import React from 'react';
import selfie_img_form from '@/assets/images/selfie_img_form.png'
import 'react-html5-camera-photo/build/css/index.css';

import {
  Button
} from '@material-ui/core';

import {
  DivBlockText,
  ImgBox,
  Letra,
  Letra2,
  ContentFormInside,
} from '../styles';


export default ({
  handleSubmit
}) => {
  return (
    <ContentFormInside>
      <ImgBox><img style={{ maxHeight: "300px", maxWidth: "300px" }} src={selfie_img_form}></img></ImgBox>
      <DivBlockText>
        <Letra>Agora precisamos que você tire uma selfie</Letra>
        <Letra2>Fique tranquilo, é apenas por questões de segurança.</Letra2>
      </DivBlockText>
      <Button fullWidth={false} size="large" variant="contained" disabled={false} color="primary" onClick={handleSubmit}>
        Continuar
      </Button>
    </ContentFormInside>
  )
}