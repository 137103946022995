export default function (value, places, symbol = 'R$', thousand = ".", decimal = ",") {
    places = !isNaN(places = Math.abs(places)) ? places : 2;
    var number = value,
        negative = number < 0 ? "-" : "",
        i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
    return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};


export function currencyMask(value) {
    let transform = value.split(',')[0].replace('.', '').replace('R$', '') * 10;
    return transform;
}