import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({ 
  getCreddefenseRequest: ['values'],
  getCreddefenseSuccess: ['data'],
  getCreddefenseFailure: [],

  getCreddefenseStatusRequest: ['values', 'token'],
  getCreddefenseStatusSuccess: ['data'],
  getCreddefenseStatusFailure: []
});

export const CreddefenseTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  loading: false,
  loading_status: false,
  data: [],
  error: false,
  success: false,
  success_request: false,
});

/* Reducers */

const getCreddefenseRequest = (state) => state.merge({
  ...state,
  loading: true,
  error: false,
  success: false,
  success_request: false,
});

const getCreddefenseSuccess = (state, { data }) => state.merge({
  data,
  loading: false,
  error: false,
  success_request: true,
});

const getCreddefenseFailure = (state) => state.merge({
  ...state,
  success_request: false,
  error: true
});


const getCreddefenseStatusRequest = (state) => state.merge({
  ...state,
  loading_status: true,
  success_request: false,
  success: false,
});

const getCreddefenseStatusSuccess = (state, { data }) => state.merge({
  data,
  loading_status: false,
  success: true,
});

const getCreddefenseStatusFailure = (state) => state.merge({
  ...state,
  loading_status: false,
  success: false
});

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CREDDEFENSE_REQUEST]: getCreddefenseRequest,
  [Types.GET_CREDDEFENSE_SUCCESS]: getCreddefenseSuccess,
  [Types.GET_CREDDEFENSE_FAILURE]: getCreddefenseFailure,

  [Types.GET_CREDDEFENSE_STATUS_REQUEST]: getCreddefenseStatusRequest,
  [Types.GET_CREDDEFENSE_STATUS_SUCCESS]: getCreddefenseStatusSuccess,
  [Types.GET_CREDDEFENSE_STATUS_FAILURE]: getCreddefenseStatusFailure,
});
