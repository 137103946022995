import React, { useState, useEffect } from 'react'
import moment from 'moment'
import marital from '@/utils/marital'
import { Container, Row, Col } from 'react-bootstrap'
import HeaderUser from '@/components/HeaderUser'
import Lottie from 'react-lottie'
import Box from '@/components/Box'
import FormGroup from '@/components/FormGroup'
import Table from '@/components/Table'
import Button from '@/components/Button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEye, faFile } from '@fortawesome/free-solid-svg-icons'
import animations from '@/assets/animations'
import headers from './headers'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import UsersActions from '@/store/ducks/users'
import ClientsActions from '@/store/ducks/clients'
import ProfessionsActions from '@/store/ducks/professions'
import { URL_API } from '@/services/api'

const ClientSingle = ({
  user,
  client,
  loading,
  match: { params: { id } },
  getClientsByIdRequest,
  getProfessionsRequest,
  professions
}) => {

  useEffect(() => {
    getClientsByIdRequest(id);
    getProfessionsRequest();
  }, [id]);

  function locationProfession(id) {
    const val = professions.find(item => item.value === id);
    return val ? val.label : 'Não encontrado'
  }

  // console.log(client);


  function addAction(arr) {
    if (arr && arr.length > 0) {
      return arr.map(item => ({
        id: item.id,
        term: item.term,
        first_maturity: item.first_maturity,
        value: item.value,
        status: item.status,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        tableActions: null
      }))
    }
  }

  if (!user || loading || !client) {
    return (
      <Lottie
        height={400}
        width={400}
        options={{
          loop: true,
          autoplay: true,
          animationData: animations.loading
        }}
      />
    )
  }
  return (
    <>
      <Container fluid className="animated fadeIn">
        <HeaderUser
          user={user}
          button={false}
        />

        <Box title="Perfil do cliente">
          <div className="fieldset">
            <h4>Dados Pessoais</h4>
            <Row className="full">
              <Col sm={6} xs={12}>
                <FormGroup
                  value={client.name}
                  disabled={true}
                  label="Nome"
                />
              </Col>
              <Col sm={6} xs={12}>
                <FormGroup
                  value={client.email}
                  disabled={true}
                  label="E-mail"
                />
              </Col>
              <Col md={4} sm={6} xs={12}>
                <FormGroup
                  value={client.cpf}
                  disabled={true}
                  label="CPF"
                />
              </Col>
              <Col md={4} sm={6} xs={12}>
                <FormGroup
                  value={moment(client.birthday).format('DD/MM/YYYY')}
                  disabled={true}
                  label="Data de nascimento"
                />
              </Col>
              <Col md={4} sm={6} xs={12}>
                <FormGroup
                  value={client.phone}
                  disabled={true}
                  label="Celular"
                />
              </Col>
              <Col md={4} sm={6} xs={12}>
                <FormGroup
                  value={client.gender === 'male' ? 'Masculino' : 'Feminino'}
                  disabled={true}
                  label="Sexo"
                />
              </Col>
              <Col md={4} sm={6} xs={12}>
                <FormGroup
                  value={marital(client.marital_status)}
                  disabled={true}
                  label="Estado civil"
                />
              </Col>
              <Col md={4} sm={6} xs={12}>
                <FormGroup
                  value={client.mother}
                  disabled={true}
                  label="Nome da mãe"
                />
              </Col>
            </Row>
          </div>

          <div className="fieldset">
            <h4>ENDEREÇO</h4>
            <Row className="full">
              <Col md={4} sm={6} xs={12}>
                <FormGroup
                  value={client.address.cep}
                  disabled={true}
                  label="CEP"
                />
              </Col>
              <Col md={8} sm={6} xs={12}>
                <FormGroup
                  value={client.address.address}
                  disabled={true}
                  label="Rua"
                />
              </Col>
              <Col md={2} sm={6} xs={12}>
                <FormGroup
                  value={client.address.number}
                  disabled={true}
                  label="Número"
                />
              </Col>
              <Col md={3} sm={6} xs={12}>
                <FormGroup
                  value={client.address.complement || "Nenhum complemento"}
                  disabled={true}
                  label="Complemento"
                />
              </Col>
              <Col md={3} sm={6} xs={12}>
                <FormGroup
                  value={client.address.neighborhood}
                  disabled={true}
                  label="Bairro"
                />
              </Col>
              <Col md={2} sm={6} xs={12}>
                <FormGroup
                  value={client.address.city}
                  disabled={true}
                  label="Cidade"
                />
              </Col>
              <Col md={2} sm={6} xs={12}>
                <FormGroup
                  value={client.address.state}
                  disabled={true}
                  label="Estado"
                />
              </Col>
            </Row>
          </div>

          <div className="fieldset">
            <h4>Dados Profissionais</h4>
            <Row className="full">
              <Col sm={6} xs={12}>
                <FormGroup
                  value={client.professiona_data.profession.label}
                  disabled={true}
                  label="Profissão"
                />
              </Col>
              <Col sm={6} xs={12}>
                <FormGroup
                  value={`R$ ${client.professiona_data.income.toLocaleString('pt-BR')}`}
                  disabled={true}
                  label="Renda"
                />
              </Col>
              <Col sm={6} xs={12}>
                <FormGroup
                  value={client.professiona_data.pay_day}
                  disabled={true}
                  label="Dia do pagamento"
                />
              </Col>
              <Col sm={6} xs={12}>
                <FormGroup
                  value={moment(client.professiona_data.work_time).format('DD/MM/YYYY')}
                  disabled={true}
                  label="Data da contratação"
                />
              </Col>
            </Row>
          </div>

          {client.bank &&
            <div className="fieldset">
              <h4>Banco</h4>
              <Row className="full">
                <Col sm={6} xs={12}>
                  <FormGroup
                    value={`${client.bank.bank.name} - ${client.bank.bank.code}`}
                    disabled={true}
                    label="Banco"
                  />
                </Col>
                <Col sm={6} xs={12}>
                  <FormGroup
                    value={client.bank.type_of_account === 'current_account' ? 'Conta corrente' : 'Conta poupança'}
                    disabled={true}
                    label="Tipo da conta"
                  />
                </Col>
                <Col sm={6} xs={12}>
                  <FormGroup
                    value={client.bank.agency}
                    disabled={true}
                    label="Agência"
                  />
                </Col>
                <Col sm={6} xs={12}>
                  <FormGroup
                    value={client.bank.account}
                    disabled={true}
                    label="Conta"
                  />
                </Col>
              </Row>
            </div>
          }

          <div className="fieldset">
            <h4>Documentos</h4>
            <Row className="full">
              <Col xs={12}>
                {client.identity ? <a href={`${URL_API}${client.identity.url}`} target="blank">Identidade</a> : ''}
              </Col>
              <Col xs={12}>
                {client.proof_of_income ? <a href={`${URL_API}${client.proof_of_income.url}`} target="blank">Comprovante de renda</a> : ''}
              </Col>
            </Row>
          </div>
        </Box>

        <Box table title="Propostas">
          <Table
            data={addAction(client.proposals)}
            headers={{
              ...headers,
              tableActions: {
                text: '',
                invisible: false,
                sortable: false,
                filterable: false,
                transform: (value, index, row) => {
                  return (
                    <div className="group-buttons">
                      {row.status !== 'Em Avaliação' && <Button className="circle small" action={() => alert()}><FontAwesomeIcon size={14} icon={faEye} /></Button>}
                      {row.status === 'Em Avaliação' && <Button className="small" action={() => alert()}>Recolher Assinatura</Button>}
                      <Button className="circle small" action={() => alert()} theme="danger"><FontAwesomeIcon size={14} icon={faTrash} /></Button>
                    </div>
                  )
                }
              }
            }}
          />
        </Box>
      </Container>
    </>
  )
}

const mapStateToProps = ({ users, clients, professions }) => ({
  loading: clients.loading,
  client: clients.client,
  user: users.data.user,
  professions: professions.data,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  ...UsersActions,
  ...ClientsActions,
  ...ProfessionsActions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ClientSingle);
