import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getProposalRequest: ['params'],
  getProposalSuccess: ['data'],
  getProposalFailure: [],

  postProposalRequest: ['values'],
  postProposalSuccess: ['data'],
  postProposalFailure: [],


  getProposalByIdRequest: ['id', 'token'],
  getProposalByIdSuccess: ['data'],
  getProposalByIdFailure: [],

  postGenerateSignatureRequest: ['values'],
  postGenerateSignatureSuccess: [],
  postGenerateSignatureFailure: [],

  postVerifyCodeRequest: ['values', 'token'],
  postVerifyCodeSuccess: ['data'],
  postVerifyCodeFailure: [],

  putAuditTrailRequest: ['values', 'id', 'nextStep', 'token'],
  putAuditTrailSuccess: ['data'],
  putAuditTrailFailure: [''],

  postReuploadRequest: ["values"],
  postReuploadSuccess: ["data"],
  postReuploadFailure: []
});

export const ProposalTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  loading: false,
  proposal: null,
  data: [],
  error: false,
  success: false,
  loading_trail: false,
  success_trail: false,
  success_code: false
});

/* Reducers */

const getProposalRequest = (state) => state.merge({
  ...state,
  loading: true,
  success: false
});

const getProposalSuccess = (state, { data }) => state.merge({
  data,
  loading: false,
  error: false,
});

const getProposalFailure = (state) => state.merge({
  ...state,
  error: true,
  loading: false,
});


const postProposalRequest = (state) => state.merge({
  ...state,
  loading: true,
  success: false
});

const postProposalSuccess = (state, { data }) => state.merge({
  data,
  loading: false,
  error: false,
  success: true
});

const postProposalFailure = (state) => state.merge({
  ...state,
  error: true,
  loading: false,
  success: false
});



const getProposalByIdRequest = (state) => state.merge({
  ...state,
  loading: true,
  success: false
});

const getProposalByIdSuccess = (state, { data }) => state.merge({
  proposal: data,
  loading: false,
  error: false,
});

const getProposalByIdFailure = (state) => state.merge({
  ...state,
  error: true,
  loading: false,
});



const postGenerateSignatureRequest = (state) => state.merge({
  ...state,
  loading: true,
  success: false
});

const postGenerateSignatureSuccess = (state, { data }) => state.merge({
  proposal: data,
  loading: false,
  error: false,
});

const postGenerateSignatureFailure = (state) => state.merge({
  ...state,
  error: true,
  loading: false,
});


const postVerifyCodeRequest = (state) => state.merge({
  ...state,
  loading: true,
  success_code: false
});

const postVerifyCodeSuccess = (state, { data }) => state.merge({
  loading: false,
  error: false,
  success_code: true
});

const postVerifyCodeFailure = (state) => state.merge({
  ...state,
  error: true,
  loading: false,
  success_code: false
});


const putAuditTrailRequest = (state) => state.merge({
  ...state,
  loading_trail: true,
  success_trail: false
});

const putAuditTrailSuccess = (state, { data }) => state.merge({
  proposal: data,
  loading_trail: false,
  error: false,
  success_trail: true
});

const putAuditTrailFailure = (state) => state.merge({
  ...state,
  error: true,
  loading_trail: false,
  success_trail: false
});






/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PROPOSAL_REQUEST]: getProposalRequest,
  [Types.GET_PROPOSAL_SUCCESS]: getProposalSuccess,
  [Types.GET_PROPOSAL_FAILURE]: getProposalFailure,

  [Types.POST_PROPOSAL_REQUEST]: postProposalRequest,
  [Types.POST_PROPOSAL_SUCCESS]: postProposalSuccess,
  [Types.POST_PROPOSAL_FAILURE]: postProposalFailure,

  [Types.GET_PROPOSAL_BY_ID_REQUEST]: getProposalByIdRequest,
  [Types.GET_PROPOSAL_BY_ID_SUCCESS]: getProposalByIdSuccess,
  [Types.GET_PROPOSAL_BY_ID_FAILURE]: getProposalByIdFailure,

  [Types.POST_GENERATE_SIGNATURE_REQUEST]: postGenerateSignatureRequest,
  [Types.POST_GENERATE_SIGNATURE_SUCCESS]: postGenerateSignatureSuccess,
  [Types.POST_GENERATE_SIGNATURE_FAILURE]: postGenerateSignatureFailure,

  [Types.POST_VERIFY_CODE_REQUEST]: postVerifyCodeRequest,
  [Types.POST_VERIFY_CODE_SUCCESS]: postVerifyCodeSuccess,
  [Types.POST_VERIFY_CODE_FAILURE]: postVerifyCodeFailure,

  [Types.PUT_AUDIT_TRAIL_REQUEST]: putAuditTrailRequest,
  [Types.PUT_AUDIT_TRAIL_SUCCESS]: putAuditTrailSuccess,
  [Types.PUT_AUDIT_TRAIL_FAILURE]: putAuditTrailFailure,
});
