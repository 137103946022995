import React, { useState, useRef, useCallback, useEffect } from 'react';
import 'react-html5-camera-photo/build/css/index.css';

import {
  Button
} from '@material-ui/core';

import {
  DivBlockText,
  Letra,
  Letra2,
  TokenInputWrap,
  ButWrapToken,
  ContentFormInside,
  F12,
} from '../styles';
import Lottie from 'react-lottie';
import animations from '@/assets/animations';

export default ({
  handleSubmit,
  loading
}) => {
  const [code, setCode] = useState();

  return (
    <ContentFormInside>
      <DivBlockText>
        <Letra>Enviamos um código por SMS para seu celular</Letra>
        <Letra2>Digite abaixo para continuar.</Letra2>
      </DivBlockText>
      <TokenInputWrap>
        <input className="input-code-form" inputMode="numeric" maxLength="4" onKeyUp={(e) => setCode(e.target.value)}></input>
      </TokenInputWrap>
      <ButWrapToken>
        {!loading && <Button fullWidth={false} size="large" variant="contained" disabled={!code || code && code.length !== 4} color="primary" onClick={() => handleSubmit(code)}>Continuar</Button>}
        {loading && <Lottie
          height={150}
          width={150}
          style={{
            overflow: 'initial'
          }}
          options={{
            loop: true,
            autoplay: true,
            animationData: animations.loading
          }}
        />}
        <F12>Não recebeu? <strong>Envie novamente.</strong></F12>
      </ButWrapToken>
    </ContentFormInside>
  )
}
/*<TokenInputWrap>
        <input id="number0" inputMode="numeric" className="token-input-field" onKeyUp={pressKeyboard} maxLength="1"></input>
        <input id="number1" inputMode="numeric" className="token-input-field" onKeyUp={pressKeyboard} maxLength="1"></input>
        <input id="number2" inputMode="numeric" className="token-input-field" onKeyUp={pressKeyboard} maxLength="1"></input>
        <input id="number3" inputMode="numeric" className="token-input-field" onKeyUp={pressKeyboard} maxLength="1"></input>
      </TokenInputWrap>
    */
