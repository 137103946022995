import styled from 'styled-components';

import colors from '@/styles/colors';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Figure = styled.figure`
  width: 65px;
  height: 65px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 15px;
`;

export const Avatar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Name = styled.h4`
  font-weight: bold;
  font-size: 22px;
  color: ${colors.dark};
`;
export const Email = styled.p`
  font-size: 14px;
  color: ${colors.grayBold}
`;
export const Button = styled.a`
  display: inline-block;
  background: ${colors.white};
  border-radius: 4px;
  padding: 2px 8px;
  color: ${colors.grayBold};
  font-weight: bold;
  right: 0;
  font-size: 16px;
  top:-5px;
  position: relative;
  text-decoration: none;
  margin-left: 10px;
  transition: all 250ms;

  :hover {
    background: ${colors.default};
    color: ${colors.white};
  }
`;
