import React, { useEffect, useState } from 'react'
import { Formik, Field } from 'formik'
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import MaskedInput from 'react-maskedinput';

import Button from '@/components/Button';

import {
  Formgroup,
  Input,
  Form,
  SuccesBox,
  EyeBlock
} from './styles';

const validationSchemaOne = Yup.object().shape({
  username: Yup.string()
    .required('Campo obrigatório')

});

const validationSchemaTwo = Yup.object().shape({
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),

  cpf: Yup.string().required('Campo obrigatório'),

  name: Yup.string().required('Campo obrigatório'),

  phone: Yup.string().required('Campo obrigatório'),

  birthday: Yup.string().required('Campo obrigatório'),

  password: Yup.string().required('Campo obrigatório'),
});

const StepOne = (props) => {

  const handleSubmit = (values) => {
    props.padrao(values)
    props.setData(values)
  }

  useEffect(()=>{
    if(props.success){
      props.next()
    }
  }, [props.loading])

    return(
        <Formik
            validationSchema={validationSchemaOne}
            initialValues={props.data}
            onSubmit={handleSubmit}
          >
            {({
              values, errors, handleChange, handleSubmit,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Formgroup>
                    <Input
                      type={"text" }
                      placeholder="Usuário Padrão"
                      name="username"
                      className={errors?.username ? 'error' : ''}
                      onChange={handleChange}
                    />
                  </Formgroup>
                  <Button className="cadastro" theme='' onClick={handleSubmit} type="submit" loading={props.loading}>Verificar</Button>
                </Form>
              )
            }}
          </Formik>
    )
}

const StepTwo = (props) => {

  const [showPassword, setShowPassword] = useState(false);
  const [validation, setValidation] = useState()

  const handleSubmit = async (values, event) => {
    setValidation(validationSchemaTwo)
    const validated = await event.validateForm()
    if(Object.keys(validated).length === 0) props.postData(values);   
  };

  useEffect(()=>{
    if(props.success){
      props.next()
    }
  }, [props.loading])

  return(
    <Formik
        validationSchema={validation}
        initialValues={props.data}
        onSubmit={handleSubmit}
      >
        {({
          values, errors, handleChange, handleSubmit,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Formgroup>
                    <Input
                      type="email"
                      placeholder="Email"
                      name="email"
                      className={errors?.email ? 'error' : ''}
                      onChange={handleChange}
                    />
                  </Formgroup>
              <Formgroup>
                <MaskedInput 
                  type="text" 
                  placeholder='CPF' 
                  name='cpf' 
                  className={errors?.cpf ? 'error' : ''} 
                  onChange={handleChange} 
                  mask="111.111.111-11"
                />
              </Formgroup>
              <Formgroup>
                <Input
                  type="text"
                  placeholder="Nome"
                  name="name"
                  className={errors?.name ? 'error' : ''}
                  onChange={handleChange}
                />
              </Formgroup>
              <Formgroup>
                <MaskedInput
                  type="tel"
                  placeholder="Celular"
                  name="phone"
                  mask="(11) 11111-1111"
                  className={errors?.phone ? 'error' : ''}
                  onChange={handleChange}
                />
              </Formgroup>
              <Formgroup>
                <MaskedInput
                  type="text"
                  placeholder="Data de Nascimento"
                  name="birthday"
                  mask="11/11/1111"
                  className={errors?.birthday ? 'error' : ''}
                  onChange={handleChange}
                />
              </Formgroup>
              <Formgroup>
                    <EyeBlock type="button" onClick={() => setShowPassword(!showPassword)}><FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} size="lg" /></EyeBlock>
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder="Senha"
                      name="password"
                      className={errors?.password ? 'error' : ''}
                      onChange={handleChange}
                    />
                  </Formgroup>
              <Button className="cadastro" theme='' onClick={handleSubmit} loading={props.loading} type="submit">Finalizar</Button>
            </Form>
          )
        }}
      </Formik>
)
}

const SuccessPage = () =>{

  const redirectLogin = () => {
    window.location.href = '/login'
  }

  return(
    <SuccesBox>
      <h3>Sucesso!</h3>
      <h5>Faca login para continuar</h5>
      <Button className="cadastro success" theme="" action={redirectLogin}>Login</Button>
    </SuccesBox>
  )
}

export {StepOne, StepTwo, SuccessPage}