import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  postVerifyRequest: ['values'],
  postVerifySuccess: ['data'],
  postVerifyFailure: [],

  postCreateRequest: ['values'],
  postCreateSuccess: ['data'],
  postCreateFailure: [],
  
});

export const CadastroTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  loading_verify: false,
  data_verify: [],
  error_verify: false,
  success_verify: false,

  loading_create: false,
  data_create: [],
  error_create: false,
  success_create: false
});

/* Reducers */

const postVerifyRequest = (state) => state.merge({
  ...state,
  loading_verify: true,
});

const postVerifySuccess = (state, { data }) => state.merge({
  data_verify: data,
  loading_verify: false,
  success_verify: true,
  error_verify: false,
});

const postVerifyFailure = (state) => state.merge({
  ...state,
  error_verify: true,
  loading_verify: false,
  success_verify: false
});

const postCreateRequest = (state) => state.merge({
  ...state,
  loading_create: true,
});

const postCreateSuccess = (state, { data }) => state.merge({
  data_create: data,
  loading_create: false,
  success_create: true,
  error_create: false,
});

const postCreateFailure = (state) => state.merge({
  ...state,
  error_create: true,
  loading_create: false,
  success_create: false
});

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.POST_VERIFY_REQUEST]: postVerifyRequest,
  [Types.POST_VERIFY_SUCCESS]: postVerifySuccess,
  [Types.POST_VERIFY_FAILURE]: postVerifyFailure,
  [Types.POST_CREATE_REQUEST]: postCreateRequest,
  [Types.POST_CREATE_SUCCESS]: postCreateSuccess,
  [Types.POST_CREATE_FAILURE]: postCreateFailure,
});
