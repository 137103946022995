import React, { useState, useEffect } from 'react';
import FormGroup from '@/components/FormGroup';
import Button from '@/components/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserShield } from '@fortawesome/free-solid-svg-icons';
import AvatarImage from '@/assets/images/avatar.jpg'
import {
  StyledContainer,
  Figure,
  Title,
  TitleForm,
  FileInput
} from './styles';

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import UsersActions from '@/store/ducks/users'

import {
  Row,
  Col,
  Container,
  Modal
} from 'react-bootstrap'
import toast from '@/utils/toast';



const validationSchema = Yup.object().shape({
  repassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Senhas diferentes')
});

function Profile({
  user,
  loading,
  getMeRequest,
  putMeRequest,
  success
}) {

  const [image, setImage] = useState();

  useEffect(() => {
    getMeRequest();
  }, []);

  const handleImageChange = (event) => {
    setImage({
      file: URL.createObjectURL(event.target.files[0]),
      avatar: event.target.files[0]
    });
  }

  useEffect(() => {
    if (success) {
      toast.success('Informações alteradas com sucesso!')
    }
  }, [success])

  return (
    <StyledContainer>
      <Row>
        <Col>
          <Figure>
            <FileInput type="file" onChange={handleImageChange} />
            <img src={image?.file || user?.avatar?.url || AvatarImage} />
          </Figure>
          <Title>{user?.name}</Title>
        </Col>
      </Row>
      <Formik
        onSubmit={(values) => {
          try {
            
            let data = {
              avatar: image?.avatar || null
            }

            if(values.password) {
              data['password'] = values.password
            }

            putMeRequest(data);
          } catch (error) {
            console.log(error)
          }
        }}
        validationSchema={validationSchema}
        initialValues={{
          password: '',
          repassword: ''
        }}
      >
        {({
          errors,
          handleSubmit,
          handleChange
        }) => {
          return (
            <Container>
              <Row style={{ marginTop: 30 }}>
                <TitleForm><FontAwesomeIcon icon={faUser} /> Informações pessoais</TitleForm>
                <Col>
                  <FormGroup
                    type="text"
                    label="Nome completo"
                    placeholder="Nome completo"
                    name="name"
                    disabled={true}
                    value={user?.name}
                  />
                </Col>
                <Col>
                  <FormGroup
                    type="text"
                    label="E-mail"
                    placeholder="E-mail"
                    name="email"
                    disabled={true}
                    value={user?.user.email}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup
                    type="text"
                    label="Telefone"
                    placeholder="Telefone"
                    name="phone"
                    disabled={true}
                    value={user?.phone}
                  />
                </Col>
                <Col>
                  <FormGroup
                    type="text"
                    label="CPF"
                    placeholder="CPF"
                    name="cpf"
                    disabled={true}
                    value={user?.cpf}
                  />
                </Col>
              </Row>
              <TitleForm><FontAwesomeIcon icon={faUserShield} /> Segurança</TitleForm>
              <Row>
                <Col>
                  <FormGroup
                    type="password"
                    label="Senha"
                    placeholder="Senha"
                    name="password"
                    onChange={handleChange}
                    error={errors?.password}
                  />
                </Col>
                <Col>
                  <FormGroup
                    type="password"
                    label="Confirmação da senha"
                    placeholder="Confirmação da senha"
                    name="repassword"
                    onChange={handleChange}
                    error={errors?.repassword}
                  />
                </Col>
              </Row>
              <div style={{ textAlign: 'center', marginTop: 15 }}>
                <Button loading={loading} disabled={loading} action={handleSubmit}>Salvar</Button>
              </div>
            </Container>
          )
        }}
      </Formik>
    </StyledContainer>
  );
}

const mapStateToProps = ({ users }) => ({
  user: users.data.user,
  loading: users.loading_put,
  success: users.success
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  ...UsersActions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
