import { call, put } from 'redux-saga/effects';
import api from '@/services/api';

import cogoToast from 'cogo-toast';
import ProposalActions from '@/store/ducks/proposal';
import { toast } from 'react-toastify';

export function* getProposalRequest({ params }) {
  try {
    const endpoint = '/proposals';
    const { data } = yield call(api.get, endpoint, {
      params
    });
    yield put(ProposalActions.getProposalSuccess(data));
  } catch (error) {
    // console.log(error);
    yield put(ProposalActions.getProposalFailure());
  }
}


export function* postProposalRequest({ values }) {
  try {

    var bodyFormData = new FormData();

    //bodyFormData.append("files.proof_of_residence", values.proof_of_residence);
    values.proof_of_residence.forEach(element => {bodyFormData.append("files.proof_of_residence", element)})

    delete values.proof_of_residence;

    bodyFormData.append('data', JSON.stringify(values));

    // console.log(bodyFormData);

    const endpoint = '/proposals';
    const { data } = yield call(api.post, endpoint, bodyFormData);
    cogoToast.success('Proposta cadastrada com sucesso');

    yield put(ProposalActions.postProposalSuccess(data));
  } catch (error) {
    cogoToast.error('Algo deu errado, tente mais tarde');
    // console.log(error);
    yield put(ProposalActions.postProposalFailure());
  }
}

export function* getProposalByIdRequest({ id, token }) {
  try {
    const endpoint = `/proposals/${id}`;
    const { data } = yield call(api.get, endpoint, {
      params: { token }
    });
    yield put(ProposalActions.getProposalByIdSuccess(data));
  } catch (error) {
    // console.log(error);
    yield put(ProposalActions.getProposalByIdFailure());
  }
}

export function* postGenerateSignatureRequest({ values }) {
  try {
    const endpoint = `/proposals/send-signature`;
    const { data } = yield call(api.post, endpoint, values);
    cogoToast.success('Token enviado com sucesso');
    yield put(ProposalActions.postGenerateSignatureSuccess(data));
  } catch (error) {
    // console.log(error);
    yield put(ProposalActions.postGenerateSignatureFailure());
  }
}

export function* postVerifyCodeRequest({ values, token }) {
  // console.log(values);
  try {
    const endpoint = `/proposals/code`;
    const { data } = yield call(api.post, endpoint, values, { params: { token } });
    yield put(ProposalActions.postVerifyCodeSuccess(data));
  } catch (error) {
    // console.log(error);
    toast.error('Token não verificado ou incorreto')
    yield put(ProposalActions.postVerifyCodeFailure());
  }
}


export function* putAuditTrailRequest({ values, id, nextStep, token }) {
  try {
    const endpoint = `/proposals/audit/${id}`;
    const { data } = yield call(api.put, endpoint, values, { params: { token } });
    yield put(ProposalActions.putAuditTrailSuccess(data));

    nextStep();
  } catch (error) {
    // console.log(error);
    yield put(ProposalActions.putAuditTrailFailure());
  }
}
