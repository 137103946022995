import React, { useState, useEffect } from 'react';
import moment from 'moment';
import marital from '@/utils/marital';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderUser from '@/components/HeaderUser';
import Lottie from 'react-lottie';
import Box from '@/components/Box';
import FormGroup from '@/components/FormGroup';
import Table from '@/components/Table';
import Button from '@/components/Button';
import currency from '@/utils/currency';

import animations from '@/assets/animations';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import UsersActions from '@/store/ducks/users';
import ProposalActions from '@/store/ducks/proposal';
import ProfessionsActions from '@/store/ducks/professions';

const ProposalSingle = ({
  user,
  proposal,
  loading,
  match: {
    params: { id },
  },
  getProposalByIdRequest,
  getProfessionsRequest,
  professions,
}) => {
  useEffect(() => {
    getProposalByIdRequest(id);
    getProfessionsRequest();
  }, [id]);

  function locationProfession(id) {
    const val = professions.find((item) => item.value === id);
    return val ? val.label : 'Não encontrado';
  }

  if (!user || loading || !proposal) {
    return (
      <Lottie
        height={400}
        width={400}
        options={{
          loop: true,
          autoplay: true,
          animationData: animations.loading,
        }}
      />
    );
  }
  return (
    <>
      <Container fluid className="animated fadeIn">
        <HeaderUser user={user} button={false} />
        <Box title={`Proposta #${proposal.proposal_external}`}>
          <div className="fieldset">
            <h4>Dados da proposta</h4>
            <Row className="full">
              <Col md={3} sm={6} xs={12}>
                <FormGroup
                  value={proposal.status}
                  disabled={true}
                  label="Status"
                />
              </Col>
              <Col md={2} sm={6} xs={12}>
                <FormGroup
                  value={`Dia ${proposal.first_maturity}`}
                  disabled={true}
                  label="Primeira parcela"
                />
              </Col>
              <Col md={2} sm={6} xs={12}>
                <FormGroup
                  value={`${proposal.term}x`}
                  disabled={true}
                  label="Quant. de parcelas"
                />
              </Col>
              <Col md={2} sm={6} xs={12}>
                <FormGroup
                  value={`${currency(proposal.padrao.ValorParcela)}`}
                  disabled={true}
                  label="Valor da parcela"
                />
              </Col>
              <Col md={3} sm={6} xs={12}>
                <FormGroup
                  value={`R$ ${proposal.value?.toLocaleString('pt-BR')}`}
                  disabled={true}
                  label="Valor"
                />
              </Col>
              <Col md={2} sm={6} xs={12}>
                <FormGroup
                  value={proposal.type_payment}
                  disabled={true}
                  label="Tipo de pagamento"
                />
              </Col>
              <Col xs={12}>
                <FormGroup
                  value={proposal.goal || 'Sem objetivo'}
                  disabled={true}
                  label="Objetivo"
                  //type="textarea"
                  //rows={8}
                />
              </Col>
            </Row>
          </div>
        </Box>

        <Box title="Perfil do cliente">
          <div className="fieldset">
            <h4>Dados Pessoais</h4>
            <Row className="full">
              <Col sm={6} xs={12}>
                <FormGroup
                  value={proposal.client.name}
                  disabled={true}
                  label="Nome"
                />
              </Col>
              <Col sm={6} xs={12}>
                <FormGroup
                  value={proposal.client.email}
                  disabled={true}
                  label="E-mail"
                />
              </Col>
              <Col md={4} sm={6} xs={12}>
                <FormGroup
                  value={proposal.client.cpf}
                  disabled={true}
                  label="CPF"
                />
              </Col>
              <Col md={4} sm={6} xs={12}>
                <FormGroup
                  value={moment(proposal.client.birthday).format('DD/MM/YYYY')}
                  disabled={true}
                  label="Data de nascimento"
                />
              </Col>
              <Col md={4} sm={6} xs={12}>
                <FormGroup
                  value={proposal.client.phone}
                  disabled={true}
                  label="Telefone"
                />
              </Col>
              <Col md={4} sm={6} xs={12}>
                <FormGroup
                  value={
                    proposal.client.gender === 'male' ? 'Masculino' : 'Feminino'
                  }
                  disabled={true}
                  label="Sexo"
                />
              </Col>
              <Col md={4} sm={6} xs={12}>
                <FormGroup
                  value={marital(proposal.client.marital_status)}
                  disabled={true}
                  label="Estado civil"
                />
              </Col>
              <Col md={4} sm={6} xs={12}>
                <FormGroup
                  value={proposal.client.mother}
                  disabled={true}
                  label="Nome da mãe"
                />
              </Col>
            </Row>
          </div>

          <div className="fieldset">
            <h4>ENDEREÇO</h4>
            <Row className="full">
              <Col md={4} sm={6} xs={12}>
                <FormGroup
                  value={proposal.client.address.cep}
                  disabled={true}
                  label="CEP"
                />
              </Col>
              <Col md={8} sm={6} xs={12}>
                <FormGroup
                  value={proposal.client.address.address}
                  disabled={true}
                  label="Rua"
                />
              </Col>
              <Col md={2} sm={6} xs={12}>
                <FormGroup
                  value={proposal.client.address.number}
                  disabled={true}
                  label="Número"
                />
              </Col>
              <Col md={5} sm={6} xs={12}>
                <FormGroup
                  value={
                    proposal.client.address.complement || 'Nenhum complemento'
                  }
                  disabled={true}
                  label="Complemento"
                />
              </Col>
              <Col md={3} sm={6} xs={12}>
                <FormGroup
                  value={proposal.client.address.neighborhood}
                  disabled={true}
                  label="Bairro"
                />
              </Col>
              <Col md={2} sm={6} xs={12}>
                <FormGroup
                  value={proposal.client.address.city}
                  disabled={true}
                  label="Cidade"
                />
              </Col>
              <Col md={2} sm={6} xs={12}>
                <FormGroup
                  value={proposal.client.address.state}
                  disabled={true}
                  label="Estado"
                />
              </Col>
            </Row>
          </div>

          <div className="fieldset">
            <h4>Dados Profissionais</h4>
            <Row className="full">
              <Col sm={6} xs={12}>
                <FormGroup
                  value={proposal.client.professiona_data.profession.label}
                  disabled={true}
                  label="Profissão"
                />
              </Col>
              <Col sm={6} xs={12}>
                <FormGroup
                  value={`R$ ${proposal.client.professiona_data.income.toLocaleString(
                    'pt-BR',
                  )}`}
                  disabled={true}
                  label="Sua renda"
                />
              </Col>
              <Col sm={6} xs={12}>
                <FormGroup
                  value={proposal.client.professiona_data.pay_day}
                  disabled={true}
                  label="Dia do pagamento"
                />
              </Col>
              <Col sm={6} xs={12}>
                <FormGroup
                  value={moment(
                    proposal.client.professiona_data.work_time,
                  ).format('DD/MM/YYYY')}
                  disabled={true}
                  label="Data da contratação"
                />
              </Col>
            </Row>
          </div>

          {proposal.client.bank && (
            <div className="fieldset">
              <h4>Banco</h4>
              <Row className="full">
                <Col sm={6} xs={12}>
                  <FormGroup
                    value={`${proposal.client.bank.bank.name} - ${proposal.client.bank.bank.code}`}
                    disabled={true}
                    label="Banco"
                  />
                </Col>
                <Col sm={6} xs={12}>
                  <FormGroup
                    value={
                      proposal.client.bank.type_of_account === 'current_account'
                        ? 'Conta corrente'
                        : 'Conta poupança'
                    }
                    disabled={true}
                    label="Tipo da conta"
                  />
                </Col>
                <Col sm={6} xs={12}>
                  <FormGroup
                    value={proposal.client.bank.agency}
                    disabled={true}
                    label="Agência"
                  />
                </Col>
                <Col sm={6} xs={12}>
                  <FormGroup
                    value={proposal.client.bank.account}
                    disabled={true}
                    label="Conta"
                  />
                </Col>
              </Row>
            </div>
          )}
        </Box>
      </Container>
    </>
  );
};

const mapStateToProps = ({ users, proposal, professions }) => ({
  loading: proposal.loading,
  proposal: proposal.proposal,
  user: users.data.user,
  professions: professions.data,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...UsersActions,
      ...ProposalActions,
      ...ProfessionsActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProposalSingle);
