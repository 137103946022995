import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getUsersRequest: [],
  getUsersSuccess: ['data'],
  getUsersFailure: [],

  getMeRequest: [],
  getMeSuccess: ['user'],
  getMeFailure: [],

  putMeRequest: ['values'],
  putMeSuccess: ['data'],
  putMeFailure: []
});

export const UsersTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  loading: false,
  data: [],
  error: false,
  success: false,
  loading_put: false
});

/* Reducers */

const getUsersRequest = (state) => state.merge({
  ...state,
  loading: true,
  loading_put: false
});

const getUsersSuccess = (state, { data }) => state.merge({
  data,
  loading: false,
  error: false,
});

const getUsersFailure = (state) => state.merge({
  ...state,
  error: true,
  loading: false,
});


const getMeRequest = (state) => state.merge({
  ...state,
  loading: true,
  success: false,
  loading_put: false
});

const getMeSuccess = (state, { user }) => state.merge({
  user,
  loading: false,
  error: false,
});

const getMeFailure = (state) => state.merge({
  ...state,
  error: true,
  loading: false,
});


const putMeRequest = (state) => state.merge({
  ...state,
  loading_put: true,
  success: false
});

const putMeSuccess = (state, { user }) => state.merge({
  user,
  loading_put: false,
  error: false,
  success: true
});

const putMeFailure = (state) => state.merge({
  ...state,
  error: true,
  loading_put: false,
});


/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_USERS_REQUEST]: getUsersRequest,
  [Types.GET_USERS_SUCCESS]: getUsersSuccess,
  [Types.GET_USERS_FAILURE]: getUsersFailure,


  [Types.GET_ME_REQUEST]: getMeRequest,
  [Types.GET_ME_SUCCESS]: getMeSuccess,
  [Types.GET_ME_FAILURE]: getMeFailure,


  [Types.PUT_ME_REQUEST]: putMeRequest,
  [Types.PUT_ME_SUCCESS]: putMeSuccess,
  [Types.PUT_ME_FAILURE]: putMeFailure,
});
