import styled from 'styled-components';
import colors from '@/styles/colors';
export const Body = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
`;

export const Header = styled.header`
  h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 60px;
    font-weight: regular;
  }

  p {
    font-family: "niveau grotesk", sans-serif;
    font-size: 17px;
    font-weight: light;
    margin: 15px 0;
    color: #AAAAAA;
  }
`;

export const Section = styled.div``;


export const Input = styled.input`
font-family: "niveau grotesk", sans-serif;
  font-size: 17px;
  font-weight: regular;
  width: 100%;
  height: 65px;
  border-radius: 30px;
  border: 0;
  padding: 15px 30px;
  background: transparent;
  color: ${colors.dark};
  background-color: rgba(239,239,239,0.6);
  

  ::placeholder {
    font-family: "niveau grotesk", sans-serif;
    font-size: 17px;
    font-weight: regular;
    color: #B8B8B8;
    opacity: 1;
  }
  
  :-ms-input-placeholder {
    color: #B8B8B8;
    opacity: 1;
  }
  
  ::-ms-input-placeholder { 
    color: #B8B8B8;
    opacity: 1;
  }

  :-moz-placeholder {
    color: #B8B8B8;
    opacity: 1;
  }

  ::-moz-placeholder {
    color: #B8B8B8;
    opacity: 1;
  }

  &.error {
    border-color: ${colors.error};


    ::placeholder {
      color: ${colors.error};
    }
    
    :-ms-input-placeholder {
      color: ${colors.error};
    }
    
    ::-ms-input-placeholder { 
      color: ${colors.error};
    }
  }
`;

export const Formgroup = styled.div`
  margin-bottom: 15px;
  position: relative;
`;

export const ErrorBlcok = styled.div`
  color: red;
  font-size: 13px;
  text-align: left;
  padding-left: 15px;
  margin-top: 10px;
`;

export const Form = styled.div`
  width: 420px;

  a {
    color: ${colors.dark};
    text-decoration: underline;
  }
`;

export const Figure = styled.div`
  img {
    max-width: 80%;
  }
`;

export const Button = styled.button`
  text-align: center;
  width: 260px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
  background-color: #444CF0;
  border: 0;
  border-radius: 30px;
  outline: none !important;
  font-family: 'Poppins', sans-serif;
  font-weight: medium;
`;