import React, { useState, useRef, useCallback, useEffect } from 'react';
import MaskedInput from 'react-maskedinput';
import { Formik } from 'formik';
import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min';
import {
  Button
} from '@material-ui/core';

import {
  DivBlockText,
  Letra,
  Letra2,
  TokenInputWrap,
  ButWrapToken,
  ContentFormInside,
  F12,
  CpfInput,
  Form
} from '../styles';
import Lottie from 'react-lottie';
import animations from '@/assets/animations';
import FormGroup from '@/components/FormGroup';


export default ({
  onSubmit,
  loading
}) => {


  useEffect(() => {
    $('.cpf').mask('000.000.000-00');
  })

  return (
    <ContentFormInside>
      <DivBlockText>
        <Letra>Insira seu CPF</Letra>
        <Letra2>Digite abaixo para continuar.</Letra2>
      </DivBlockText>

      <Formik
        initialValues={{ cpf: '' }}
        onSubmit={onSubmit}
        validate={(values) => {
          const errors = {};

          if (!values.cpf) {
            errors.cpf = 'Campo obrigatório';
          }
          return errors;
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          ...props
        }) => {
          return (
            <Form>
              <FormGroup
                type="text"
                required
                onChange={handleChange}
                placeholder=""
                name="cpf"
                error={errors.cpf}
                value={values.cpf}
                className="input cpf"
                inputMode="numeric"
                maxLength="11"
                style={{ borderRadius: "0px", marginBottom: "56px" }}
              />
              <div>
                <Button onClick={handleSubmit} fullWidth={false} size="large" variant="contained" disabled={errors.cpf || loading} color="primary">Continuar</Button>
              </div>
            </Form>
          )
        }}
      </Formik>



    </ContentFormInside>
  )
}