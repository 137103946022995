import styled from 'styled-components';

import colors from '@/styles/colors';
import { text } from '@fortawesome/fontawesome-svg-core';

export const StyledContainer = styled.div`
  background-color: ${colors.background};
  color: ${colors.primary};
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
`;


export const Form = styled.form``;