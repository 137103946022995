import styled from 'styled-components';
import colors from '@/styles/colors';

export const Background = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(335deg, rgba(70,125,255,1) 0%, rgba(68,76,241,1) 100%);
  align-items: center;
  justify-content: center;
  display: flex;
  font-family : proxima-nova;

  a {
    color: ${colors.white};
    text-decoration: none;
  }
    
`;

export const Center = styled.div`
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 62px;
  color: ${colors.white};
  margin-top: -10px;
  margin-bottom: 15px;
  font-weight: 400;
`;

export const Description = styled.div`
  font-size: 14px;
  color: ${colors.white};
  margin-bottom: 80px;
`;

export const Input = styled.input`
  width: 100%;
  height: 65px;
  border-radius: 30px;
  border: 2px solid ${colors.white};
  padding: 15px 45px;
  background: transparent;
  color: ${colors.white};

  ::placeholder {
    color: ${colors.white};
  }
  
  :-ms-input-placeholder {
    color: ${colors.white};
  }
  
  ::-ms-input-placeholder { 
    color: ${colors.white};
  }

  &.error {
    border-color: ${colors.error};


    ::placeholder {
      color: ${colors.error};
    }
    
    :-ms-input-placeholder {
      color: ${colors.error};
    }
    
    ::-ms-input-placeholder { 
      color: ${colors.error};
    }
  }
`;

export const Formgroup = styled.div`
  margin-bottom: 15px;
  position: relative;
`;

export const EyeBlock = styled.button`
  background: transparent;
  border: 0;
  color: ${colors.black};
  position: absolute;
  right: 25px;
  top: 22px;

  &:focus {
    outline: 0;
  }
`;

export const ErrorBlcok = styled.div`
  color: red;
  font-size: 13px;
  text-align: left;
  padding-left: 15px;
  margin-top: 10px;
`;

export const Form = styled.form`
  width: 420px;
  margin: 0 auto;
`;

export const Button = styled.button`
  color: ${colors.white};
  text-align: center;
  background: #febc31;
  font-weight: bold;
  width: 100%;
  padding: 12px 0;
  border: 0;
  border-radius: 30px;
  margin-top: 60px;
  margin-bottom: 15px;
  outline: 0;
  &:focus{
    outline: 0;
  }
  z-index: 99;

  &:disabled{
    background: #c4c4c4;
    cursor: not-allowed;
  }
`;