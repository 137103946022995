import styled from 'styled-components';

import colors from '@/styles/colors';

export const StyledContainer = styled.div`
  background-color: ${colors.background};
  color: ${colors.primary};
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
`;

export const Figure = styled.figure`
  width: 150px;
  height: 150px;
  border-radius: 100%;
  overflow: hidden;
  margin: 0 auto 15px;
  position: relative;
  background: #000;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 250ms;
  }

  &:hover {
    img {
      opacity: 0.5;
    }
  }
`;


export const Title = styled.h2`
  text-align:center;
  font-weight: bold;
  text-transform: capitalize;
`;

export const TitleForm = styled.h4`
  font-weight: bold;
  margin: 15px 0 30px;
  padding: 15px 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  font-size: 18px;
`;

export const FileInput = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
`;