import { put } from 'redux-saga/effects';

import CitiesActions from '@/store/ducks/cities';

export function* getCitiesRequest(values) {

  if (!values.values) {
    return yield put(CitiesActions.getCitiesSuccess([]));
  }

  try {
    const endpoint = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${values.values}/municipios`;
    const cities = yield fetch(endpoint);
    const data = yield cities.json();

    if (!cities.ok) {
      return yield put(CitiesActions.getCitiesFailure());
    }

    yield put(CitiesActions.getCitiesSuccess(data));
  } catch (error) {
    // console.log(error);
    yield put(CitiesActions.getCitiesFailure());
  }
}
