import React, { useEffect, useState } from 'react'
import { ReactSVG } from 'react-svg';
import Logo from '@/assets/images/logo-branca.svg';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CadastroActions from '@/store/ducks/cadastro';

import {
  Background,
  Description,
  Title,
} from './styles';

import {StepOne, StepTwo, SuccessPage} from './step';

const Cadastro = ({
  postVerifyRequest,
  postCreateRequest,
  loading,
  error,
  success,
  resposta,
  loadingCreate,
  errorCreate,
  successCreate,
  respostaCreate,
  history,
  ...props
}) => {
    
  const [currentStep, setCurrentStep] = useState(0);
  const [data, setData] = useState({
    username: '',
    password: '',
    email: '',

    name: '',
    cpf:'',
    phone: '',
    birthday: '',
  })

  const handleStepOneData = (data) => {
    setData(prevData=>({...prevData, ...data}))
  }

  const handleSkipStep = () => {
    setCurrentStep(prevStep => prevStep + 1)
  }

  const handleSubmitData = (data) => {
    setData(prevData=>({...prevData, ...data}))
    postCreateRequest(data)
  }

  const handlePrevStep = (data) => {
    setData(prevData=>({...prevData, ...data}))
    setCurrentStep(prevStep => prevStep - 1)
}
  const step = [
  <StepOne next={handleSkipStep} setData={handleStepOneData} data={data} padrao={postVerifyRequest} loading={loading} error={error} success={success} resposta={resposta} />, 
  <StepTwo next={handleSkipStep} postData={handleSubmitData} prev={handlePrevStep} data={data} loading={loadingCreate} error={errorCreate} success={successCreate} resposta={respostaCreate} />,
  <SuccessPage/>
]

  return (
    <Background>      
          <ReactSVG src={Logo} style={{ width: 75, margin: '0 auto' }} />         
          {!successCreate && <>
            <Title>Cadastro</Title>
            <Description>Por favor, insira seus dados para validarmos seu usuário.</Description>
          </>
          }
          {step[currentStep]}    
    </Background>
  )
};

const mapStateToProps = ({ cadastro }) => ({
  loading: cadastro.loading_verify,
  success: cadastro.success_verify,
  error: cadastro.error_verify,
  resposta: cadastro.data_verify,

  loadingCreate: cadastro.loading_create,
  successCreate: cadastro.success_create,
  errorCreate: cadastro.error_create,
  respostaCreate: cadastro.data_create

});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(CadastroActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cadastro);

