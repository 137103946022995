import styled from 'styled-components';

import colors from '@/styles/colors';

export const StyledContainer = styled.div`
  background-color: ${colors.background};
  color: ${colors.primary};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const WhiteBox = styled.div`
  background-color: ${colors.white};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  margin-top: 40px;
  padding: 32px;
`;

export const InfoDiv = styled.div`
display: flex;
align-items: center:
justify-content: center;
width: 100%;
margin-top: 16px;
color: #959595;
font-weight: bold;
margin-bottom: 16px;
`;

export const InfoDivBot = styled.div`
display: flex;
align-items: center:
justify-content: center;
width: 100%;
color: #959595;
margin-top: 16px;
`;

export const MsgDiv = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center
`;


export const Form = styled.form``;
