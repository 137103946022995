import { useEffect, useState } from 'react'
import api from '@/services/api';

export default function FindAnalises(q) {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [analises, setAnalises] = useState()
    const [control, setControl] = useState(false)


    useEffect(()=>{
        setLoading(true);
        try{
            api.get("/analises").then(res => {
            setAnalises(res.data.resposta)
            setLoading(false);
            })
        }
        catch(error){
            setError(true)
        }
        
    }, [q])

    return{ loading, error, control, analises}
}