import { call, put } from 'redux-saga/effects';
import api from '@/services/api';
import storage from '@/utils/storage';

import UsersActions from '@/store/ducks/users';

export function* getUsersRequest() {
  try {
    const endpoint = '/users';
    const { data } = yield call(api.get, endpoint);
    yield put(UsersActions.getUsersSuccess(data));
  } catch (error) {
    // console.log(error);
    yield put(UsersActions.getUsersFailure());
  }
}


export function* getMeRequest() {
  try {
    const endpoint = '/users/me';
    const { data } = yield call(api.get, endpoint);
    //const account = data.account.id;

    //const profile = yield call(api.get, `/accounts/${account}`);

    yield put(UsersActions.getUsersSuccess({
      user: {
        ...data.account,
        user: data
      }
    }));
  } catch (error) {
    // console.log(error);
    storage.removeToken();
    window.location.href = '/';
    yield put(UsersActions.getUsersFailure());
  }
}


export function* putMeRequest({ values }) {
  try {
    var bodyFormData = new FormData();

    bodyFormData.append("files.avatar", values.avatar);

    delete values.avatar;

    bodyFormData.append('data', JSON.stringify(values));

    const endpoint = '/users/me';
    const { data } = yield call(api.put, endpoint, bodyFormData);

    yield put(UsersActions.putMeSuccess());
  } catch (error) {
    console.log(error);
    yield put(UsersActions.putMeFailure());
  }
}
