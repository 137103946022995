
export function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isDate18orMoreYearsOld(date) {
  // console.log(date);
  const newDate = new Date(date.toString().replace('Date', ''));
  // console.log(newDate);
  const day = newDate.getDay()
  const month = newDate.getMonth()
  const year = newDate.getFullYear()

  return new Date(year + 18, month - 1, day) <= new Date();
}

export function validateCPF(cpf) {
  const strCPF = cpf.replace(/[^0-9]/g, '');
  var Soma;
  var Resto;
  Soma = 0;
  if (strCPF == "00000000000") return false;

  for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto == 10) || (Resto == 11)) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto == 10) || (Resto == 11)) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

export function validatePhone(phone) {
  const phoneValidate = phone.replace(/[^0-9]/g, '');
  var regex = new RegExp('^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$');
  return regex.test(phoneValidate);
}

export function validateCEP(cep) {
  const cepValidate = cep.replace(/[^0-9]/g, '');
  
  if(cepValidate.length < 8) {
    return false;
  }

  return true;
}

export function isBoleto(plano){
  const planosCheque = [20,21,24,25,27,28,29,30,31,32,33,34,35,500,501,611,612,613,614,620,621,624,625,627,628,629,630,631,632,633,634,635,636,643,644,650,651,660,661,662,663,668,669]
  if (planosCheque.includes(plano)) return false;
  return true
}