import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getCitiesRequest: ['values'],
  getCitiesSuccess: ['data'],
  getCitiesFailure: [],
});

export const CitiesTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  loading: false,
  data: [],
  error: false,
});

/* Reducers */

const getCitiesRequest = (state) => state.merge({
  ...state,
  loading: true,
});

const getCitiesSuccess = (state, { data }) => state.merge({
  data,
  loading: false,
  error: false,
});

const getCitiesFailure = (state) => state.merge({
  ...state,
  error: true,
  loading: false,
});

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CITIES_REQUEST]: getCitiesRequest,
  [Types.GET_CITIES_SUCCESS]: getCitiesSuccess,
  [Types.GET_CITIES_FAILURE]: getCitiesFailure,
});
