import React from 'react';
import AvatarImage from '@/assets/images/avatar.jpg'
import {
  Container,
  Figure,
  Avatar,
  Name,
  Email
} from './styles';
import { Link } from 'react-router-dom';
import colors from '@/styles/colors';

const UserCard = ({ user }) => {
  if (user) {
    return (
      <Container>
        <div>
          <Figure>
            <Avatar src={user.avatar && user.avatar.url ? `${user.avatar.url}` : AvatarImage} />
          </Figure>
        </div>
        <div>
          <Name>{user.name} <Link style={styles} to="/minha-conta">Minha conta</Link></Name>
          <Email>{user.user.email}</Email>
        </div>
      </Container>
    );
  }
  return null;
}

export default UserCard;

const styles = {
  display: 'inline-block',
  background: colors.white,
  borderRadius: 4,
  padding: [2, 8],
  color: colors.grayBold,
  fontWeight: 'bold',
  right: 0,
  fontSize: 16,
  top: -5,
  position: 'relative',
  textDecoration: 'none',
  marginLeft: 10,
  transition: 'all 250ms',

  '&:hover': {
    background: colors.default,
    color: colors.white,
  }
}