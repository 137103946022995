import styled from 'styled-components';

import colors from '@/styles/colors';

export const StyledContainer = styled.div`
  background: ${colors.white};
  border-radius: 5px;
  box-shadow: 0 1px 20px rgba(0,0,0,0.02);
  margin-bottom: 50px;
`;

export const Header = styled.div`
  padding: 20px 25px 0;
  text-align: left;
  border-radius: 7px 7px 0 0;
`
export const Title = styled.h3`
  font-size: 22px;
  color: ${colors.dark};
  font-weight: bold;
  margin: 0;
`;

export const Body = styled.div`
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
`;