import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as authentication } from './authentication';
import { reducer as users } from './users';
import { reducer as cep } from './cep';
import { reducer as bank } from './bank';
import { reducer as states } from './states';
import { reducer as cities } from './cities';
import { reducer as clients } from './clients';
import { reducer as proposal } from './proposal';
import { reducer as professions } from './professions';
import { reducer as factors } from './factors';
import { reducer as creddefense } from './creddefense';
import { reducer as signature } from './signature';
import { reducer as upload } from './upload';
import { reducer as notifications } from './notifications';
import { reducer as analise } from './analise';
import { reducer as cadastro } from './cadastro'

// import { reducer as session } from './session';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    authentication,
    users,
    cep,
    bank,
    states,
    cities,
    clients,
    proposal,
    professions,
    factors,
    creddefense,
    signature,
    upload,
    notifications,
    analise,
    cadastro
  });
