import React, { useState, useRef, useCallback, useEffect } from 'react';
import 'react-html5-camera-photo/build/css/index.css';
import currency from '@/utils/currency';
import moment from 'moment';
import 'moment/locale/pt-br';

import {
  Button
} from '@material-ui/core';

import {
  DivBlockText,
  Letra,
  ContentFormInside,
  SpecsBox,
  ValorEmprestimo,
  LittleItens,
  BigItens,
  F34Bold,
  F18,
  F14OP50,
  F14,
  F12,
  F10OP50
} from '../styles';




export default ({
  proposal,
  handleSubmit,
  loading
}) => {

  return (
    <ContentFormInside>
      <DivBlockText>
        <Letra>Ótimo! Estamos quase terminando.</Letra>
        <F14OP50>Confirme as condições do empréstimo que você está contratando.</F14OP50>
      </DivBlockText>
      <SpecsBox>
        <ValorEmprestimo>
          <F12>Valor do Empréstimo</F12>
          <F34Bold>{currency(proposal.padrao.ValorEmprestimo)}</F34Bold>
        </ValorEmprestimo>

        <BigItens>
          <F10OP50>Prazo</F10OP50>
          <F18>{proposal.padrao.Prazo}x</F18>
        </BigItens>

        <BigItens>
          <F10OP50>Parcela</F10OP50>
          <F18>{currency(proposal.padrao.ValorParcela)}</F18>
        </BigItens>

        <LittleItens>
          <F10OP50>Taxa de juros</F10OP50>
          <F14>{proposal.padrao.Taxa}%</F14>
        </LittleItens>

        <LittleItens>
          <F10OP50>Data do 1º vencimento</F10OP50>
          <F14>{moment(proposal.padrao.DataVencimento, 'YYYYMMDD').format('DD')} de {moment(proposal.padrao.DataVencimento, 'YYYYMMDD').format('MMMM')}</F14>

        </LittleItens>

        <LittleItens>
          <F10OP50>IOF</F10OP50>
          <F14>{currency(proposal.padrao.IOF)}</F14>

        </LittleItens>

        <LittleItens>
          <F10OP50>Forma de pagamento</F10OP50>
          <F14>{proposal.type_payment}</F14>

        </LittleItens>

        <LittleItens>
          <F10OP50>Custo efetivo total</F10OP50>
          <F14>{proposal.padrao.CET}% a.m.</F14>
        </LittleItens>

        <LittleItens>
          <F10OP50>Valor total</F10OP50>
          <F14>{currency(proposal.padrao.ValorParcela*proposal.padrao.Prazo)}</F14>
        </LittleItens>

      </SpecsBox>
      <Button fullWidth={false} size="large" variant="contained" disabled={loading} color="primary" onClick={handleSubmit}>Continuar</Button>
    </ContentFormInside>

  )
}