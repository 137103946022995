import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getCepRequest: ['value'],
  getCepSuccess: ['data'],
  getCepFailure: [],
});

export const CepTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  loading: false,
  data: {},
  error: false,
});

/* Reducers */

const getCepRequest = (state) => state.merge({
  ...state,
  loading: true,
});

const getCepSuccess = (state, { data }) => state.merge({
  data,
  loading: false,
  error: false,
});

const getCepFailure = (state) => state.merge({
  ...state,
  error: true,
  loading: false,
});

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CEP_REQUEST]: getCepRequest,
  [Types.GET_CEP_SUCCESS]: getCepSuccess,
  [Types.GET_CEP_FAILURE]: getCepFailure,
});
