import { call, put } from 'redux-saga/effects';

import CepActions from '@/store/ducks/cep';

export function* getCepRequest({ value }) {
  if (!value) {
    return null
  }

  const trim = value.replace('-', '');

  try {
    const endpoint = `https://viacep.com.br/ws/${trim}/json/`;
    const cep = yield fetch(endpoint);
    const data = yield cep.json();

    if (!cep.ok) {
      return yield put(CepActions.getCepFailure());
    }

    yield put(CepActions.getCepSuccess(data));
  } catch (error) {
    // console.log(error);
    yield put(CepActions.getCepFailure());
  }
}
