import React from 'react';

import Button from '@/components/Button'
import UserCard from '@/components/UserCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  RowLine,
  ColLine
} from './styles';

export default function HeaderUser({ user, iconButton, textButton, onClickButton = () => { }, button = true }) {
  return (
    <RowLine>
      <ColLine>
        <UserCard user={user} />
      </ColLine>
      {button && <ColLine style={{ justifyContent: 'flex-end' }}>
        <Button
          action={onClickButton}
          customStyles={{
            padding: '0 15px',
            height: '55px'
          }}
        >
          <FontAwesomeIcon icon={iconButton} /> {textButton}
        </Button>
      </ColLine>}
    </RowLine >
  );
}
