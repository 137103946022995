import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getClientsRequest: ['filters'],
  getClientsSuccess: ['data'],
  getClientsFailure: [],

  postClientsRequest: ['values'],
  postClientsSuccess: ['data'],
  postClientsFailure: [],

  getClientsByIdRequest: ['id'],
  getClientsByIdSuccess: ['data'],
  getClientsByIdFailure: [],

  postValidateClientRequest: ['values', 'token'],
  postValidateClientSuccess: ['data'],
  postValidateClientFailure: [],
});

export const ClientsTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  loading: false,
  data: [],
  client: null,
  error: false,
  success: false,
  success_verification: false
});

/* Reducers */

const getClientsRequest = (state) => state.merge({
  ...state,
  loading: true,
  success: false
});

const getClientsSuccess = (state, { data }) => state.merge({
  data,
  loading: false,
  error: false,
  success: false
});

const getClientsFailure = (state) => state.merge({
  ...state,
  error: true,
  loading: false,
  success: false
});


const getClientsByIdRequest = (state) => state.merge({
  ...state,
  loading: true,
  success: false
});

const getClientsByIdSuccess = (state, { data }) => state.merge({
  client: data,
  loading: false,
  error: false,
  success: false
});

const getClientsByIdFailure = (state) => state.merge({
  ...state,
  error: true,
  loading: false,
  success: false
});

const postClientsRequest = (state) => state.merge({
  ...state,
  loading: true,
  success: false
});

const postClientsSuccess = (state, { data }) => state.merge({
  data,
  loading: false,
  error: false,
  success: true
});

const postClientsFailure = (state) => state.merge({
  ...state,
  error: true,
  loading: false,
  success: false
});


const postValidateClientRequest = (state) => state.merge({
  ...state,
  loading: true,
  success_verification: false
});

const postValidateClientSuccess = (state, { data }) => state.merge({
  client: data,
  loading: false,
  error: false,
  success_verification: true
});

const postValidateClientFailure = (state) => state.merge({
  ...state,
  error: true,
  loading: false,
  success: false
});
/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CLIENTS_REQUEST]: getClientsRequest,
  [Types.GET_CLIENTS_SUCCESS]: getClientsSuccess,
  [Types.GET_CLIENTS_FAILURE]: getClientsFailure,

  [Types.POST_CLIENTS_REQUEST]: postClientsRequest,
  [Types.POST_CLIENTS_SUCCESS]: postClientsSuccess,
  [Types.POST_CLIENTS_FAILURE]: postClientsFailure,

  [Types.GET_CLIENTS_BY_ID_REQUEST]: getClientsByIdRequest,
  [Types.GET_CLIENTS_BY_ID_SUCCESS]: getClientsByIdSuccess,
  [Types.GET_CLIENTS_BY_ID_FAILURE]: getClientsByIdFailure,

  [Types.POST_VALIDATE_CLIENT_REQUEST]: postValidateClientRequest,
  [Types.POST_VALIDATE_CLIENT_SUCCESS]: postValidateClientSuccess,
  [Types.POST_VALIDATE_CLIENT_FAILURE]: postValidateClientFailure,
});
