import React from 'react';
import Loading from '@/assets/images/loading.svg';
import { ReactSVG } from 'react-svg';

export default ({ theme = 'default', children, loading, disabled, customStyles, action, className, ...props }) => {
  return (
    <button
      {...props}
      className={`button ${className} ${theme} ${loading ? 'loading' : ''} ${disabled ? 'disabled' : ''}`}
      disabled={loading || disabled}
      style={customStyles}
      onClick={action}
    >
      {loading ? <ReactSVG className="loading" src={Loading} /> : children}
    </button>
  )
};
