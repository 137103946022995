import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import moesif from 'moesif-browser-js'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'bootstrap/dist/css/bootstrap.min.css';
// eslint-disable-next-line quotes
import './assets/scss/app.scss';

import store from './store';
import Routes from './routes';

import GlobalStyled from './styles/global';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

function App() {

  useEffect(() => {
    if(window.location.hostname !== 'localhost') {
      console.log('INITIALIZATION')
      moesif.init({
        applicationId: 'eyJhcHAiOiIzNjU6MzMxIiwidmVyIjoiMi4wIiwib3JnIjoiNjkwOjI0NSIsImlhdCI6MTYxNzIzNTIwMH0.r5K-3i2YN8znfEf6KHMPD6usuNBzvEVon_BDo5pvSvs'
      });
    }
  }, [])

  return (
    <Provider store={store}>
      <GlobalStyled />
      <Routes />
      <ToastContainer
        autoClose={8000}
        closeButton={false}
        pauseOnHover
        position="bottom-right"
      />
    </Provider>
  );
}

export default App;
