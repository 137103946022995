import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  postUploadRequest: [],
  postUploadSuccess: ['data'],
  postUploadFailure: [],
});

export const UploadTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  loading: false,
  data: [],
  error: false,
});

/* Reducers */

const postUploadRequest = (state) => state.merge({
  ...state,
  loading: true,
});

const postUploadSuccess = (state, { data }) => state.merge({
  data,
  loading: false,
  error: false,
});

const postUploadFailure = (state) => state.merge({
  ...state,
  error: true,
  loading: false,
});

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.POST_UPLOAD_REQUEST]: postUploadRequest,
  [Types.POST_UPLOAD_SUCCESS]: postUploadSuccess,
  [Types.POST_UPLOAD_FAILURE]: postUploadFailure,
});
