import React, { useState, useEffect } from 'react'
import Lottie from 'react-lottie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import UsersActions from '@/store/ducks/users'
import ClientsActions from '@/store/ducks/clients'

import { Container, Modal } from 'react-bootstrap';

import animations from '@/assets/animations'

import HeaderUser from '@/components/HeaderUser'
import Table from '@/components/Table'
import Box from '@/components/Box'

import { faUserCircle, faPen, faTrash, faEye } from '@fortawesome/free-solid-svg-icons'
import CreatedClient from './Created'

import headers from './headers';
import Button from '@/components/Button'

function addAction(arr) {
  if (arr && arr.length) {
    return arr.map(item => ({
      id: item.id,
      name: item.name,
      email: item.email,
      cpf: item.cpf,
      phone: item.phone,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      tableActions: null
    }))
  }
}

function Clients({ user, history, getClientsRequest, clients, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getClientsRequest();
  }, [])

  if (!user) {
    return (
      <Lottie
        height={400}
        width={400}
        options={{
          loop: true,
          autoplay: true,
          animationData: animations.loading
        }}
      />
    )
  }
  /*if(clients && clients.length > 0){
  console.log('referencia', clients[0].referencias.NomeReferencia, clients[0].referencias.EmailReferencia, clients[0].referencias.TelReferencia);
  clients[0].identity.forEach(e => {console.log("identidade: ",e)});
  }*/
  return (
    <>
      <Container fluid className="animated fadeIn">
        <HeaderUser
          user={user}
          iconButton={faUserCircle}
          textButton="Criar Cliente"
          onClickButton={handleShow}
        />
        <Box table>
          <Table
            data={addAction(clients)}
            headers={{
              ...headers,
              tableActions: {
                text: '',
                invisible: false,
                sortable: false,
                filterable: false,
                transform: (value, index, row) => {
                  return (
                    <div className="group-buttons">
                      <Link to={`/clientes/${row.id}`}><Button className="circle small"><FontAwesomeIcon size={14} icon={faEye} /></Button></Link>
                      {/* <Button className="circle small" action={() => alert()} theme="danger"><FontAwesomeIcon size={14} icon={faTrash} /></Button> */}
                    </div>
                  )
                }
              }
            }}
          />
        </Box>
      </Container>

      <Modal
        size="xl"
        show={show}
        //onHide={handleClose}
        onHide={() => {
          handleClose()
          getClientsRequest({
            name: 1,
            email: 1,
            cpf: 1,
            phone: 1,
            proposals: 1
          })
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Novo Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreatedClient closeModal={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  );
}


const mapStateToProps = ({ users, clients }) => ({
  user: users.data.user,
  loading: users.loading,
  clients: clients.data
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  ...UsersActions,
  ...ClientsActions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
