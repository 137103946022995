import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FactorsActions from '@/store/ducks/factors';
import AnaliseActions from "@/store/ducks/analise";
import ClientsActions from '@/store/ducks/clients';

import {WhiteBox, Form, InfoDiv, InfoDivBot, MsgDiv} from './styles';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Col, Row, Modal, Container } from 'react-bootstrap';
import FormGroup from '@/components/FormGroup';
import Button from '@/components/Button';
import moment from 'moment';
import Slider from '@material-ui/core/Slider';
import currency from '@/utils/currency';
import cogoToast from 'cogo-toast';
import CurrencyFormat from 'react-currency-format';
import CreatedClient from '@/pages/Clients/Created';
import Table from '@/components/Table'
import headers from './headers';
import UseAnalises from '@/hooks/UseAnalises';
//import { Center } from '../Login/styles';


const AnaliseRapida = ({
  getFactorsRequest,
  postAnaliseRequest,
  getClientsRequest,
  loading_factors,
  factors,
  analise,
  analise_success, 
  analise_loading,
}) => {

  const [birthday, setBirthday] = useState('');
  const [ValueSlider, setValueSlider] = useState(500);
  const [parcel, setParcel] = useState(3);
  const [parcela, setParcela] = useState(500);
  const [validade, setValidade] = useState(moment().add('15', 'days').format('YYYYMMDD'));
  const [clienteAnalise, setClienteAnalise] = useState()

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {loading, error, control, analises } = UseAnalises(analise_success);
  //console.log("tets:", analises, loading, error)
      
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    email: Yup.string().required('Campo obrigatório'),
    cpf: Yup.string().required('Campo obrigatório'),
    birthday: Yup.string().required('Campo obrigatório'),
    phone: Yup.string().required('Campo obrigatório')
  });


  useEffect(() => {
    if (factors.length)
      setParcela(currency(String((factors[24 - parcel].ValorParcela))));
  }, [factors]);

  
  function handleGetFactors(values) {
    return getFactorsRequest(values);
  }

  const handleNext = (factors) => {
    if (parcel === 18) {
      return cogoToast.error('Esse é o limite máximo de parcelas');
    }

    const count = parcel + 1;


    if (factors.length)
      setParcela(currency(String((factors[24 - count].ValorParcela))));
    setParcel(count);
  };


  const handlePrev = (factors) => {
    if (parcel === 3) {
      return cogoToast.error('Esse é o limite mínimo de parcelas');
    }

    const count = parcel - 1;

    if (factors.length)
      setParcela(currency(String((factors[24 - count].ValorParcela))));
    setParcel(count);
  };

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        onSubmit={(values) => {
          
          const data = {
            ...values
          };
          postAnaliseRequest(data);
        } }

        initialValues={{
          name: '',
          email: '',
          cpf: '',
          birthday: '',
          phone: ''
        }}
      >
        {({
          values, errors, handleSubmit, handleChange, setFieldValue, setFieldError, handleBlur, isValid,
        }) => {

          return (
            <>
              <WhiteBox>
                <Form className="form" onSubmit={handleSubmit}>
                  <Row>
                    <Col md={4} xs={12}>
                      <fieldset className='border-0'>
                        <Row>
                          <Col md={12} xs={12} className="br">
                            <h4>Valores</h4>
                            <div className="value-input" style={{ fontSize: "24px" }}>
                              <CurrencyFormat disabled className="numero-disabled" style={{ border: 0, fontSize: "50px", fontWeight: "700", color: "#333" }}
                                thousandSeparator="."
                                prefix={'R$'}
                                thousandSpacing="2s"
                                decimalSeparator=","
                                value={ValueSlider} />
                            </div>
                            <Slider
                              min={500}
                              max={20000}
                              step={100}
                              onChange={(e, newValue) => {
                                setValueSlider(newValue || 500);
                              } }
                              onChangeCommitted={() => {
                                handleGetFactors({
                                  first_maturity: validade,
                                  value: ValueSlider,
                                  type: values.type_payment
                                });
                              } } />
                              
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: "40px", marginTop: "16px", fontSize: "24px" }}>
                              <div><strong>{parcel}x</strong> parcelas de <strong> {loading_factors ? 'calculando...' : parcela}</strong></div>
                              <div style={{ display: "flex", justifyContent: "space-around", width: "20%" }}>
                                <Button theme="clean" action={() => handlePrev(factors)} type="button"><strong>&#8722;</strong></Button>
                                <Button theme="clean" action={() => handleNext(factors)} type="button"><strong>&#43;</strong></Button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} xs={12}>
                            <Field
                              component={FormGroup}
                              label={'Dia da vencimento'}
                              type="date"
                              name="first_maturity"
                              placeholderText="Ex: 01/01/1990"
                              placeholder="Ex: 01/01/2020"
                              showYearDropdown
                              showMonthDropdown
                              selected={moment(validade).toDate()}
                              minDate={moment(new Date(), 'DDDD.MM.YYYY').add('15', 'days').toDate()}
                              maxDate={moment(new Date(), 'DDDD.MM.YYYY').add('45', 'days').toDate()}
                              onChange={(e) => {
                                setFieldValue('first_maturity', e);
                                setValidade(moment(e).format('YYYYMMDD'));
                                handleGetFactors({
                                  first_maturity: validade,
                                  value: ValueSlider,
                                  type: values.type_payment
                                });
                              } } />
                          </Col>
                          <Col md={6} xs={12}>
                            <Field
                              component={FormGroup}
                              label={"Tipo do pagamento"}
                              name="type_payment"
                              type="select"
                              onChange={(event) => {
                                handleChange(event);
                                handleGetFactors({
                                  first_maturity: validade,
                                  value: ValueSlider,
                                  type: event.target.value
                                });
                              } }
                              options={[
                                {
                                  label: 'Boleto',
                                  value: 'boleto'
                                },
                                {
                                  label: 'Cheque',
                                  value: 'cheque'
                                }
                              ]} />
                          </Col>
                        </Row>
                      </fieldset>
                    </Col>

                    <Col>
                      <fieldset className='border-0'>
                        <h4>Cliente</h4>
                        <Row>
                          <Col md={6} xs={12}>
                            <Field
                              component={FormGroup}
                              label={'Nome Completo'}
                              error={errors.name}
                              placeholder="Ex: João Henrique de Almeida"
                              name="name" />
                          </Col>
                          <Col md={6} xs={12}>
                            <Field
                              component={FormGroup}
                              label={'E-mail'}
                              placeholder="Ex: cliente@exemplo.com"
                              name="email"
                              type="email"
                              error={errors.email} />
                          </Col>
                          <Col md={4} xs={12}>
                            <Field
                              component={FormGroup}
                              label={'CPF'}
                              mask="111.111.111-11"
                              placeholder="Ex: 999.999.999-99"
                              name="cpf"
                              error={errors.cpf} />
                          </Col>
                          <Col md={4} xs={12}>
                            <Field
                              component={FormGroup}
                              error={errors.birthday}
                              selected={birthday}
                              label={'Data de nascimento'}
                              placeholder="Ex: 01/01/1990"
                              mask="11/11/1111"
                              name="birthday"
                              onChange={(e) => {
                                const val = e.target.value;
                                setBirthday(moment(val, 'DD/MM/YYYY').format());
                                setFieldValue('birthday', val);
                              } } />
                          </Col>
                          <Col md={4} xs={12}>
                            <Field
                              component={FormGroup}
                              label={'Celular'}
                              placeholder="Ex: (21) 99999-9999"
                              name="phone"
                              mask="(11) 11111-1111"
                              error={errors.phone} />
                          </Col>
                        </Row>
                      </fieldset>

                      <div className="text-center footer">
                        <Button className="min-150" disabled={!isValid} loading={analise_loading} type="submit">
                          Consultar
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </WhiteBox>
            </>
          );
        } }
      </Formik>
      <WhiteBox>
      <Table
        data={analises}
        perPage={5}
        orderedHeaders={["cpf","nome", "celular", "email", "proposta", "status", "tableActions"]}                  
        headers={{
          ...headers,
          tableActions: {
            text: '',
            invisible: false,
            sortable: false,
            filterable: false, 
            transform: (value, index, row) => {
              if(row.status == "aprovado"){
                return (                                     
                    <Button action={() => {
                      setClienteAnalise({
                        nome: row.nome,
                        cpf: row.cpf,
                        email: row.email,
                        proposta: row.proposta,
                        celular: row.celular
                      })
                      handleShow()
                    }} className={"small tableAnalise"}>Cadastrar cliente</Button>
                )  
              }           
            }
          }
          }                                         
        }
                    />
      </WhiteBox>

      <Modal
        size="xl"
        show={show}
        //onHide={handleClose}
        onHide={() => {
          handleClose()
          getClientsRequest({
            name: 1,
            email: 1,
            cpf: 1,
            phone: 1,
            proposals: 1
          })
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Novo Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreatedClient clienteAnalise={clienteAnalise} closeModal={handleClose} />
        </Modal.Body>
      </Modal>

    </>
  );

}

const mapStateToProps = ({ factors, analise }) => ({
  factors: factors.data,
  loading_factors: factors.loading, 
  analise: analise.data,
  analise_loading: analise.loading,
  analise_success: analise.success
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    ...FactorsActions,
    ...AnaliseActions,
    ...ClientsActions
  },
  dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AnaliseRapida);

{/*(analise_success && analise && !analise_loading) &&  
        <Row>
          <Col md={4} sm={6} xs={12}>
            <WhiteBox>
              <h4>Resultado</h4>
              <Container style={{marginTop: "42px"}}>
                <Container fluid style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
                  {analise.aprovado && <div style={{marginBottom:"32px"}} className="badge badge-success">Aprovado</div>}
                  {!analise.aprovado && <div style={{marginBottom:"32px"}} className="badge badge-danger">Recusado</div>} 
                </Container>
                <MsgDiv>
                  <p style={{marginBottom:"32px", color: "#959595"}}>{analise.mensagem}</p>
                  {(analise.aprovado && analise.newClient) && <Button action={handleShow}>Cadastrar cliente</Button>}
                </MsgDiv>
              </Container>
            </WhiteBox>
          </Col>

          <Col md={8} sm={6} xs={12}>
            <WhiteBox>
            <h4>Dados</h4>
            <Container style={{marginTop: "42px"}}>
              <Row style={{backgroundColor: "#F5F5F5", borderRadius: "5px"}}>
                <Col md={3} sm={6} xs={12}><InfoDiv>Nome</InfoDiv></Col>
                <Col md={3} sm={6} xs={12}><InfoDiv>Email</InfoDiv></Col>
                <Col md={2} sm={6} xs={12}><InfoDiv>Cpf</InfoDiv></Col>
                <Col md={2} sm={6} xs={12}><InfoDiv>Nascimento</InfoDiv></Col>
                <Col md={2} sm={6} xs={12}><InfoDiv>Celular</InfoDiv></Col>
              </Row>
              <Row>
                <Col md={3} sm={6} xs={12}><InfoDivBot>{analise.nome}</InfoDivBot></Col>
                <Col md={3} sm={6} xs={12}><InfoDivBot>{analise.email}</InfoDivBot></Col>
                <Col md={2} sm={6} xs={12}><InfoDivBot>{analise.cpf}</InfoDivBot></Col>
                <Col md={2} sm={6} xs={12}><InfoDivBot>{analise.nascimento}</InfoDivBot></Col>
                <Col md={2} sm={6} xs={12}><InfoDivBot>{analise.phone}</InfoDivBot></Col>
              </Row>
              </Container>
            </WhiteBox>
          </Col>
        </Row>
      */}

