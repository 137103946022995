import React, { useState, useRef, useCallback, useEffect } from 'react';
import 'react-html5-camera-photo/build/css/index.css';

import { getIp, getBrowser } from '@/utils/events';

import {
  ProgressDiv,
  ContentForm,
} from './styles';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ClientsActions from '@/store/ducks/clients';
import CreddefenseActions from '@/store/ducks/creddefense';
import ProposalActions from '@/store/ducks/proposal';
import SignatureActions from '@/store/ducks/signature';

import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import Step5 from './Steps/Step5';
import Step6 from './Steps/Step6';
import Step7 from './Steps/Step7';
import Step8 from './Steps/Step8';
import storage from '@/utils/storage';
import Lottie from 'react-lottie';
import animations from '@/assets/animations';
import Step10 from './Steps/Step10';
import StepCPF from './Steps/StepCPF';
import StepTermos from './Steps/StepTermos';


function hasGetUserMedia() {
  return !!(navigator.mediaDevices &&
    navigator.mediaDevices.getUserMedia);
}

function Signature({
  getCreddefenseRequest,
  getCreddefenseStatusRequest,
  getProposalByIdRequest,
  getSignatureRequest,
  generatePdfRequest,

  postVerifyCodeRequest,
  postValidateClientRequest,

  putAuditTrailRequest,
  formalizeSignatureRequest,

  success,
  success_request,
  success_code,
  success_formalize,
  success_signature,
  success_pdf,
  success_verification,

  loading_creddefense,
  proposal_loading,
  loading_trail,
  loading_status,
  loading_client,
  loading,

  error,

  history,
  creddefense,
  client,
  proposal,
  ...props
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [porcento, setProgress] = useState(0);
  const [showSignature, setShowSignature] = useState(false);
  const [cam] = useState(true);
  const [token] = useState(history.location.search.replace('?token=', ''));
  const [trail, setTrail] = useState({
    ip: '',
    browser: '',
    lat: '',
    lng: '',
    version: '',
    event: ''
  })
  const [hide, setHide] = useState(false);

  const handleInfoAgent = async () => {
    const ip = await getIp();
    const geolocation = await storage.getStorage('geolocation');

    let data = {
      ip,
      browser: await getBrowser(navigator.userAgent),
      version: navigator.appVersion,
      userAgent: navigator.userAgent,
    }

    if (geolocation && JSON.parse(geolocation).lat && JSON.parse(geolocation).lng) {
      data = {
        ...data,
        ...JSON.parse(geolocation)
      }
    }

    return data;
  }

  useEffect(() => {
    if (activeStep == 9) setHide(true);
    const porcent = 10 * activeStep;
    setProgress(porcent)
  }, [activeStep])

  useEffect(() => {
    if (success_code) {
      nextPage('Validando código de verificação')
    }
  }, [success_code]);

  useEffect(() => {
    if (success_request) {
      nextPage('Aprovação creddefense')
    }
  }, [success_request]);

  useEffect(() => {
    if (success) {
      storage.removeStorage('geolocation');
      nextPage('Avaliação da creddefense');
    }
  }, [success]);

  useEffect(() => {
    if (success_formalize) {
      nextPage('Formalização da proposta.')
    }
  }, [success_formalize])

  useEffect(() => {
    if (success_signature) {
      nextPage('Proposta assinada.')
    }
  }, [success_signature]);

  useEffect(() => {
    if (success_verification) {
      nextPage('Validação do CPF')
    }
  }, [success_verification])

  useEffect(() => {
    handleInfoAgent()
      .then(data => {
        setTrail(data);
        if (data && data.lat && data.lng) {
          setActiveStep(1);
        }
      })
  }, []);

  const nextPage = (event) => {
    if (!event) {
      return setActiveStep(activeStep + 1);
    }


    const dataLog = {
      ...trail,
      event
    }

    let logs = proposal ? [...proposal.logs, dataLog] : [dataLog];

    const split = token.split('-');
    const id = split[split.length - 1];

    putAuditTrailRequest({
      logs
    }, id, setActiveStep(activeStep + 1), token);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Step1 setGeolocation={setTrail} trail={trail} nextPage={nextPage} />;     
      case 1:
        return (<StepCPF
          loading={loading_client}
          onSubmit={(values) => {
            postValidateClientRequest(values, token)
          }}
        />);
      case 2:
        return (
          <Step2
            loading={proposal_loading}
            handleSubmit={(code) => {
              postVerifyCodeRequest({ code }, token)
            }}
          />
        );
      case 3:
        return (
          <Step3 proposal={proposal} handleSubmit={() => {
            nextPage('Página de informação da liberação do pagamento')
          }} />
        );
      case 4:
        return <Step4
          handleSubmit={() => {
            nextPage('Página de informação da selfie')
          }}
        />;
      case 5:
        return (
          <Step5
            cam={cam}
            proposal={proposal}
            token={token}
            getCreddefenseRequest={getCreddefenseRequest}
            loading_creddefense={loading_creddefense}
          />
        );

      case 6:
        return (
          <Step6
            handleCreddefense={() => getCreddefenseStatusRequest({ id: creddefense.id, proposal: proposal.id }, token)}
            success={success}
          />
        )
      case 7:
        return <Step7 
        loading={loading} 
        proposal={proposal} 
        handleSubmit={() => formalizeSignatureRequest({ proposal: proposal.id }, token)} 
        />;

      case 8:
        return (
          <StepTermos
            setHide={setHide}
            handleSubmit={() => {
              nextPage('Termos de adesão aceito pelo usuário')
            }}
            plano={proposal.padrao.Plano}
          />
        )

      case 9:
        return <Step8
          showSignature={showSignature}
          loading={loading}
          proposal={proposal}
          getSignatureRequest={getSignatureRequest}
          history={history}
          setShowSignature={setShowSignature}
        />

      case 10: {
        return <Step10
          generatePdfRequest={generatePdfRequest}
          history={history}
          proposal={proposal.id}
          success={success_pdf}
          token={token}
        />;
      }
      default:
        return null
    }
  }

  return (
    <ContentForm>
      {!hide && (
        <ProgressDiv>
          <progress value={porcento} max="100"></progress>
        </ProgressDiv>)
      }

      {loading_trail ? (
        <>
          <Lottie
            width={200}
            height={200}
            options={{
              loop: true,
              autoplay: true,
              animationData: animations.loading
            }}
          />
        </>
      ) : getStepContent(activeStep)}


    </ContentForm>
  );
}

const mapStateToProps = ({ creddefense, clients, proposal, signature }) => ({
  loading_creddefense: creddefense.loading,
  loading_status: creddefense.loading_status,
  creddefense: creddefense.data,
  error: creddefense.error,
  success_request: creddefense.success_request,
  success: creddefense.success,

  client: clients.client,
  loading_client: clients.loading,
  success_verification: clients.success_verification,

  proposal: proposal.proposal,
  proposal_loading: proposal.loading,
  success_code: proposal.success_code,
  loading_trail: proposal.loading_trail,

  loading: signature.loading,
  success_formalize: signature.success,
  success_signature: signature.success_signature,
  success_pdf: signature.success_pdf
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  ...ClientsActions,
  ...CreddefenseActions,
  ...ProposalActions,
  ...SignatureActions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Signature);

