export default {
  white: '#fff',
  gray: '#F5F5F5',
  grayMedium: '#D3D3D3',
  grayBold: '#959595',
  dark: '#202020',

  default: '#444CF0',
  secundary: '#27D086',

  error: '#FF5E5E',
  success: '#27D086',
  warning: '#FFCC00'
};
