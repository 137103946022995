import { call, put } from 'redux-saga/effects';

import BankActions from '@/store/ducks/bank';
import api from '@/services/api';

export function* getBankRequest({ values }) {
  try {
    const endpoint = '/banks';
    const { data } = yield call(api.get, endpoint, {
      params: values.params
    });

    yield put(BankActions.getBankSuccess(data));
  } catch (error) {
    yield put(BankActions.getBankFailure());
  }
}
