import { call, put } from 'redux-saga/effects';
import api from '@/services/api';

import AnaliseActions from '@/store/ducks/analise';

export function* postAnaliseRequest(values) {
    try {
      const endpoint = '/clients/analise';
      const { data } = yield call(api.post, endpoint, values);
      yield put(AnaliseActions.postAnaliseSuccess(data));
    } catch (error) {
      yield put(AnaliseActions.postAnaliseFailure());
    }
}

  
  