import React from 'react';
import {
  StyledContainer,
  Header,
  Title,
  Body
} from './styles';

export default function Box({ title, children, align = 'flex-start', alignHeader = 'left', table = false }) {
  return (
    <StyledContainer className={table ? 'box-table' : 'box'}>
      {title && <Header className="header" style={{ textAlign: alignHeader }}><Title>{title}</Title></Header>}
      <Body style={{ alignItems: align }} className="box-body">
        {children}
      </Body>
    </StyledContainer>
  );
}
