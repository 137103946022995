import React from 'react';
import { StyledContainer } from './styles';

import SmartDataTable from 'react-smart-data-table';
import storage from '@/utils/storage';

export default function Table({ data, perPage, searchBox, ...props }) {
  return (
    <>
      <SmartDataTable
        className="table"
        data={data}
        dataKey=''
        name='data-table'
        loader={(
          <div className="loading">
            Loading...
          </div>
        )}
        emptyTable={(
          <div className="message-table">
            Nenhum Registro
          </div>
        )}
        {...props}
        perPage={perPage || 10}
        sortable={true}
        filterValue={searchBox}
      />
    </>
  );
}
