import styled from 'styled-components';

import colors from '@/styles/colors';



export const Title = styled.h4`
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 20px;
`

export const ContentVideo = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;

  video {
    width: 100%;
    height: 450px;
    border-radius: 30px;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.16);
  }
`

/* css nova form*/

export const Form = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  .form-group  {
    text-align: center;
  }
`;

export const ContentForm = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 85vh;
    align-items: center;

    @media (max-height: 500px) {
      height: auto;
      padding-bottom: 40px;
    }
`
export const ContentFormInside = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  overflow: hidden;
    .input {
      max-width: 240px;
      width: 100%;
      text-align: center;
      height: 48px;
      border: none;
      outline: none;
      border-bottom: 2px solid rgba(52, 57, 85, 0.3);
      color: #343955;
    }
  `

export const ProgressDiv = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-right: 36px;
  padding-left: 36px;
`

export const DivBlockText = styled.div`
  max-width: 240px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  `

export const Letra = styled.h1`
  color: #343955;
  font-size: 20px;
  font-weight: bold;
`
export const Letra2 = styled.h2`
  color: #343955;
  font-size: 14px;
  font-weight: normal;
`

export const ImgBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const TokenInputWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  font-size: 24px;
`

export const ButWrapToken = styled.div`
  height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const F34Bold = styled.h5`
  color: #343955;
  font-size: 34px;
  font-weight: bold;
`

export const F18 = styled.h6`
  color: #343955;
  font-size: 18px;
  font-weight: bold;
`

export const F14OP50 = styled.h6`
  color: #343955;
  font-size: 14px;
  font-weight: normal;
  opacity: 0.5; 
`
export const F14 = styled.h6`
  color: #343955;
  font-size: 14px;
  font-weight: normal;
`
export const F12 = styled.h3`
  color: #343955;
  font-size: 12px;
  font-weight: normal;
  opacity: 0.5; 
`
export const F10OP50 = styled.h6`
  color: #343955;
  font-size: 10px;
  font-weight: normal;
  opacity: 0.5;
`
export const F10 = styled.h6`
  color: #343955;
  font-size: 10px;
  font-weight: normal;
`
export const SpecsBox = styled.div`
  display: grid;
  max-width: 300px;
  min-width: 240px;
  grid-template-areas: "valorEmprestimo valorEmprestimo"
                       "prazo parcela"
                       "taxa 1venc"
                       "iof formPag"
                       "cet valorTotal";
  column-gap: 56px;
`

export const ValorEmprestimo = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: valorEmprestimo;
  line-height: 36px;
`

export const BigItens = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  line-height: 22px;
`

export const LittleItens = styled.div`
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: column;
`

export const CpfInput = styled.input`
  max-width: 240px;
  height: 48px;
  border: none;
  outline: none;
  border-bottom: 2px solid rgba(52, 57, 85, 0.3);
  color: #343955;
`

export const DivButtonAssinatura = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  width: 380px;
  height: 100px;
`

export const ButtonAssinatura = styled.button`
  outline: none;
  appearance: none;
  border: none;
  width: 175px;
  height: 34px;
  border-radius: 17px;
  background-color: #444cf0;
  color: white;
  font-size: 12px;
  cursor: pointer;
`

export const ButtonLimparAssinatura = styled.button`
  outline: none;
  appearance: none;
  border: none;
  width: 175px;
  height: 34px;
  border-radius: 17px;
  background-color: #D8D8D8;
  color: #343955;
  font-size: 12px;
  cursor: pointer;  
`

export const DivBlockTextAssinatura = styled.div`
  max-width: 240px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  `

  export const ButtonTermos = styled.button`
  outline: none;
  appearance: none;
  border: none;
  width: 175px;
  height: 34px;
  border-radius: 17px;
  background-color: #444cf0;
  color: white;
  font-size: 12px;
  cursor: pointer;
`

  export const TermosDiv = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    font-size: 14px;
    padding-right: 8px;

    div{
      width: 100%;
      text-align: justify;
    }
  `

  export const TermosBox = styled.div`
    display: flex;
    width: 100%;
    max-width: 340px;
    height: 75vh;
    overflow: auto;

    ::-webkit-scrollbar {
      width: 6px;
    }
    
    ::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
     
    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
    }
    
    ::-webkit-scrollbar-thumb:hover {
      background: #444cf0;
    }
  `

  export const TermosContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  `