import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTrash } from '@fortawesome/free-solid-svg-icons';
import DatePicker, { registerLocale } from "react-datepicker";
import MaskedInput from 'react-maskedinput';
import ptBR from 'date-fns/locale/pt-BR';
import Autocomplete from "react-autocomplete-select";
import IntlCurrencyInput from "react-intl-currency-input"
import { DateRangePicker } from 'react-date-range'
import { createStaticRanges } from 'react-date-range/dist/defaultRanges'
import "react-datepicker/dist/react-datepicker.css";
import { pt } from 'react-date-range/dist/locale/index';
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
} from 'date-fns';

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};


const defaultStaticRanges = createStaticRanges([
  {
    label: 'Hoje',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Ontem',
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
  },

  {
    label: 'Esta semana',
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    label: 'Última semana',
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    }),
  },
  {
    label: 'Este mês',
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
  {
    label: 'Última mês',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
]);


registerLocale('pt-BR', ptBR);

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export default ({
  type,
  label,
  options,
  children,
  error,
  mask = false,
  field,
  form,
  ...props
}) => {
  switch (type) {
    case 'date-range':
      return (
        <DateRangePicker
          locale={pt}
          staticRanges={defaultStaticRanges}
          dateDisplayFormat="dd/MM/yyyy"
          {...props}
        />
      )
    case 'select':
      return (
        <div className={`form-group ${error ? 'error' : ''}`}>
          {label && <label>{label}</label>}
          <select {...field} {...props} >
            {children}
            {options.map(({ label, value }, index) => (
              <option key={index} value={value}>
                {label}
              </option>
            ))}
          </select>
          {error && <p>{error}</p>}
        </div>
      );
    case 'file':
      return (
        <div className={`form-group ${error ? 'error' : ''}`}>
        <div className={`file-container ${field.value && field.value[0] && field.value[0].name ? "active" : ""}`}>
            <div className="group-icon">
              <FontAwesomeIcon icon={faUpload} size="lg" /> {field.value && field.value[0] && !field.value[0].name ? 'Selecione o arquivo' : ''}
            </div>
            <div className="file-input">
              <div className="file-name">{ field.value && field.value[0] && field.value[0].name ? `${field.value.length} arquivos selecionados` : 'Arquivo não selecionado'}</div>
        </div>

            <button
              className="trash"
              type="button"
              onClick={() => {
                form.setFieldValue(field.name, null);
              }}><FontAwesomeIcon icon={faTrash} size="lg" />
              Excluir
              </button>
            <input
              multiple
              className="file-control"
              type="file"
              name={field.name || props.name}
              {...props}
              {...field}
              value=""
              onChange={(event) => {
                let envio = [];
                event.target.files.forEach(element => {
                  envio.push(element);
                });
                //form.setFieldValue(field.name, event.target.files[0]);
                form.setFieldValue(field.name, envio);
              }}
            />
          </div>
          {error && <p>{error}</p>}
        </div>
      )
    case 'currency':
      return (
        <div className={`form-group ${error ? 'error' : ''}`}>
          {label && <label>{label}</label>}
          <IntlCurrencyInput
            currency="BRL"
            config={currencyConfig}
            placeholder={field && field.placeholder || props.placeholder}
            name={field && field.name || props.name}
            value={field && field.value || props.value}
            onChange={field && field.onChange || props.onChange}
          />
        {error && <p>{error}</p>}
        </div>
      )
    case 'date':
      return (
        <div className={`form-group ${error ? 'error' : ''}`}>
          {label && <label>{label}</label>}
          <DatePicker
            locale={ptBR}
            dateFormat="dd/MM/yyyy"
            name={field.name || props.name}
            value={field.value || props.value}
            onChange={field.onChange || props.onChange}
            customInput={<MaskedInput mask="11/11/1111" />}
            {...props}
          />
          {error && <p>{error}</p>}
        </div>
      )
    case 'textarea':
      return (
        <div className={`form-group ${error ? 'error' : ''}`}>
          {label && <label>{label}</label>}
          <textarea {...props} {...field} />
        </div>
      )
    case 'autocomplete':
      return (
        <div className={`form-group ${error ? 'error' : ''}`}>
          {label && <label>{label}</label>}
          <Autocomplete
            onChange={props.onChange}
            name={props.name || field.name}
            value={props.value || field.value}
            inputJSX={(propsInput) => mask ? <MaskedInput mask={mask} {...propsInput} /> : <input type="text" {...propsInput}></input>}
            {...props}
          />
        </div>
      )
    default:
      const defaultFunction = (e) => {
        const val = e.target.value;
        form.setFieldValue(field.name, val);
      }
      return (
        <div className={`form-group ${error ? 'error' : ''}`}>
          {label && <label>{label}</label>}
          {mask ? <MaskedInput mask={mask} {...props} {...field} onChange={props.onChange || defaultFunction} /> : <input type={type} {...props} {...field} />}
          {error && <p>{error}</p>}
        </div>
      );
  }
};

