import { call, put } from 'redux-saga/effects';
import api from '@/services/api';

import FactorsActions from '@/store/ducks/factors';

export function* getFactorsRequest({ values }) {
  try {
    const endpoint = '/proposals/factors';
    const { data } = yield call(api.post, endpoint, values);
    yield put(FactorsActions.getFactorsSuccess(data));
  } catch (error) {
    // console.log(error);
    yield put(FactorsActions.getFactorsFailure());
  }
}
