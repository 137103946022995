import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getBankRequest: ['values'],
  getBankSuccess: ['data'],
  getBankFailure: [],
});

export const BankTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  loading: false,
  data: [],
  error: false,
});

/* Reducers */

const getBankRequest = (state) => state.merge({
  ...state,
  loading: true,
});

const getBankSuccess = (state, { data }) => state.merge({
  data,
  loading: false,
  error: false,
});

const getBankFailure = (state) => state.merge({
  ...state,
  error: true,
  loading: false,
});

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_BANK_REQUEST]: getBankRequest,
  [Types.GET_BANK_SUCCESS]: getBankSuccess,
  [Types.GET_BANK_FAILURE]: getBankFailure,
});
