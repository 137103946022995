import moment from 'moment';
import status from '@/utils/status';

export default {
  id: {
    invisible: true,
  },
  proposal_external: {
    text: 'ID',
  },
  status: {
    transform: (value) => status(value),
    sortable: false,
    filterable: true,
  },
  email: {
    sortable: false,
  },
  token: {
    invisible: true,
  },
  padraoParcela: {
    invisible: true,
  },
  padraoObs: {
    invisible: true,
  },
  padraoPrazo: {
    invisible: true,
  },
  padraoStatus: {
    invisible: true,
  },
  tipoP: {
    invisible: true,
  },
  /*},"padrao.ValorParcela":{
    invisible: true
  },"padrao.Proposta":{
    invisible: true
  },"padrao.Taxa":{
    invisible: true
  },"padrao.Tac":{
    invisible: true
  },"padrao.ValorEmprestimo":{
    invisible: true
  },"padrao.Seguro":{
    invisible: true
  },"padrao.Plano":{
    invisible: true
  },"padrao.IOF":{
    invisible: true
  },"padrao.DataVencimento":{
    invisible: true
  },"padrao.Contrato":{
    invisible: true
  },"padrao.ContratoFinanceira":{
    invisible: true
  },"padrao.NomeCCBD":{
    invisible: true
  },"padrao.Observacao":{
    invisible: true
  },*/

  cpf: {
    text: 'CPF',
    sortable: false,
  },
  phone: {
    text: 'Telefone',
    sortable: false,
  },
  client: {
    text: 'Beneficiário',
    sortable: false,
  },
  client_id: {
    invisible: true,
  },
  value: {
    text: 'Valor',
    transform: (value) => `R$ ${value?.toLocaleString('pt-BR')}`,
    sortable: false,
    filterable: false,
  },
  term: {
    text: 'Prazo',
    invisible: true,
  },
  first_maturity: {
    text: 'Primeira parcela',
    invisible: true,
  },
  createdAt: {
    text: 'Data de criação',
    transform: (value) => moment(value).format('DD/MM/YYYY'),
    filterable: false,
  },
  updatedAt: {
    text: 'Atualização',
    transform: (value) => moment(value).format('DD/MM/YYYY'),
    invisible: true,
  },
};
