import moment from 'moment';
import status from '@/utils/status'

export default ({
  "id": {
    invisible: true
  },
  "name": {
    text: "Nome"
  },
  "email": {
    text: "E-mail"
  },
  "cpf": {
    text: "CPF"
  },
  "phone": {
    text: "Telefone"
  },
  "createdAt": {
    text: "Data de criação",
    transform: (value) => moment(value).format('DD/MM/YYYY')
  },
  "updatedAt": {
    text: "Atualização",
    transform: (value) => moment(value).format('DD/MM/YYYY')
  },
})