import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  postAnaliseRequest: ['values'],
  postAnaliseSuccess: ['data'],
  postAnaliseFailure: [],
});

export const AnaliseTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  loading: false,
  data: [],
  error: false,
  success: false
});

/* Reducers */

const postAnaliseRequest = (state) => state.merge({
  ...state,
  loading: true,
  success: false,
  error: false
});

const postAnaliseSuccess = (state, { data }) => state.merge({
  data,
  loading: false,
  success: true,
  error: false,
});

const postAnaliseFailure = (state) => state.merge({
  ...state,
  error: true,
  loading: false,
  success: false
});

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.POST_ANALISE_REQUEST]: postAnaliseRequest,
  [Types.POST_ANALISE_SUCCESS]: postAnaliseSuccess,
  [Types.POST_ANALISE_FAILURE]: postAnaliseFailure,
});
