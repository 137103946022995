import { call, put } from 'redux-saga/effects';
import api from '@/services/api';

import CreddefenseActions from '@/store/ducks/creddefense';
import toast from '@/utils/toast';
import cogoToast from 'cogo-toast';

function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
  
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)
  
    return new Blob([ia], { type: mimeString })
  }

export function* getCreddefenseRequest( {values} ) {
  try {
    const endpoint = "/clients/creddefense"
    const token = values.token

    const { data } = yield call(api.post, endpoint, values, {
      params: {
        token
      }
    })

    const endpointSelfie = `/proposals/selfie/${values.proposalId}`;

    const selfie = DataURIToBlob(values.photo)
    let bdyForm = new FormData;
    bdyForm.append("files.selfie", selfie, `selfie_${values.proposalId}.jpeg`);
    bdyForm.append("data", JSON.stringify({}));

    const {selfieData} = api.post(endpointSelfie, bdyForm)
    
    yield put(CreddefenseActions.getCreddefenseSuccess(data));

  } catch (error) {
    yield put(CreddefenseActions.getCreddefenseFailure());
  }
}
 

export function* getCreddefenseStatusRequest({ values, token }) {
  try {
    const endpoint = '/clients/creddefense/status';
    const { data } = yield call(api.post, endpoint, values, {
      params: {
        token
      }
    });

    // console.log(data);

    if (data.error) {
      data.message.map(item => cogoToast.error(item.state));
      return yield put(CreddefenseActions.getCreddefenseStatusFailure());
    }

    yield put(CreddefenseActions.getCreddefenseStatusSuccess(data));
  } catch (error) {
    if (error && error.length) {
      error.map(item => toast.error(item.state));
    }
    yield put(CreddefenseActions.getCreddefenseStatusFailure());
  }
}
