import { call, put } from 'redux-saga/effects';
import api from '@/services/api';
import cogoToast from 'cogo-toast';

import ClientsActions from '@/store/ducks/clients';
import toast from '@/utils/toast';

export function* getClientsRequest(values) {
  try {
    const endpoint = '/clients'; 
    const { data } = yield call(api.get, endpoint, {
      params: values.filters
    });
    yield put(ClientsActions.getClientsSuccess(data));
  } catch (error) {
    yield put(ClientsActions.getClientsFailure());
  }
}


export function* getClientsByIdRequest({ id }) {
  try {
    const endpoint = `/clients/${id}`;
    const { data } = yield call(api.get, endpoint);
    yield put(ClientsActions.getClientsByIdSuccess(data));
  } catch (error) {
    yield put(ClientsActions.getClientsByIdFailure());
  }
}


export function* postClientsRequest(values) {
  var bodyFormData = new FormData();
  values.values.files.identity.forEach(element => {bodyFormData.append("files.identity", element)});
  values.values.files.proof_of_income.forEach(element => {bodyFormData.append("files.proof_of_income", element)});
  /*bodyFormData.append("files.identity", values.values.files.identity);
  bodyFormData.append("files.proof_of_income", values.values.files.proof_of_income);*/
  let data = values.values;
  delete data.files;
  bodyFormData.append('data', JSON.stringify(data));

  try {
    const endpoint = '/clients';
    const { data } = yield call(api.post, endpoint, bodyFormData);
    cogoToast.success('Cliente cadastrado com sucesso');
    yield put(ClientsActions.postClientsSuccess(data));
  } catch (error) {
    yield put(ClientsActions.postClientsFailure());
  }
}


export function* postValidateClientRequest({ values, token }) {
  try {
    const endpoint = `/clients/verification`;
    const { data } = yield call(api.post, endpoint, values, {
      params: {
        token
      }
    });

    toast.success('Dados autenticados');

    yield put(ClientsActions.postValidateClientSuccess(data));
  } catch (error) {
    // console.log(error);
    //toast.error('O CPF não corresponde ao cliente')
    //toast.error(error)
    yield put(ClientsActions.postValidateClientFailure());
  }
}