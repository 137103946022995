import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Formik, Form, Field } from 'formik'
import Box from '@/components/Box'
import FormGroup from '@/components/FormGroup'
import Button from '@/components/Button'
import currency from '@/utils/currency';
import { URL_API } from '@/services/api';
import Axios from 'axios';


function trataObs(obs){
  obs = obs.replaceAll("<font color=blue>", "");
  obs = obs.replaceAll("</font>", "");
  obs = obs.replaceAll("<BR>", "\n");

  return obs
}

function filterPlan(p){
  if(p == "828" || p == "827" || p == "824") return "Boleto";
  return "Cheque";
}

  export default ({
    id,
  }) => {

  const [letra, setLetra] = useState("Enviar para Análise");
  const [bActive, setbActive] = useState(false);
  const [bDisabled, setbDisabled] = useState(false);
  

  const sendDocs = async (proposal, doc, obs) => {
    setbActive(true);
    const endpoint = `/proposals/docreupload/${proposal}`;
    var bdyForm = new FormData;
    bdyForm.append("files.docreupload", doc[0], doc[0].name);
    bdyForm.append("data", JSON.stringify(obs));
    const headers = {
      'Content-Type': 'multipart/form-data'
    }
    const {data} = Axios({
      method: 'POST',
      url: `${URL_API}${endpoint}`,
      data: bdyForm,
      headers: headers
    })
    if(data){
      setTimeout(()=>{
        setbActive(false);
        setbDisabled(true);
        setLetra("Enviado");
      }, 2300)
    }
    
  }

  return (
    <>
      <Container fluid className="animated fadeIn">
        <Box>
          <div className="fieldset">
            <h4>Detalhes</h4>
            <Row className="full">
              <Col md={5} sm={6} xs={12}>
                <FormGroup
                  value={id.client}
                  disabled={true}
                  label="Nome"
                />
              </Col>
              <Col md={3} sm={6} xs={12}>
                <FormGroup
                  value={id.cpf}
                  disabled={true}
                  label="CPF"
                />
              </Col>
              <Col md={4} sm={6} xs={12}>
                <FormGroup
                  value={id.status}
                  disabled={true}
                  label="Status"
                />
              </Col>
              <Col md={2} sm={6} xs={12}>
                <FormGroup
                  value={currency(id.value)}
                  disabled={true}
                  label="Valor"
                />
              </Col>
              <Col md={2} sm={6} xs={12}>
                <FormGroup
                  value={`${id.padraoPrazo}x`}
                  disabled={true}
                  label="Prazo"
                />
              </Col>
              <Col md={2} sm={6} xs={12}>
                <FormGroup
                  value={currency(id.padraoParcela)}
                  disabled={true}
                  label="Parcela"
                />
              </Col>
              <Col md={3} sm={6} xs={12}>
                <FormGroup
                  value={filterPlan(id.tipoP)}
                  disabled={true}
                  label="Tipo de pagamento"
                />
              </Col>
              <Col md={3} sm={6} xs={12}>
                <FormGroup
                  value={id.phone}
                  disabled={true}
                  label="Celular"
                />
              </Col>
              {id.status === "Aguardando Formalização" && 
              <Col md={12} sm={6} xs={12}>
                <FormGroup
                  value={`http://app.souone.com.br/assinatura?token=${id.token}`}
                  disabled={true}
                  label="Link"
                />
              </Col>
              }
              
            </Row>

            <h4>Observações</h4>
            <Row>
              <Col md={12} sm={6} xs={12}>
                <textarea readOnly={true} style={{cursor:"default",width: "100%", height:"160px", borderColor:"#c4c4c4", padding:"8px"}} value={trataObs(id.padraoObs)}></textarea>
              </Col>
            </Row>

            {(/*id.status == "Em Avaliação" ||*/ id.padraoStatus == "Pendente com Restrição" || id.padraoStatus == "Pendente sem Restrição") && <>
              <h4>Enviar Observação </h4>
              <Formik 
                onSubmit={(values) => sendDocs(id.id, values.reupload_files, values.obs)}                  
                initialValues={{
                  reupload_files: null,
                  obs: ""}}>
                {({
                  values,
                  errors,
                  handleSubmit,
                  setFieldValue,
                  handleChange,
                  setFieldError,
                  isValid,
                  ...propsForm
                }) => {

                return (<>
                  <Form className="form form-proposal" onSubmit={handleSubmit}>
                    <textarea 
                    style={{cursor:"auto",width: "100%", height:"160px", borderColor:"#c4c4c4", padding:"8px"}} 
                    onChange={(e)=>handleChange(e)}
                    id="obs"
                    name="obs"
                    ></textarea>

                    <Field
                      multiple
                      component={FormGroup}
                      label={'Documentos'}
                      name="reupload_files"
                      type="file"
                      id="reupload_files"
                      onChange={(e) => handleChange(e)}
                    />
                  <div style={{width:"100%", marginTop:"24px", display:"flex", justifyContent:"center"}}><Button loading={bActive} disabled={bDisabled} type="submit">{letra}</Button></div>
                  </Form>
                  </>
                )}}
              </Formik>
            </>}
          </div>
        </Box>
      </Container>
    </>
  )
}
