import { call, put } from 'redux-saga/effects';
import api from '@/services/api';

import CadastroActions from '@/store/ducks/cadastro';

export function* postVerifyRequest(values) {// verifica o user da padrao
    try {
      const endpoint = '/users/verify';
      const { data } = yield call(api.post, endpoint, values);
      yield put(CadastroActions.postVerifySuccess(data));
    } catch (error) {
      yield put(CadastroActions.postVerifyFailure());
    }
}

export function* postCreateRequest(values) {// cria um novo usuario 
    try {
      const endpoint = '/users';
      const { data } = yield call(api.post, endpoint, values.values);

      yield put(CadastroActions.postCreateSuccess(data));
    } catch (error) {
      yield put(CadastroActions.postCreateFailure());
    }
}
