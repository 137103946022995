import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getProfessionsRequest: [],
  getProfessionsSuccess: ['data'],
  getProfessionsFailure: [],
});

export const ProfessionsTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  loading: false,
  data: [],
  error: false,
});

/* Reducers */

const getProfessionsRequest = (state) => state.merge({
  ...state,
  loading: true,
});

const getProfessionsSuccess = (state, { data }) => state.merge({
  data,
  loading: false,
  error: false,
});

const getProfessionsFailure = (state) => state.merge({
  ...state,
  error: true,
  loading: false,
});

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PROFESSIONS_REQUEST]: getProfessionsRequest,
  [Types.GET_PROFESSIONS_SUCCESS]: getProfessionsSuccess,
  [Types.GET_PROFESSIONS_FAILURE]: getProfessionsFailure,
});
