import { call, put } from 'redux-saga/effects';
import api from '@/services/api';

import StatesActions from '@/store/ducks/states';

export function* getStatesRequest() {
  try {
    const endpoint = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados';
    const states = yield fetch(endpoint);
    const data = yield states.json();

    yield put(StatesActions.getStatesSuccess(data));
  } catch (error) {

    yield put(StatesActions.getStatesFailure());
  }
}
