import { call, put } from 'redux-saga/effects';
import api from '@/services/api';

import UploadActions from '@/store/ducks/upload';

export function* postUploadRequest() {
  try {
    const endpoint = '/upload';
    const { data } = yield call(api.post, endpoint);
    yield put(UploadActions.postUploadSuccess(data));
  } catch (error) {
    // console.log(error);
    yield put(UploadActions.postUploadFailure());
  }
}
