import React, { useEffect, useState } from 'react';
import storage from './utils/storage';
//import OneSignal, { useOneSignalSetup } from 'react-onesignal';
import Header from './components/Header';


import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UsersActions from '@/store/ducks/users';

function isPublicRoute() {
  const publicRoutes = ['/recover', '/login', '/logout', '/assinatura', '/esqueci-minha-senha', "/cadastro"];
  return publicRoutes.indexOf(window.location.pathname) > -1;
}

function guardian(getMeRequest) {
  const token = storage.getToken()
  if (!isPublicRoute() && !storage.getToken()) {
    window.location.href = '/login';
    storage.removeToken();
    return null;
  }

  if(token) {
    getMeRequest();
  }
}

const Root = ({ children, getMeRequest, user, history, putMeRequest }) => {

 // const [notification, setNotification] = useState(false);

  useEffect(() => {
    guardian(getMeRequest);
  }, []);


  /*useEffect(() => {
    if (!notification && user) {
      OneSignal.initialize(process.env.REACT_APP_ONE_SIGNAL_ID);
      setNotification(true);
    }
  }, [user])

  useOneSignalSetup(() => {
    if (!user?.one_signal_id && notification) {
      updateOneSignalId();
    }
  });*/



  /*async function updateOneSignalId() {
    const playerID = await OneSignal.getPlayerId();
    putMeRequest({
      one_signal_id: playerID
    });
  }*/

  if (isPublicRoute()) {
    return <>{children}</>;
  }

  return (
    <>
      <div id="body-main">
        <Header history={history} />
        {children}
      </div>
    </>
  );
};


const mapStateToProps = ({ users }) => ({
  loading: users.loading,
  user: users.data.user
})

const mapDispatchToProps = (dispatch) => bindActionCreators(UsersActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Root);

