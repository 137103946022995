import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUsers, faFile, faUserCircle, faBell, faWallet, faPoll, faSignOutAlt, faSearch} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import IosClose from 'react-ionicons/lib/IosClose'

import Logo from '@/assets/images/logo.svg';


import {
  HeaderContainer,
  Image,
  LogoContainer,
  Menu,
  Item,
  GroupInfo,
  Bold,
  NotificationBar,
  Alert,
  ItemNotification
} from './styles';


import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import UsersActions from '@/store/ducks/users'
import NotificationsActions from '@/store/ducks/notifications'

import colors from '@/styles/colors';
import storage from '@/utils/storage';
import moment from 'moment';


const ItemNotfy = ({
  item: {
    title,
    message,
    createdAt
  }
}) => {
  return (
    <ItemNotification>
      <div className="diviser">
        <h5>{title}</h5>
        <p>{message}</p>
      </div>
      <div className="timer">{moment(createdAt).format('DD/MM/YYYY - hh:mm')}</div>
    </ItemNotification>
  )
}


const Header = ({
  history,
  user,
  notifications,
  getNotificationsRequest
}) => {
  const [isShowNotification, setIsShowNotification] = useState(false);
  const [isActive, setIsActive] = useState(history.location.pathname);

  useEffect(() => {
    getNotificationsRequest();
    history.listen(({ pathname }) => {
      setIsActive(pathname);
      getNotificationsRequest();
    })
  }, []);


  const items = [
    /*{
      text: 'Dashboard',
      href: '/',
      icon: faHome,
    },*/
    {
      text: 'Clientes',
      href: '/clientes',
      icon: faUsers,
    },
    {
      text: 'Propostas',
      href: '/',
      icon: faFile,
    },
     {
       text: 'Analise rapida',
      href: '/analise',
       icon: faSearch,
     },
  ];

  function logout() {
    storage.removeToken();
    window.location.reload();
  }

  return (
    <>
      {(user && !user.cpf) && <Alert><a href="/">Completar meu cadastro agora</a></Alert>}
      <HeaderContainer>
        <LogoContainer href="/">
          <Image src={Logo} style={{width:"250px", height:"250px"}}/>
        </LogoContainer>
        <Menu id="menu-header">
          {items.map((item, index) => (
            <Link to={item.href}>
              <Item key={index} className={isActive === item.href ? 'active' : ''}>
                <FontAwesomeIcon icon={item.icon} />
                {item.text}
              </Item>
            </Link>
          ))}
        </Menu>

        <Menu style={{
          flex: 1,
          justifyContent: 'flex-end'
        }}>
          {/* <GroupInfo>
          <div className="text-right">
            <Bold>R$ 3.000,00</Bold> <Bold style={{ color: colors.grayBold, fontSize: 12 }}>TLT</Bold>
          </div>
          <div className="text-right">
            <Bold>30</Bold> <Bold style={{ color: colors.grayBold, fontSize: 12 }}>PRTS</Bold>
          </div>
        </GroupInfo> */}
          <Item href="javascript:void(0)" onClick={() => setIsShowNotification(true)}>
            <FontAwesomeIcon icon={faBell} />
          </Item>
          <Item href="javascript:void(0)" onClick={() => logout()}>
            <FontAwesomeIcon icon={faSignOutAlt} />
          </Item>
        </Menu>
        <NotificationBar id="notification-bar" className={!isShowNotification ? 'hidden' : ''}>
          <header>
            <h2 className="title">Notificações</h2>
            <button type="button" className="button close" onClick={() => setIsShowNotification(false)}><IosClose fontSize={38} color={colors.dark} /></button>
          </header>

          <div className={`body ${notifications.length === 0 ? 'middle' : 'active'}`}>
            {notifications?.length === 0 && <h3 className="notfound">Nenhuma notificação encontrada</h3>}
            {notifications?.length > 0 && notifications.map(item => (<ItemNotfy item={item} />))}
          </div>
        </NotificationBar>
      </HeaderContainer>
    </>
  );
};

const mapStateToProps = ({ users, notifications }) => ({
  user: users.data.user,
  notifications: notifications.data
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  ...UsersActions,
  ...NotificationsActions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
