import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';


import Login from '@/pages/Login';
//import Dashboard from '@/pages/Dashboard';
import Proposal from '@/pages/Proposal';
import ProposalSingle from '@/pages/Proposal/Single';
import Clients from '@/pages/Clients';
import ClientSingle from '@/pages/Clients/Single';
//import Marketing from '@/pages/Marketing';
import Signature from '@/pages/Signature';
import AnaliseRapida from "@/pages/AnaliseRapida";
import ForgotPassword from '@/pages/ForgotPassword';
import Profile from '@/pages/Profile';
import Cadastro from '@/pages/Cadastro';

import Root from '@/Root';
import history from './history';



const Routes = () => (
  <ConnectedRouter history={history}>
    <Root history={history}>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/" component={Proposal} />    
        <Route exact path="/propostas" component={Proposal} />
        <Route exact path="/propostas/:id" component={ProposalSingle} />
        <Route exact path="/clientes" component={Clients} />
        <Route exact path="/clientes/:id" component={ClientSingle} />
        <Route exact path="/assinatura" component={Signature} />
        <Route exact path="/minha-conta" component={Profile} />
        <Route exact path="/esqueci-minha-senha" component={ForgotPassword} />
        <Route exact path="/analise" component={AnaliseRapida}/>
        <Route exact path="/cadastro" component={Cadastro}/>

      </Switch>
    </Root>
  </ConnectedRouter>
);

export default Routes;


//<Route exact path="/" component={Dashboard} /><Route exact path="/marketing" component={Marketing} />