import React from 'react';

export function StatusColor(status) {
  switch (status) {
    case "Negada": return 'danger';//pos avaliarProposta ou analise manual
    case "fraude_creddefense": return 'danger';

    case "Em Avaliação": return 'warning';//pos completarDados
    case "Pendente com Restrição": return 'pending';//pos avaliarProposta pendente com restricao
    case "Pendente sem Restrição": return 'pending';//pos avaliarProposta
    case "Aguardando Processamento": return 'success';//aguardando o envio dos cheques
    case "Aguardando Pagamento": return 'success';//depois de enviar os cheques, esperando a tesouraria pagar
    case "Aguardando Confirmar Valor": return 'warning';//pos analise manual que mudou valores
    case "Pendente com Restrição": return 'pending'; // Pendente com Restrição

    case "Proposta Paga": return 'success';//tudo finalizado
    case "Aguardando Formalização": return 'success';//pos analise manual ok
    case "Aguardando Assinatura": return 'success';//pos formalizarProposta

    case "recusado": return "danger";//analise rapida recusada
    case "aprovado": return "success";//analise rapida aprovada

  }
}


export function Translator(status) {
  switch (status) {
    case "Negada": return 'Recusado';//pos avaliarProposta ou analise manual
    case "fraude_creddefense": return 'Recusado';

    case "Em Avaliação": return 'Em análise';//pos completarDados
    case "Pendente com Restrição": return 'Pendente';//pos avaliarProposta pendente com restricao
    case "Pendente sem Restrição": return 'Pendente';//pos avaliarProposta
    case "Aguardando Processamento": return 'Assinado';//aguardando o envio dos cheques
    case "Aguardando Confirmar Valor": return 'Em análise';//pos analise manual que mudou valores
    case "Pendente com Restrição": return 'Pendente'; // Pendente com Restrição

    case "Aguardando Pagamento": return 'Assinado';//depois de enviar os cheques, esperando a tesouraria pagar

    case "Proposta Paga": return 'Pago';//tudo finalizado
    case "Aguardando Formalização": return 'Aprovada';//pos analise manual ok
    case "Aguardando Assinatura": return 'Aprovada';//pos formalizarProposta

    case "recusado": return "Recusada";//analise rapida recusada
    case "aprovado": return "Aprovada";//analise rapida aprovada
  }
}


export function QueryStringStatus(status) {

  switch (status) {
    case 'negado':
      return (['Negada', 'fraude_creddefense', 'recusado'])
    case 'analise':
      return (['Em Avaliação', 'Pendente com Restrição', 'Pendente sem Restrição', 'Aguardando Processamento', 'Aguardando Confirmar Valor', 'Pendente com Restrição'])
    case 'aprovado':
      return (['Aguardando Formalização', 'Aguardando Assinatura', 'aprovado'])
    case 'pago':
      return (['Proposta Paga'])
    default: return null
  }
}

export default (status) => <div className={`badge badge-${StatusColor(status)}`}>{Translator(status)}</div>
