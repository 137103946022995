import React, { useState, useRef, useCallback, useEffect } from 'react';
import Lottie from 'react-lottie'
import animations from '@/assets/animations'
import 'react-html5-camera-photo/build/css/index.css';

export default ({
  handleCreddefense,
  success
}) => {
  const [token, setToken] = useState('');

  useEffect(() => {
    if (!success) {
      handleCreddefense();
    }
  }, [success]);

  return (
    <div className="text-center">
      <Lottie
        width={200}
        options={{
          loop: true,
          autoplay: true,
          animationData: animations.loading
        }}
      />
      <h4>Estamos verificando seus dados, por favor aguarde!</h4>
    </div>
  )
}