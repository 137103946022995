import React, { useState, useRef, useCallback, useEffect } from 'react';
import check_img_form from '@/assets/images/check_img_form.png';
import { isBoleto } from '@/utils/validation';

import {
  Button
} from '@material-ui/core';

import {
  DivBlockText,
  ImgBox,
  Letra,
  Letra2,
  ContentFormInside
} from '../styles';




export default ({
  handleSubmit,
  proposal
}) => {
  const [mensagem, setMensagem] = useState('');

  useEffect(() => {

    if(!isBoleto(proposal.padrao.Plano)) return setMensagem(<span>A liberação do pagamento será realizada mediante a entrega dos <strong>cheques</strong>.</span>);
    
    /*if (proposal.type_payment == "cheque") {
      return setMensagem(<span>A liberação do pagamento será realizada mediante a entrega dos <strong>cheques</strong>.</span>);
    }*/

    setMensagem(<span>O pagamento das parcelas do seu empréstimo será feito por <strong>boleto bancário</strong>.</span>);
  }, [proposal]);


  return (
    <ContentFormInside>
      <ImgBox><img style={{ maxHeight: "300px", maxWidth: "300px" }} src={check_img_form}></img></ImgBox>
      <DivBlockText>
        <Letra>Lembre-se</Letra>
        <Letra2>{mensagem}</Letra2>
      </DivBlockText>
      <Button fullWidth={false} size="large" variant="contained" disabled={false} color="primary" onClick={handleSubmit}>
        Continuar
                </Button>
    </ContentFormInside>
  )
}