import React, { useState} from 'react';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import cogoToast from 'cogo-toast';
//import Axios from 'axios';
//import { URL_API } from '@/services/api';
import { Button } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import { ContentVideo } from '../styles';
import Loading_svg from '@/assets/images/loading.svg';
//import toast from '@/utils/toast';

export default ({
  cam,
  proposal,
  token,
  getCreddefenseRequest,
  loading_creddefense
}) => {

  const [imageAvatar, setImageAvatar] = useState(null);

  return (
    (
      <ContentVideo>
        {imageAvatar && <img style={{ width: '100%',borderRadius:"20px", marginBottom: 36 }} src={imageAvatar} />}
        {!imageAvatar && (
          <Camera
            imageType="jpg"
            isDisplayStartCameraError={false}
            onTakePhoto={(dataUri, event) => {
              if (!cam) {
                return cogoToast.error('Seu dispositivo de câmera está desabilitado');
              }
              return setImageAvatar(dataUri);
            }}
          />
        )}

        {imageAvatar &&
        <>
        <Button 
          className="continuar-photo"
          fullWidth={true}
          disabled={loading_creddefense}
          onClick={() => getCreddefenseRequest({ photo: imageAvatar, proposal: proposal.proposal_external, proposalId: proposal.id, token: token} ) }
        >
        {!loading_creddefense && "Enviar"}
        {loading_creddefense && <ReactSVG className="loading" src={Loading_svg} />}       
        </Button>

        <Button 
          className="reset-photo"
          fullWidth="true"
          disabled={loading_creddefense}
          onClick={() => setImageAvatar(null)}
        >
        Tirar Novamente</Button>
        
        </>} 

      </ContentVideo>
    )
  )
}