import { all, takeLatest } from 'redux-saga/effects';

import { AuthenticationTypes } from '../ducks/authentication';
import {
  getAuthenticationRequest,
  postForgotPasswordRequest,
  postResetPasswordRequest
} from './authentication';


import { UsersTypes } from '../ducks/users';
import {
  getUsersRequest,
  getMeRequest,
  putMeRequest
} from './users';

import { CepTypes } from '../ducks/cep';
import { getCepRequest } from './cep';

import { BankTypes } from '../ducks/bank';
import { getBankRequest } from './bank';

import { StatesTypes } from '../ducks/states';
import { getStatesRequest } from './states';

import { CitiesTypes } from '../ducks/cities';
import { getCitiesRequest } from './cities';

import { ClientsTypes } from '../ducks/clients';
import { getClientsRequest, postClientsRequest, getClientsByIdRequest, postValidateClientRequest } from './clients';

import { ProposalTypes } from '../ducks/proposal';
import {
  getProposalRequest,
  postProposalRequest,
  getProposalByIdRequest,
  postGenerateSignatureRequest,
  postVerifyCodeRequest,
  putAuditTrailRequest
} from './proposal';

import { ProfessionsTypes } from '../ducks/professions';
import { getProfessionsRequest } from './professions';

import { CreddefenseTypes } from '../ducks/creddefense';
import { getCreddefenseRequest, getCreddefenseStatusRequest } from './creddefense';

import { FactorsTypes } from '../ducks/factors';
import { getFactorsRequest } from './factors';

import { SignatureTypes } from '../ducks/signature';
import { getSignatureRequest, formalizeSignatureRequest, generatePdfRequest } from './signature';

import { UploadTypes } from '../ducks/upload';
import { postUploadRequest } from './upload';

import { NotificationsTypes } from '../ducks/notifications';
import { getNotificationsRequest } from './notifications';

import { AnaliseTypes } from '../ducks/analise';
import { postAnaliseRequest } from './analise';

import { CadastroTypes } from '../ducks/cadastro';
import { postVerifyRequest, postCreateRequest } from './cadastro';

export default function* rootSaga() {
  yield all([
    takeLatest(
      AuthenticationTypes.GET_AUTHENTICATION_REQUEST,
      getAuthenticationRequest
    ),
    takeLatest(
      AuthenticationTypes.POST_FORGOT_PASSWORD_REQUEST,
      postForgotPasswordRequest
    ),
    takeLatest(
      AuthenticationTypes.POST_RESET_PASSWORD_REQUEST,
      postResetPasswordRequest
    ),
    takeLatest(
      UsersTypes.GET_USERS_REQUEST,
      getUsersRequest
    ),
    takeLatest(
      UsersTypes.GET_ME_REQUEST,
      getMeRequest
    ),
    takeLatest(
      UsersTypes.PUT_ME_REQUEST,
      putMeRequest
    ),
    takeLatest(
      CepTypes.GET_CEP_REQUEST,
      getCepRequest
    ),
    takeLatest(
      BankTypes.GET_BANK_REQUEST,
      getBankRequest
    ),
    takeLatest(
      StatesTypes.GET_STATES_REQUEST,
      getStatesRequest
    ),
    takeLatest(
      CitiesTypes.GET_CITIES_REQUEST,
      getCitiesRequest
    ),
    takeLatest(
      ClientsTypes.GET_CLIENTS_REQUEST,
      getClientsRequest
    ),
    takeLatest(
      ClientsTypes.GET_CLIENTS_BY_ID_REQUEST,
      getClientsByIdRequest
    ),
    takeLatest(
      ClientsTypes.POST_VALIDATE_CLIENT_REQUEST,
      postValidateClientRequest
    ),
    takeLatest(
      ClientsTypes.POST_CLIENTS_REQUEST,
      postClientsRequest
    ),
    takeLatest(
      ProposalTypes.POST_PROPOSAL_REQUEST,
      postProposalRequest
    ),
    takeLatest(
      ProposalTypes.GET_PROPOSAL_REQUEST,
      getProposalRequest
    ),
    takeLatest(
      ProposalTypes.GET_PROPOSAL_BY_ID_REQUEST,
      getProposalByIdRequest
    ),
    takeLatest(
      ProposalTypes.POST_GENERATE_SIGNATURE_REQUEST,
      postGenerateSignatureRequest
    ),
    takeLatest(
      ProposalTypes.POST_VERIFY_CODE_REQUEST,
      postVerifyCodeRequest
    ),

    takeLatest(
      ProposalTypes.PUT_AUDIT_TRAIL_REQUEST,
      putAuditTrailRequest
    ),

    takeLatest(
      SignatureTypes.GENERATE_PDF_REQUEST,
      generatePdfRequest
    ),

    takeLatest(
      ProfessionsTypes.GET_PROFESSIONS_REQUEST,
      getProfessionsRequest
    ),
    takeLatest(
      FactorsTypes.GET_FACTORS_REQUEST,
      getFactorsRequest
    ),
    takeLatest(
      SignatureTypes.GET_SIGNATURE_REQUEST,
      getSignatureRequest
    ),

    takeLatest(
      SignatureTypes.FORMALIZE_SIGNATURE_REQUEST,
      formalizeSignatureRequest
    ),

    takeLatest(
      UploadTypes.POST_UPLOAD_REQUEST,
      postUploadRequest
    ),

    takeLatest(
      CreddefenseTypes.GET_CREDDEFENSE_REQUEST,
      getCreddefenseRequest
    ),

    takeLatest(
      CreddefenseTypes.GET_CREDDEFENSE_STATUS_REQUEST,
      getCreddefenseStatusRequest
    ),

    takeLatest(
      NotificationsTypes.GET_NOTIFICATIONS_REQUEST,
      getNotificationsRequest
    ),
    takeLatest(
      AnaliseTypes.POST_ANALISE_REQUEST,
      postAnaliseRequest
    ),
    takeLatest(
      CadastroTypes.POST_VERIFY_REQUEST,
      postVerifyRequest
    ),
    takeLatest(
      CadastroTypes.POST_CREATE_REQUEST,
      postCreateRequest
    )
  ]);
}
