import React, { useEffect, useState } from 'react'
import currency from '@/utils/currency';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';

import { Formik, Form, Field } from 'formik'
import { Col, Row } from 'react-bootstrap'
import Slider from '@material-ui/core/Slider';

import FormGroup from '@/components/FormGroup'
import Button from '@/components/Button'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FactorsActions from '@/store/ducks/factors'
// import Table from '@/components/Table';
import * as Yup from 'yup';
import cogoToast from 'cogo-toast';

 /*function useSearchDebounce(delay = 500) {
  const [search, setSearch] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);

  useEffect(() => {
    const delayFn = setTimeout(() => setSearch(searchQuery), delay);
    return () => clearTimeout(delayFn);
  }, [searchQuery, delay]);

  return [search, setSearchQuery];
}*/


const validationSchema = Yup.object().shape({
  client: Yup.string()
    .required('Campo obrigatório'),
  value: Yup.string()
    .required('Campo obrigatório'),
  term: Yup.string()
    .required('Campo obrigatório'),
  first_maturity: Yup.string()
    .nullable()
    .required('Campo obrigatório'),
  type_payment: Yup.string()
    .required('Campo obrigatório'),
  origem: Yup.string()
    .required('Campo obrigatório'),
  goal: Yup.string()
    .required('Campo obrigatório')
});

const CreatedProposal = ({
  closeModal,
  loading,
  success,
  searchClient,
  loadingClient,
  clientSuccess,
  clients,
  postProposalRequest,
  getFactorsRequest,
  user,
  loading_factors,
  handleShowClient,
  factors 
}) => {
  const [disabled, setDisabled] = useState(false);
  const [text, setText] = useState(false);
  const [ValueSlider, setValueSlider] = useState(500);
  const [parcel, setParcel] = useState(3);
  const [parcela, setParcela] = useState(500);
  //const [searchText, setSearchText] = useSearchDebounce();
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    if (success) {
      closeModal();
    }
  }, [success])

  useEffect(() => {
    if (factors.length) setParcela(currency(String((factors[24 - parcel].ValorParcela))));
  }, [factors])

  useEffect(() => {
    getFactorsRequest({
      first_maturity: moment().add('15', 'days').format('YYYYMMDD'),
      value: 500,
      type: 'cheque'
    })
  }, [])

  useEffect(()=>{
    searchClient(searchText)
  }, [searchText])

  
  function handleGetFactors(values, setFieldError) {
    if (!values.value) {
      return setFieldError('value', 'Campo obrigatório');
    }

    return getFactorsRequest(values);
  }


  const handleNext = (setFieldValue, factors) => {
    if (parcel === 18) {
      return cogoToast.error('Esse é o limite máximo de parcelas')
    }

    const count = parcel + 1;

    setFieldValue('term', count);
    if (factors.length) setParcela(currency(String((factors[24 - count].ValorParcela))));
    setParcel(count);
  }


  const handlePrev = (setFieldValue, factors) => {
    if (parcel === 3) {
      return cogoToast.error('Esse é o limite mínimo de parcelas')
    }

    const count = parcel - 1;

    setFieldValue('term', count);
    if (factors.length) setParcela(currency(String((factors[24 - count].ValorParcela))));
    setParcel(count);
  }

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        onSubmit={(values) => postProposalRequest(values)}
        initialValues={{
          client: '',
          value: 500,
          term: '3',
          first_maturity: moment(new Date(), 'DD.MM.YYYY').add('15', 'days').toDate(),
          goal: '',
          type_payment: 'cheque',
          proof_of_residence: null,
          origem: "leads"
        }}
      >
        {({
          values,
          errors,
          handleSubmit,
          setFieldValue,
          handleChange,
          setFieldError,
          isValid,
          ...propsForm
        }) => {

          return (
            <Form className="form form-proposal" onSubmit={handleSubmit}>
              <Row>
                <Col md={6} className="br">
                  <h3>Valor da proposta</h3>
                  <div className="value-input" style={{ fontSize: "24px" }} >
                    <CurrencyFormat disabled className="numero-disabled"
                      thousandSeparator="."
                      prefix={'R$'}
                      thousandSpacing="2s"
                      decimalSeparator=","
                      value={values.value}
                    />
                  </div>
                  <Slider
                    min={500}
                    max={25000}
                    step={100}
                    onChange={(e, newValue) => {
                      setFieldValue('value', newValue || 500)
                      setValueSlider(newValue || 500);
                    }}
                    onChangeCommitted={() => {
                      if (values.first_maturity && values.value && values.type_payment) {
                        handleGetFactors({
                          first_maturity: moment(values.first_maturity).format('YYYYMMDD'),
                          value: values.value,
                          type: values.type_payment
                        });
                      }
                    }}
                  />
                  {errors.value && <p className="error">{errors.value}</p>}

                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "16px", fontSize: "24px" }}>
                    <div><strong>{parcel}x</strong> parcelas de <strong> {loading_factors ? 'calculando...' : parcela}</strong></div>
                    <div style={{ display: "flex", justifyContent: "space-around", width: "20%" }}>
                      <Button theme="clean" action={() => handlePrev(setFieldValue, factors)} type="button"><strong>&#8722;</strong></Button>
                      <Button theme="clean" action={() => handleNext(setFieldValue, factors)} type="button"><strong>&#43;</strong></Button>
                    </div>
                  </div>

                </Col>
                <Col md={6}>
                  <h3>Dados da proposta</h3>
                  <div className="block">
                    <Field
                      type="autocomplete"
                      component={FormGroup}
                      //label={loadingClient ? `Carregando ${loadingClient} ${clientSuccess}` : "Cliente" }
                      label={"Cliente"}
                      placeholder="Digite o nome do cliente ou o CPF"
                      maxOptionsLimit={10}
                      error={errors.client}
                      itemsData={clients}
                      getItemValue={
                        (item) => {
                          return loadingClient ? "Carregando..." : `${item.name} - ${item.cpf}`
                        }
                        
                      }
                      onSelect={(value) => {
                        setText(true);
                        setFieldValue('client', value)
                      }}
                      name="client"              
                      onChange={text => {
                        if (text) {
                          setText(true);
                        } else {
                          setText(false);
                        }
                        setSearchText(text)
                      }}
                    />
                    {!text && <Button className="button-add" type="button" action={() => handleShowClient()}>Adicionar um novo cliente</Button>}
                  </div>
                  <Row>
                    <Col md={6} xs={12}>
                      <Field
                        component={FormGroup}
                        label={'Dia da vencimento'}
                        type="date"
                        name="first_maturity"
                        error={errors.first_maturity}
                        placeholderText="Ex: 01/01/1990"
                        placeholder="Ex: 01/01/2020"
                        showYearDropdown
                        showMonthDropdown
                        selected={values.first_maturity}
                        minDate={moment(new Date(), 'DDDD.MM.YYYY').add('15', 'days').toDate()}
                        maxDate={moment(new Date(), 'DDDD.MM.YYYY').add('45', 'days').toDate()}
                        onChange={(e) => {
                          setFieldValue('first_maturity', e);
                          getFactorsRequest({
                            first_maturity: moment(e).format('YYYYMMDD'),
                            value: values.value,
                            type: values.type_payment
                          })
                        }}
                      />
                    </Col>
                    <Col md={6} xs={12}>
                      <Field
                        component={FormGroup}
                        label={"Tipo do pagamento"}
                        name="type_payment"
                        type="select"
                        error={errors.type_payment}
                        onChange={(event) => {
                          handleChange(event);
                          getFactorsRequest({
                            first_maturity: moment(values.first_maturity).format('YYYYMMDD'),
                            value: values.value,
                            type: event.target.value
                          })
                        }}
                        options={[
                          {
                            label: 'Escolha o tipo do pagamento',
                            value: ''
                          },
                          {
                            label: 'Cheque',
                            value: 'cheque'
                          },
                          {
                            label: 'Boleto',
                            value: 'boleto'
                          }
                        ]}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} xs={12}>
                      <Field
                        component={FormGroup}
                        label={"Objetivo"}
                        name="goal"
                        type="select"
                        options={[
                          { label: 'Escolha um objetivo', value: '' },
                          { label: 'Fazer compras', value: 'Fazer compras' },
                          { label: 'Pagar contas', value: 'Pagar contas' },
                          { label: 'Pagar dívidas', value: 'Pagar dívidas' },
                          { label: 'Despesas médicas', value: 'Despesas médicas' },
                          { label: 'Reforma ou construção', value: 'Reforma ou construção' },
                          { label: 'Consertar meu veículo', value: 'Consertar meu veículo' },
                          { label: 'Ajudar um familiar', value: 'Ajudar um familiar' },
                          { label: 'Outros', value: 'Outros' }
                        ]}
                      />
                    </Col>
                    <Col md={6} xs={12}>
                      <Field
                        component={FormGroup}
                        label={"Origem"}
                        name="origem"
                        type="select"
                        options={[
                          { label: 'Escolha a origem', value: '' },
                          { label: 'Lead', value: 'leads' },
                          { label: 'Manual', value: 'manual' }
                        ]}
                      />
                    </Col>
                  </Row>
                  <hr />

                  <h5>Comprovante de residência</h5>
                  <Field
                    multiple
                    component={FormGroup}
                    label={'Identidade'}
                    name="proof_of_residence"
                    type="file"
                    id="proof_of_residence"
                    onChange={(e) => handleChange(e)}
                  />

                  <div className="text-center footer">
                    <Button loading={loading} theme="clear" disabled={!isValid} type="submit">Enviar proposta</Button>
                  </div>
                </Col>
              </Row>
            </Form>

          )
        }}

      </Formik>
    </>
  )
}


const mapStateToProps = ({ factors }) => ({
  factors: factors.data,
  loading_factors: factors.loading
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    ...FactorsActions
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreatedProposal);
