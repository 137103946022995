import { call, put } from 'redux-saga/effects';
import api from '@/services/api';

import NotificationsActions from '@/store/ducks/notifications';

export function* getNotificationsRequest() {
  try {
    const endpoint = '/notifications';
    const { data } = yield call(api.get, endpoint);
    yield put(NotificationsActions.getNotificationsSuccess(data));
  } catch (error) {
    // console.log(error);
    yield put(NotificationsActions.getNotificationsFailure());
  }
}
