import { call, put } from 'redux-saga/effects';
import api from '@/services/api';

import SignatureActions from '@/store/ducks/signature';
import toast from '@/utils/toast';

import moment from 'moment';

function DataURIToBlob(dataURI) {
  const splitDataURI = dataURI.split(',')
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++)
    ia[i] = byteString.charCodeAt(i)

  return new Blob([ia], { type: mimeString })
}

export function* getSignatureRequest({ values, token }) {
  try {
    const file = DataURIToBlob(values.signature);
    var bodyFormData = new FormData();

    bodyFormData.append("files.signature", file, values.filename);
    bodyFormData.append("data", JSON.stringify({}));

    const endpoint = `/proposals/signature/${values.proposal}`;
    const { data } = yield call(api.post, endpoint, bodyFormData, {
      params: { token }
    });

    yield put(SignatureActions.getSignatureSuccess(data));
  } catch (error) {
    // console.log(error);
    yield put(SignatureActions.getSignatureFailure());
  }
}


export function* formalizeSignatureRequest({ values, token }) {
  try {
    const endpoint = `/proposals/formalize`;
    const { data } = yield call(api.post, endpoint, values, {
      params: { token }
    });

    yield put(SignatureActions.formalizeSignatureSuccess(data));
  } catch (error) {
    // console.log(error);
    yield put(SignatureActions.formalizeSignatureFailure());
  }
}

export function* generatePdfRequest({ values, token }) {
  try {
    const endpoint = `/proposals/audit`;
    const { data } = yield call(api.post, endpoint, values, {
      params: { token }
    });

    yield put(SignatureActions.generatePdfSuccess(data));
  } catch (error) {
    // console.log(error);
    yield put(SignatureActions.generatePdfFailure());
  }
}
