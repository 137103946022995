import axios from 'axios';
import storage from '@/utils/storage';
import cogoToast from 'cogo-toast';

export const URL_API = process.env.REACT_APP_API_URL;

function translateMessage(id) {
  switch (id) {
    case 'Auth.form.error.invalid':
      return cogoToast.error('usuário inválido');
    case 'Auth.form.error.blocked':
      return cogoToast.error('este usuário foi bloqueado, entre em contato com o suporte');
  }
}

const api = axios.create({
  baseURL: URL_API,
});

api.interceptors.request.use((config) => {
  const token = storage.getToken();

  const headers = { ...config.headers };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return { ...config, headers };
});

api.interceptors.response.use(
  async response => {
    if (response.data.error) {
      cogoToast.error(response.data.message);
      throw (response.data.message);
    }
    return response
  },
  async ({ message, response: { status, data, ...response } }) => {
    switch (status) {
      case 404:
        return cogoToast.error('Não encontramos a rota de conexão');
      case 500:
        return cogoToast.error('Estamos com problema para se conectar, tente mais tarde');
      case 502:
        return cogoToast.error(message);
      case 400:
        try {
          return translateMessage(data.message[0].messages[0].id);
        } catch (err) {
          return cogoToast.error('Algo deu errado, tente mais tarde');
        }
      default:
        return cogoToast.error('Algo deu errado, tente mais tarde');
    }
  }
)

export default api;
