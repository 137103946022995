
import React, { useEffect } from 'react';
import Lottie from 'react-lottie';
import animations from '@/assets/animations';
import toast from '@/utils/toast';

import {
    Letra,
    Letra2
} from '../styles';

export default ({
    history,
    generatePdfRequest,
    proposal,
    success,
    token
}) => {

    useEffect(() => {
        generatePdfRequest({ proposal }, token)
    }, [])

    /*useEffect(() => {
        if (success) {
            toast.success('Sua proposta foi assinada e enviada!');
            history.push('login')
        }
    }, [success])*/

    return (
        <div className="text-center">
            <div>
                <Lottie
                    height="250px"
                    options={{
                        loop: false,
                        autoplay: true,
                        animationData: animations.check
                    }}
                />
            </div>
            <div>
                <Letra>Contrato Assinado!</Letra>
                <Letra2>Obrigado por escolher o Crédito One. Assim que seu pagamento for liberado, você receberá um email com o resumo do seu empréstimo.</Letra2>
            </div>
        </div>
    )
}