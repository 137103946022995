import { call, put } from 'redux-saga/effects';
import api from '@/services/api';

import ProfessionsActions from '@/store/ducks/professions';

export function* getProfessionsRequest() {
  try {
    const endpoint = '/professions';
    const { data } = yield call(api.get, endpoint);
    yield put(ProfessionsActions.getProfessionsSuccess(data));
  } catch (error) {
    // console.log(error);
    yield put(ProfessionsActions.getProfessionsFailure());
  }
}
