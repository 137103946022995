import React, { useEffect, useState } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import { currencyMask } from '@/utils/currency';

import CepActions from '@/store/ducks/cep';
import BankActions from '@/store/ducks/bank';
import StatesActions from '@/store/ducks/states';
import CitiesActions from '@/store/ducks/cities';
import ClientsActions from '@/store/ducks/clients';
import ProfessionsActions from '@/store/ducks/professions';
//import AnaliseActions from "@/store/ducks/analise";

import FormGroup from '@/components/FormGroup';
import { Col, Row } from 'react-bootstrap';

import { Form } from './styles';

import Button from '@/components/Button';
import Axios from 'axios';

function onSetCep({ ibge, logradouro, bairro }, form, getCitiesRequest) {
  if (ibge && form) {
    Axios.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${ibge}`,
    ).then(
      ({
        data: {
          microrregiao: { nome, mesorregiao },
        },
      }) => {
        form.setFieldValue('address.address', logradouro, false);
        form.setFieldValue('address.neighborhood', bairro, false);
        form.setFieldValue('address.state', mesorregiao.UF.sigla, false);
        form.setFieldValue('address.city', nome, false);
      },
    );
  }
}

function onSetCepCompany({ ibge, logradouro, bairro }, form, getCitiesRequest) {
  if (ibge && form) {
    Axios.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${ibge}`,
    ).then(
      ({
        data: {
          microrregiao: { nome, mesorregiao },
        },
      }) => {
        form.setFieldValue(
          'professiona_data.address.address',
          logradouro,
          false,
        );
        form.setFieldValue(
          'professiona_data.address.neighborhood',
          bairro,
          false,
        );
        form.setFieldValue(
          'professiona_data.address.state',
          mesorregiao.UF.sigla,
          false,
        );
        form.setFieldValue('professiona_data.address.city', nome, false);
      },
    );
  }
}

function onCities(cities) {
  if (cities.length > 0) {
    return cities.map((city) => ({
      label: city.nome,
      value: city.id,
    }));
  }

  return [
    {
      label: 'Selecione um estado para exibir as cidades',
      value: '',
    },
  ];
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),

  email: Yup.string().required('Campo obrigatório'),

  cpf: Yup.string().required('Campo obrigatório'),

  rg: Yup.string().required('Campo obrigatório'),

  birthday: Yup.string().required('Campo obrigatório'),

  phone: Yup.string().matches(/\([0-9]{2}\) [0-9]{1} [0-9]{4}-[0-9]{4}/, { message: "Preencha o celular corretamente", excludeEmptyString: true }).required("Campo obrigatório"),

  gender: Yup.string().required('Campo obrigatório'),

  marital_status: Yup.string().required('Campo obrigatório'),

  mother: Yup.string().required('Campo obrigatório'),

  address: Yup.object({
    address: Yup.string().required('Campo obrigatório'),
    cep: Yup.string().required('Campo obrigatório'),
    number: Yup.string().required('Campo obrigatório'),
    neighborhood: Yup.string().required('Campo obrigatório'),
    city: Yup.string().required('Campo obrigatório'),
    state: Yup.string().required('Campo obrigatório'),
  }),

  professiona_data: Yup.object({
    profession: Yup.string().required('Campo obrigatório'),
    income: Yup.string().min(10, "Ao menos R$ 1.000,00").max(12, "No maximo R$ 99.999,99").required('Campo obrigatório'),

    company: Yup.string().when('professiona_data.profession', {
      is: (values) =>
        values &&
        (JSON.parse(values).value !== 6 || JSON.parse(values).value !== 7),
      then: Yup.string().required('Campo obrigatório'),
    }),
    role: Yup.string().when('professiona_data.profession', {
      is: (values) =>
        values &&
        (JSON.parse(values).value !== 6 || JSON.parse(values).value !== 7),
      then: Yup.string().required('Campo obrigatório'),
    }),
    phone: Yup.string().when('professiona_data.profession', {
      is: (values) =>
        values &&
        (JSON.parse(values).value !== 6 || JSON.parse(values).value !== 7),
      then: Yup.string().required('Campo obrigatório'),
    }),
    pay_day: Yup.string().when('professiona_data.profession', {
      is: (values) =>
        values &&
        (JSON.parse(values).value !== 6 || JSON.parse(values).value !== 7),
      then: Yup.string().required('Campo obrigatório'),
    }),
    work_time: Yup.string().when('professiona_data.profession', {
      is: (values) =>
        values &&
        (JSON.parse(values).value !== 6 || JSON.parse(values).value !== 7),
      then: Yup.string().required('Campo obrigatório'),
    }),
    address: Yup.object({
      address: Yup.string().when('professiona_data.profession', {
        is: (values) =>
          values &&
          (JSON.parse(values).value !== 6 || JSON.parse(values).value !== 7),
        then: Yup.string().required('Campo obrigatório'),
      }),
      cep: Yup.string().when('professiona_data.profession', {
        is: (values) =>
          values &&
          (JSON.parse(values).value !== 6 || JSON.parse(values).value !== 7),
        then: Yup.string().required('Campo obrigatório'),
      }),
      number: Yup.string().when('professiona_data.profession', {
        is: (values) =>
          values &&
          (JSON.parse(values).value !== 6 || JSON.parse(values).value !== 7),
        then: Yup.string().required('Campo obrigatório'),
      }),
      neighborhood: Yup.string().when('professiona_data.profession', {
        is: (values) =>
          values &&
          (JSON.parse(values).value !== 6 || JSON.parse(values).value !== 7),
        then: Yup.string().required('Campo obrigatório'),
      }),
      city: Yup.string().when('professiona_data.profession', {
        is: (values) =>
          values &&
          (JSON.parse(values).value !== 6 || JSON.parse(values).value !== 7),
        then: Yup.string().required('Campo obrigatório'),
      }),
      state: Yup.string().when('professiona_data.profession', {
        is: (values) =>
          values &&
          (JSON.parse(values).value !== 6 || JSON.parse(values).value !== 7),
        then: Yup.string().required('Campo obrigatório'),
      }),
    }),
  }),

  bank: Yup.object({
    bank: Yup.string().required('Campo obrigatório'),
    agency: Yup.string().required('Campo obrigatório'),
    account: Yup.string().required('Campo obrigatório'),
    type_of_account: Yup.string().required('Campo obrigatório'),
  }),

  Referencias: Yup.object({
    NomeReferencia: Yup.string().required('Campo obrigatório'),
    TelReferencia: Yup.string().required('Campo obrigatório'),
    NomeReferencia2: Yup.string().required('Campo obrigatório'),
    TelReferencia2: Yup.string().required('Campo obrigatório'),
    Parentesco: Yup.string().required('Campo obrigatório'),
    Parentesco2: Yup.string().required('Campo obrigatório')
  })
  
});

const CreatedClient = ({
  getCepRequest,
  getBankRequest,
  getStatesRequest,
  getCitiesRequest,
  getProfessionsRequest,
  postClientsRequest,
  closeModal,
  cep,
  loading,
  professions,
  success,
  banks,
  states,
  cities,
  clienteAnalise
  //analise
}) => {
  const [disabled, setDisabled] = useState(false);
  const [form, setForm] = useState(null);
  const [optionsBanks, setOptionsBank] = useState([]);
  const [optionsStates, setOptionsStates] = useState([]);
  const [birthday, setBirthday] = useState('');
  const [worktime, setWorkTime] = useState('');
  const [getTypeCep, setGetTypeCep] = useState(null);
  const [text, setText] = useState(false);
  const [listProfessions, setListProfessions] = useState(null);

  useEffect(() => {
    const arrays = banks.map((bank) => ({
      name: bank.name,
      value: bank.code,
    }));

    setOptionsBank(arrays);
  }, [banks]);

  useEffect(() => {
    if (professions && professions.length) {
      let list = professions.map((item) => ({
        label: item.label,
        value: JSON.stringify({ id: item.id, value: item.value }),
      }));
      setListProfessions([
        {
          label: 'Selecione uma profissão',
          value: '',
        },
        ...list,
      ]);
    }
  }, [professions]);

  useEffect(() => {
    const arrays = states.map((state) => ({
      label: state.sigla,
      value: state.sigla,
    }));

    setOptionsStates([
      {
        label: 'Selecione um estado',
        value: '',
      },
      ...arrays,
    ]);
  }, [states]);

  useEffect(() => {
    if (getTypeCep === 'company') {
      return onSetCepCompany(cep, form, getCitiesRequest);
    }

    onSetCep(cep, form, getCitiesRequest);
  }, [cep, form, getTypeCep]);

  useEffect(() => {
    if (success) {
      closeModal();
    }
  }, [success]);

  function searchBank(text) {
    getBankRequest({
      /*params: {
        filter: {
          $or: [
            { name: { $regex: text, $options: ['i', 'm', 'x'] } },
            { code: { $regex: text, $options: ['i', 'm', 'x'] } },
          ]
        }
      },*/
    });
  }

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={(values) => {
        //console.log(values.files);
        const data = {
          ...values,
          birthday,
          professiona_data: {
            ...values.professiona_data,
            work_time: worktime || null,
            profession: JSON.parse(values.professiona_data.profession).id,
            income: currencyMask(values.professiona_data.income),
          },
          bank: {
            ...values.bank,
            bank: {
              name: values.bank.bank.split(' - ')[0],
              code: values.bank.bank.split(' - ')[1],
            },
          },
          referencias: {
            ...values.Referencias,
            nomeReferencia: values.Referencias.NomeReferencia,
            nomeReferencia2: values.Referencias.NomeReferencia2,
            telReferencia: values.Referencias.TelReferencia,
            telReferencia2: values.Referencias.TelReferencia2,
            parentesco: values.Referencias.Parentesco,
            parentesco2: values.Referencias.Parentesco2
          }
        };
        //console.log(data);
        //return false;
        postClientsRequest(data);
      }}
      initialValues={{
        name: clienteAnalise ? clienteAnalise.nome : "",
        email: clienteAnalise ? clienteAnalise.email : "",
        cpf: clienteAnalise ? clienteAnalise.cpf : "",
        pre_analise: clienteAnalise ? clienteAnalise.proposta : 0,
        rg: '',
        birthday: "",
        phone: clienteAnalise ? clienteAnalise.celular : "",    
        gender: '',
        marital_status: '',
        mother: '',
        address: {
          address: '',
          cep: '',
          number: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: '',
        },
        professiona_data: {
          profession: '',
          income: '',
          pay_day: '',
          work_time: '',
          company: '',
          role: '',
          phone: '',
          address: {
            address: '',
            cep: '',
            number: '',
            complement: '',
            neighborhood: '',
            city: '',
            state: '',
          },
        },
        bank: {
          bank: {},
          agency: '',
          account: '',
          type_of_account: '',
        },
        Referencias: {
          NomeReferencia:"",
          NomeReferencia2:"",
          TelReferencia:"",
          TelReferencia2:"",
          Parentesco: "",
          Parentesco2: ""
        },
        files: {
          identity: null,
          proof_of_income: null,
        },
      }}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        setFieldValue,
        setFieldError,
        handleBlur,
        isValid,
      }) => {
        if (!form) {
          getStatesRequest();
          getProfessionsRequest();
          setForm({ setFieldValue });
        }

        return (
          <Form className="form" onSubmit={handleSubmit}>
            <fieldset>
              <h4>Dados Pessoais</h4>
              <Row>
                <Col md={6} xs={12}>
                  <Field
                    component={FormGroup}
                    label={'Nome Completo'}
                    error={errors.name}
                    placeholder="Ex: João Henrique de Almeida"
                    name="name"
                  />
                </Col>
                <Col md={6} xs={12}>
                  <Field
                    component={FormGroup}
                    label={'E-mail'}
                    placeholder="Ex: cliente@exemplo.com"
                    name="email"
                    type="email"
                    error={errors.email}
                  />
                </Col>
                <Col md={6} xs={12}>
                  <Field
                    component={FormGroup}
                    label={'CPF'}
                    mask="111.111.111-11"
                    placeholder="Ex: 999.999.999-99"
                    name="cpf"
                    error={errors.cpf}
                  />
                </Col>
                <Col md={6} xs={12}>
                  <Field
                    component={FormGroup}
                    label={'RG'}
                    placeholder="Ex: 33123212"
                    name="rg"
                    error={errors.rg}
                  />
                </Col>
                <Col md={6} xs={12}>
                  <Field
                    component={FormGroup}
                    error={errors.birthday}
                    selected={birthday}
                    label={'Data de nascimento'}
                    placeholder="Ex: 01/01/1990"
                    mask="11/11/1111"
                    name="birthday"
                    onChange={(e) => {
                      const val = e.target.value;
                      setBirthday(moment(val, 'DD/MM/YYYY').format());
                      setFieldValue('birthday', val);
                    }}
                  />
                </Col>
                <Col md={6} xs={12}>
                  <Field
                    component={FormGroup}
                    label={'Celular'}
                    placeholder="Ex: (99) 9 9999-9999"
                    name="phone"
                    mask="(11) 1 1111-1111"
                    error={errors.phone}
                  />
                </Col>
                <Col md={6} xs={12}>
                  <Field
                    component={FormGroup}
                    label={'Sexo'}
                    name="gender"
                    type="select"
                    error={errors.gender}
                    options={[
                      {
                        label: 'Selecione um Sexo',
                        value: '',
                      },
                      {
                        label: 'Masculino',
                        value: 'male',
                      },
                      {
                        label: 'Feminino',
                        value: 'famale',
                      },
                    ]}
                  />
                </Col>
                <Col md={6} xs={12}>
                  <Field
                    component={FormGroup}
                    label={'Estado civil'}
                    name="marital_status"
                    type="select"
                    error={errors.marital_status}
                    options={[
                      {
                        label: 'Selecione um estado civil',
                        value: '',
                      },
                      {
                        label: 'Solteiro(a)',
                        value: 'not_married',
                      },
                      {
                        label: 'Casado(a)',
                        value: 'married',
                      },
                      {
                        label: 'Divorciado(a)',
                        value: 'divorcerd',
                      },
                      {
                        label: 'Viúvo(a)',
                        value: 'widow',
                      },
                    ]}
                  />
                </Col>
                <Col md={6} xs={12}>
                  <Field
                    component={FormGroup}
                    label={'Nome da mãe'}
                    placeholder="Ex: Maria dos Santos de Almeida"
                    name="mother"
                    error={errors.mother}
                  />
                </Col>
              </Row>
            </fieldset>
            <fieldset>
              <h4>Endereço</h4>
              <Row>
                <Col md={3} xs={12}>
                  <FormGroup
                    label={'CEP'}
                    placeholder="Ex: 59943-233"
                    name="address.cep"
                    mask="11111-111"
                    error={
                      errors.address && errors.address.cep
                        ? errors.address.cep
                        : null
                    }
                    onChange={(e) => {
                      const val = e.target.value;
                      setFieldValue('address.cep', val);
                    }}
                    onBlur={(e) => {
                      const value = e.target.value;
                      setGetTypeCep(null);
                      setFieldValue('address.cep', value);
                      getCepRequest(value);
                    }}
                  />
                </Col>
                <Col md={4} sm={4} xs={12}>
                  <Field
                    component={FormGroup}
                    label={'Rua'}
                    placeholder="Ex: Av. Tim Maia"
                    name="address.address"
                    value={values.address.address}
                    error={
                      errors.address && errors.address.address
                        ? errors.address.address
                        : null
                    }
                  />
                </Col>
                <Col md={2} sm={2} xs={12}>
                  <Field
                    component={FormGroup}
                    type="number"
                    label={'Número'}
                    placeholder="Ex: 892"
                    name="address.number"
                    error={
                      errors.address && errors.address.number
                        ? errors.address.number
                        : null
                    }
                  />
                </Col>
                <Col md={3} xs={12}>
                  <Field
                    component={FormGroup}
                    label={'Complemento'}
                    placeholder="Ex: Bl 4, apto 41"
                    name="address.complement"
                  />
                </Col>
                <Col lg={4} md={6} xs={12}>
                  <Field
                    component={FormGroup}
                    type="select"
                    label={'Estado'}
                    name="address.state"
                    error={
                      errors.address && errors.address.state
                        ? errors.address.state
                        : null
                    }
                    options={optionsStates}
                    onChange={(e) => getCitiesRequest(e.target.value)}
                    value={values.address.state}
                  />
                </Col>
                <Col lg={4} md={6} xs={12}>
                  <Field
                    component={FormGroup}
                    label={'Cidade'}
                    disabled={cities.length === 0}
                    name="address.city"
                    error={
                      errors.address && errors.address.city
                        ? errors.address.city
                        : null
                    }
                  />
                </Col>
                <Col lg={4} md={6} xs={12}>
                  <Field
                    component={FormGroup}
                    label={'Bairro'}
                    placeholder="Ex: Seu bairro"
                    name="address.neighborhood"
                    error={
                      errors.address && errors.address.neighborhood
                        ? errors.address.neighborhood
                        : null
                    }
                    value={values.address.neighborhood}
                  />
                </Col>
              </Row>
            </fieldset>
            <fieldset>
              <h4>Dados Profissionais</h4>
              <Row>
                <Col md={6} xs={12}>
                  {listProfessions && listProfessions.length && (
                    <Field
                      component={FormGroup}
                      type="select"
                      label={'Profissão'}
                      name="professiona_data.profession"
                      //error={errors.state}
                      options={listProfessions}
                      onChange={(e) =>
                        setFieldValue(
                          'professiona_data.profession',
                          e.target.value,
                        )
                      }
                      value={values.professiona_data.profession}
                      error={
                        errors.professiona_data &&
                        errors.professiona_data.profession
                          ? errors.professiona_data.profession
                          : null
                      }
                    />
                  )}
                </Col>
                <Col md={6} xs={12}>
                  <FormGroup
                    label={'Sua renda'}
                    type="currency"
                    name="professiona_data.income"
                    error={
                      errors.professiona_data && errors.professiona_data.income
                        ? errors.professiona_data.income
                        : null
                    }
                    placeholder="Ex: R$ 1450,00"
                    value={values.professiona_data.income}
                    onChange={(e) => {
                      setFieldValue('professiona_data.income', e.target.value);
                    }}
                  />
                </Col>
              </Row>
              {values.professiona_data.profession &&
                JSON.parse(values.professiona_data.profession).value != '6' &&
                JSON.parse(values.professiona_data.profession).value != '7' && (
                  <Row>
                    <Col md={6} xs={12}>
                      <Field
                        component={FormGroup}
                        label={'Nome da empresa'}
                        placeholder="Ex: Empresa LTDA."
                        name="professiona_data.company"
                        error={
                          errors.professiona_data &&
                          errors.professiona_data.company
                            ? errors.professiona_data.company
                            : null
                        }
                      />
                    </Col>

                    <Col md={6} xs={12}>
                      <Field
                        component={FormGroup}
                        label={'Cargo'}
                        placeholder="Ex: Gerente"
                        name="professiona_data.role"
                        error={
                          errors.professiona_data &&
                          errors.professiona_data.role
                            ? errors.professiona_data.role
                            : null
                        }
                      />
                    </Col>

                    <Col md={6} xs={12}>
                      <Field
                        component={FormGroup}
                        label={'Telefone da empresa'}
                        placeholder="Ex: (99) 9 9999-9999"
                        mask="(11) 1111-1111"
                        name="professiona_data.phone"
                        error={
                          errors.professiona_data &&
                          errors.professiona_data.phone
                            ? errors.professiona_data.phone
                            : null
                        }
                      />
                    </Col>

                    <Col md={6} xs={12}>
                      <Field
                        component={FormGroup}
                        label={'Dia de pagamento'}
                        placeholder="Ex: 10"
                        maxLength="2"
                        name="professiona_data.pay_day"
                        error={
                          errors.professiona_data &&
                          errors.professiona_data.pay_day
                            ? errors.professiona_data.pay_day
                            : null
                        }
                      />
                    </Col>

                    <Col md={6} xs={12}>
                      <Field
                        component={FormGroup}
                        label={'Dia da contratação'}
                        name="professiona_data.work_time"
                        error={
                          errors.professiona_data &&
                          errors.professiona_data.work_time
                            ? errors.professiona_data.work_time
                            : null
                        }
                        placeholder="Ex: 01/01/2020"
                        mask="11/11/1111"
                        onChange={(e) => {
                          const val = e.target.value;
                          setWorkTime(moment(val, 'DD/MM/YYYY').format());
                          setFieldValue('professiona_data.work_time', val);
                        }}
                      />
                    </Col>

                    <Col xs={12}>
                      <hr style={{ marginBottom: 30 }} />
                    </Col>

                    <Col md={3} xs={12}>
                      <FormGroup
                        label={'CEP da empresa'}
                        placeholder="Ex: 59943-233"
                        name="professiona_data.address.cep"
                        mask="11111-111"
                        error={
                          errors.professiona_data &&
                          errors.professiona_data.address &&
                          errors.professiona_data.address.cep
                            ? errors.professiona_data.address.cep
                            : null
                        }
                        onChange={(e) => {
                          const val = e.target.value;
                          setFieldValue('professiona_data.address.cep', val);
                        }}
                        onBlur={(e) => {
                          setGetTypeCep('company');
                          const value = e.target.value;
                          setFieldValue('professiona_data.address.cep', value);
                          getCepRequest(value);
                        }}
                      />
                    </Col>
                    <Col md={4} sm={4} xs={12}>
                      <Field
                        component={FormGroup}
                        label={'Rua da empresa'}
                        placeholder="Ex: Av. Tim Maia"
                        name="professiona_data.address.address"
                        value={values.address.address}
                        error={
                          errors.professiona_data &&
                          errors.professiona_data.address &&
                          errors.professiona_data.address.address
                            ? errors.professiona_data.address.address
                            : null
                        }
                      />
                    </Col>
                    <Col md={2} sm={2} xs={12}>
                      <Field
                        component={FormGroup}
                        type="number"
                        label={'Número da empresa'}
                        placeholder="Ex: 892"
                        name="professiona_data.address.number"
                        error={
                          errors.professiona_data &&
                          errors.professiona_data.address &&
                          errors.professiona_data.address.number
                            ? errors.professiona_data.address.number
                            : null
                        }
                      />
                    </Col>
                    <Col md={3} xs={12}>
                      <Field
                        component={FormGroup}
                        label={'Complemento da empresa'}
                        placeholder="Ex: Bl 4, apto 41"
                        name="professiona_data.address.complement"
                      />
                    </Col>
                    <Col lg={4} md={6} xs={12}>
                      <Field
                        component={FormGroup}
                        type="select"
                        label={'Estado da empresa'}
                        name="professiona_data.address.state"
                        error={
                          errors.professiona_data &&
                          errors.professiona_data.address &&
                          errors.professiona_data.address.state
                            ? errors.professiona_data.address.state
                            : null
                        }
                        options={optionsStates}
                        onChange={(e) => getCitiesRequest(e.target.value)}
                        value={values.address.state}
                      />
                    </Col>
                    <Col lg={4} md={6} xs={12}>
                      <Field
                        component={FormGroup}
                        label={'Cidade da empresa'}
                        disabled={cities.length === 0}
                        name="professiona_data.address.city"
                        error={
                          errors.professiona_data &&
                          errors.professiona_data.address &&
                          errors.professiona_data.address.city
                            ? errors.professiona_data.address.city
                            : null
                        }
                      />
                    </Col>
                    <Col lg={4} md={6} xs={12}>
                      <Field
                        component={FormGroup}
                        label={'Bairro da empresa'}
                        placeholder="Ex: Seu bairro"
                        name="professiona_data.address.neighborhood"
                        error={
                          errors.professiona_data &&
                          errors.professiona_data.address &&
                          errors.professiona_data.address.neighborhood
                            ? errors.professiona_data.address.neighborhood
                            : null
                        }
                        value={values.address.neighborhood}
                      />
                    </Col>
                  </Row>
                )}
            </fieldset>
            <fieldset>
              <h4>Bancos</h4>
              <Row>
                <Col md={6} xs={12}>
                  {/* <Field
                    component={FormGroup}
                    label={'Banco'}
                    type="autocomplete"
                    name="bank.bank"
                    itemsData={optionsBanks}
                    placeholder="Digite o nome ou código do banco"
                    getItemValue={(item) => `${item.name} - ${item.value}`}
                    onSelect={(value) => setFieldValue('bank.bank', value)}
                  /> */}

                  <Field
                    type="autocomplete"
                    component={FormGroup}
                    label={'Banco'}
                    placeholder="Digite o nome ou código do banco"
                    maxOptionsLimit={10}
                    error={errors.bank && errors.bank.bank ? errors.bank.bank : null}
                    itemsData={optionsBanks}
                    getItemValue={(item) => `${item.name} - ${item.value}`}
                    onSelect={(value) => setFieldValue('bank.bank', value)}
                    name="client"                    
                    onChange={(text) => {
                      if (text) {
                        setText(true);
                      } else {
                        setText(false);
                      }
                      searchBank(text);

                      if(text.length < 8) {
                        setFieldValue('bank.bank', '')
                      }
                    }}
                  />
                </Col>
                <Col md={6} xs={12}>
                  <Field
                    component={FormGroup}
                    label={'Tipo da conta'}
                    type="select"
                    name="bank.type_of_account"
                    error={
                      errors.bank && errors.bank.type_of_account
                        ? errors.bank.type_of_account
                        : null
                    }
                    options={[
                      {
                        label: 'Selecione o tipo da conta',
                        value: '',
                      },
                      {
                        label: 'Conta Corrente',
                        value: 'current_account',
                      },
                      {
                        label: 'Conta Poupança',
                        value: 'savings',
                      },
                    ]}
                  />
                </Col>
                <Col md={6} xs={12}>
                  <Field
                    component={FormGroup}
                    label={'Agência'}
                    name="bank.agency"
                    placeholder="Ex: 0001"
                    error={
                      errors.bank && errors.bank.agency
                        ? errors.bank.agency
                        : null
                    }
                  />
                </Col>
                <Col md={6} xs={12}>
                  <Field
                    component={FormGroup}
                    label={'Conta com dígito'}
                    name="bank.account"
                    placeholder="Ex: 1232124"
                    error={
                      errors.bank && errors.bank.account
                        ? errors.bank.account
                        : null
                    }
                  />
                </Col>
              </Row>
            </fieldset>

            <fieldset>
              <h4>Contatos de Referência</h4>
              <Row>
                <Col md={6} xs={12}>
                  <Field
                    component={FormGroup}
                    label={'Nome'}
                    name="Referencias.NomeReferencia"
                    placeholder="Ex: Nome do contato de referência"
                    error={errors.Referencias && errors.Referencias.NomeReferencia ? errors.Referencias.NomeReferencia : null}
                  />
                </Col>
                <Col md={3} xs={12}>
                  <Field
                    component={FormGroup}
                    label={"Telefone ou celular"}
                    name="Referencias.TelReferencia"
                    placeholder="Ex: (21)12345-6789"
                    mask="(11)11111-1111"
                    error={errors.Referencias && errors.Referencias.TelReferencia ? errors.Referencias.TelReferencia : null}
                  />
                </Col>
                <Col md={3} xs={12}>
                  <Field
                    component={FormGroup}
                    label={"Grau de proximidade"}
                    name="Referencias.Parentesco"
                    placeholder="Ex: Irmao"
                    error={errors.Referencias && errors.Referencias.Parentesco ? errors.Referencias.Parentesco : null}

                  />
                </Col>
                <Col md={6} xs={12}>
                  <Field
                    component={FormGroup}
                    label={"Nome"}
                    name="Referencias.NomeReferencia2"
                    placeholder="Ex: Nome do contato de referência"
                    error={errors.Referencias && errors.Referencias.NomeReferencia2 ? errors.Referencias.NomeReferencia2 : null}
                  />
                </Col>
                <Col md={3} xs={12}>
                  <Field
                    component={FormGroup}
                    label={"Telefone ou celular"}
                    name="Referencias.TelReferencia2"
                    placeholder="Ex: (21)12345-6789"
                    mask="(11)11111-1111"
                    error={errors.Referencias && errors.Referencias.TelReferencia2 ? errors.Referencias.TelReferencia2 : null}
                  />
                </Col>
                <Col md={3} xs={12}>
                  <Field
                    component={FormGroup}
                    label={"Grau de proximidade"}
                    name="Referencias.Parentesco2"
                    placeholder="Ex: Amigo"
                    error={errors.Referencias && errors.Referencias.Parentesco2 ? errors.Referencias.Parentesco2 : null}
                  />
                </Col>
              </Row>
            </fieldset>

            <fieldset>
              <h4>Arquivos</h4>
              <Row>
                <Col md={6} xs={12}>
                  <h5>Identidade</h5>
                  <Field
                    multiple
                    component={FormGroup}
                    label={'Identidade'}
                    name="files.identity"
                    type="file"
                    id="identity"
                    onChange={(e) => handleChange(e)}
                  />
                </Col>
                <Col md={6} xs={12}>
                  <h5>Comprovante de renda</h5>
                  <Field
                    multiple
                    component={FormGroup}
                    label={'Comprovante de renda'}
                    name="files.proof_of_income"
                    id="proof_of_income"
                    type="file"
                    onChange={(e) => handleChange(e)}
                  />
                </Col>
              </Row>
            </fieldset>
            <div className="text-center footer">
              <Button loading={loading} disabled={!isValid} type="submit">
                Salvar
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = ({
  cep,
  bank,
  states,
  cities,
  clients,
  professions,
  //analise
}) => ({
  cep: cep.data,
  loading: clients.loading,
  error: cep.error,
  banks: bank.data,
  states: states.data,
  cities: cities.data,
  professions: professions.data,
  success: clients.success,
  //analise: analise.data
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...CepActions,
      ...BankActions,
      ...StatesActions,
      ...CitiesActions,
      ...ClientsActions,
      ...ProfessionsActions,
      //...AnaliseActions
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreatedClient);
