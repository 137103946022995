import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Link, useLocation, useParams } from 'react-router-dom';
import colors from '@/styles/colors';

import ForgotPasswordImage from '@/assets/images/forgot-password.png';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AuthenticationActions from '@/store/ducks/authentication';


import { Col, Container, Row } from 'react-bootstrap';

import {
  Body,
  Header,
  Section,
  Formgroup,
  ErrorBlcok,
  Form,
  Figure,
  Input,
  Button
} from './styles';



const validationSchemaPassword = Yup.object().shape({
  password: Yup.string()
    .required('Campo obrigatório'),
  passwordConfirmation: Yup.string()
    .required('Campo obrigatório')
    .oneOf([Yup.ref('password'), null], 'Senhas diferentes')
});


const validationSchemaEmail = Yup.object().shape({
  email: Yup.string()
    .required('Campo obrigatório')
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ForgotPassword = ({
  postForgotPasswordRequest,
  postResetPasswordRequest,
  loading,
  history,
  match,
  success,
  ...props
}) => {
  const [code] = useState(useQuery().get('code'));

  const newPasswordForm = () => {
    return (
      <>
        <Formik
          validationSchema={validationSchemaPassword}
          initialValues={{
            password: '',
            passwordConfirmation: ''
          }}
          onSubmit={(values, { resetForm }) => {
            postResetPasswordRequest({
              ...values,
              code
            });
            resetForm();
          }}
        >
          {({
            values, errors, handleChange, handleSubmit,
          }) => {
            return (
              <Form>
                <Formgroup>
                  <Input
                    type="password"
                    required
                    onChange={handleChange}
                    placeholder="Senha"
                    name="password"
                    value={values.password}
                    className={errors?.password ? 'error' : ''}
                  />
                </Formgroup>


                <Formgroup>
                  <Input
                    type="password"
                    required
                    onChange={handleChange}
                    placeholder="Repita sua senha"
                    name="passwordConfirmation"
                    value={values.passwordConfirmation}
                    className={errors?.passwordConfirmation ? 'error' : ''}
                  />
                </Formgroup>

                <Row style={{ alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
                  <Col>
                    <Button type="submit" onClick={handleSubmit}>Alterar senha</Button>
                  </Col>
                  <Col>
                    <Link to="/login">Voltar</Link>
                  </Col>
                </Row>

              </Form>
            )
          }}
        </Formik>
      </>
    )
  }

  const layoutEmail = () => {
    return (
      <>
        <Formik
          validationSchema={validationSchemaEmail}
          onSubmit={(values, { resetForm }) => {
            postForgotPasswordRequest(values);
            resetForm();
          }}
          initialValues={{
            email: ''
          }}
        >
          {({
            values, errors, handleChange, handleSubmit,
          }) => {
            return (
              <Form>
                <Formgroup>
                  <Input
                    type="email"
                    placeholder="Email"
                    name="email"
                    className={errors?.email ? 'error' : ''}
                    onChange={handleChange}
                  />
                </Formgroup>

                <Row style={{ alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
                  <Col>
                    <Button type="submit" onClick={handleSubmit}>Continuar</Button>
                  </Col>
                  <Col>
                    <Link to="/login">Voltar</Link>
                  </Col>
                </Row>

              </Form>
            )
          }}
        </Formik>
      </>
    )
  }

  return (
    <Body>
      <Container>
        <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
          <Col>
            <Header>
              <h2>Esqueceu sua senha?</h2>
              <p>Preencha com seu email para que possamos te ajudar.</p>
            </Header>
            <Section>
              {code ? newPasswordForm() : layoutEmail()}
            </Section>
          </Col>
          <Col>
            <Figure>
              <img src={ForgotPasswordImage} />
            </Figure>
          </Col>
        </Row>
      </Container>
    </Body>
  )
};

const mapStateToProps = ({ authentication }) => ({
  loading: authentication.loading,
  success: authentication.success,
  error: authentication.error,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(AuthenticationActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

