import React, { useEffect, useState } from 'react';

import loc_img_form from '@/assets/images/loc_img_form.png';

import { Button } from '@material-ui/core';

import {
  DivBlockText,
  ImgBox,
  Letra,
  Letra2,
  ContentFormInside,
} from '../styles';
import cogoToast from 'cogo-toast';
import Lottie from 'react-lottie';
import animations from '@/assets/animations';
import storage from '@/utils/storage';
import toast from '@/utils/toast';

export default ({
  setGeolocation,
  nextPage,
  trail
}) => {
  const [permission, setPermission] = useState(false);


  const successGeolocation = async (position) => {
    console.log('positions: ', position)
    if (position) {
      await storage.setStorage('geolocation', JSON.stringify({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }));
      setGeolocation({
        ...trail,
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });

      return nextPage();
    }
    return cogoToast.error('Precisamos da sua geolocalização.');
  }


  const errorGeolocation = async (error) => {
    toast.error('Não conseguimos pegar sua geolocalização')
  }

  useEffect(() => {
    if (permission) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        await storage.setStorage('geolocation', JSON.stringify({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }));

        setGeolocation({
          ...trail,
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });

        nextPage();
      }, (error) => {
        toast.error(error.message);
      });
    }
  }, [permission]);

  const handlePermission = async () => {
    const opts = {
      enableHighAccuracy: false,
      maximumAge: 15000,
      timeout: 30000
    };
    navigator.geolocation.getCurrentPosition(successGeolocation, errorGeolocation)
  }

  /*const handlePermission = async () => {
    const { state } = await navigator.permissions.query({ name: 'geolocation' });
    switch (state) {
      case 'granted':
        return setPermission(true);
      case 'denied':
        return cogoToast.error('Precisamos da sua geolocalização.');
    }
  }*/

  return (
    <ContentFormInside>
      <ImgBox><img style={{ maxHeight: "300px", maxWidth: "300px" }} src={loc_img_form}></img></ImgBox>
      <DivBlockText>
        <Letra>Acesso à localização</Letra>
        <Letra2>Precisamos acessar sua localização para continuar com a formalização do seu empréstimo.</Letra2>
      </DivBlockText>
      {!permission && <Button onClick={handlePermission} fullWidth={false} size="large" variant="contained" color="primary">Permitir acesso</Button>}
      {permission && (
        <Lottie
          height={150}
          width={150}
          options={{
            loop: true,
            autoplay: true,
            animationData: animations.loading
          }}
        />
      )}
    </ContentFormInside>
  )
}