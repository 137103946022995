import React, { useState, useEffect } from 'react'
import Lottie from 'react-lottie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ClientsActions from '@/store/ducks/clients'
import UsersActions from '@/store/ducks/users'
import ProposalActions from '@/store/ducks/proposal'

import { Container, Modal } from 'react-bootstrap';

import animations from '@/assets/animations'

import HeaderUser from '@/components/HeaderUser'
import Table from '@/components/Table'
import Box from '@/components/Box'
import { faTrash, faEye, faFile } from '@fortawesome/free-solid-svg-icons'

import CreatedClient from '@/pages/Clients/Created'

import CreatedProposal from './Created'

import Reupload from "./reupload"

import headers from './headers';
import Button from '@/components/Button'
import { Link } from 'react-router-dom'
import { InputSearchBox } from './styles'
import Selectt from 'react-select'
import moment from 'moment';

function sortByIdDesc(a, b){
  if(a.proposta < b.proposta) return 1;
  if(a.proposta > b.proposta) return -1;
  return 0;
}

function tableOrder(p){
  if(p && p.length > 0){
    p = p.map(t => {
      if(t && t.client && t.status !== "Cancelada"){
        return {
          proposta: parseInt(t.proposal_external),
          id: t.id,
          client: t.client.name,
          client_id: t.client.id,
          cpf: t.client.cpf,
          email: t.client.email,
          phone: t.client.phone,
          value: t.padrao.ValorEmprestimo,
          status: t.status,
          createdAt: t.createdAt,
          tableActions: null,
          padraoParcela: t.padrao.ValorParcela,
          padraoObs: t.padrao.Observacao,
          padraoPrazo: t.padrao.Prazo,
          padraoStatus: t.padrao.Situacao,
          token: t.token?.token,
          tipoP: t.padrao.Plano
        }
      }
    })
    let copy = [];
    p.forEach(e => {copy.push(e)});
    return copy.sort(sortByIdDesc);
  }
}

function filterProposal(p, filter){
  return p.filter( a => {
    if(filter == "") return p;
    if(filter == "Pendente"){
      if(a.status == "Pendente com Restição" || a.status == "Pendente sem Restição" ){
        return a;
      }
    }
    if(a.status == filter){
      return a;
    }
  })
}

function searchClient(text, getClientsRequest) {
  getClientsRequest({
    filter: {
      nameOrCpf: text
    }
  })
}

function Proposal({
  user,
  getClientsRequest,
  clients,
  getProposalRequest,
  postProposalRequest,
  postGenerateSignatureRequest,
  loading,
  success,
  proposals,
  loadingClient,
  clientSuccess,
  history
}) {

  const [proposal, setProposal] = useState();

  const [show, setShow] = useState(false);
  const [showClient, setShowClient] = useState(false);
  const [showReupload, setShowReupload] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseClient = () => setShowClient(false);
  const handleShowClient = () => setShowClient(true);

  const handleCloseReupload = () => setShowReupload(false);
  const handleShowReupload = () => setShowReupload(true);

  const [searchBox, setSearchBox] = useState("");

  const [listaProposals, setLista] = useState();

  
  const handleSearchBox = (value) => {
    setSearchBox(value);
  }

  useEffect(() => {
    getProposalRequest();
    if (history.location.state && history.location.state.openModal) {
      setShow(true)
    }
  }, [])

  const generateToken = (data) => {
    postGenerateSignatureRequest(data);
    setTimeout(() => {
      window.location.reload();
    }, 1500)
  }


useEffect(() => {
  setLista(tableOrder(proposals))
}, [proposals])


  useEffect(() => {
    if (success) {
      setTimeout(() => {
        getProposalRequest();
      }, 1500)
      handleClose();
    }
  }, [success])

  if (!user || loading) {
    return (
      <Lottie
        height={400}
        width={400}
        options={{
          loop: true,
          autoplay: true,
          animationData: animations.loading
        }}
      />
    )
  }
  const options = [
    { value: '', label: 'Tudo' },
    { value: 'Proposta Paga', label: 'Pagas' },
    { value: 'Negada', label: 'Recusada' },
    { value: 'Aguardando Formalização', label: 'Aprovada' },
    { value: 'Em Avaliação', label: 'Em Avaliação' },
    { value: 'Pendente', label: 'Pendente' },
  ]

  return (
    <>
      <Container fluid className="animated fadeIn">
        <HeaderUser
          user={user}
          iconButton={faFile}
          textButton="Criar proposta"
          onClickButton={handleShow}
        />
        <Box table>
          <div className="filtersDiv">
            <div className="inputSearchBoxDiv">
              <input className="inputSearchBox" type="text"  name="searchBox" placeholder="Buscar..."
                onChange={(e) => { handleSearchBox(e.target.value) } }
            />
            <i>&#x1F50E;&#xFE0E;</i>
            </div>
            <Selectt
            placeholder="Status..."
            className="selectStatus"
            onChange={
              e=>{
                setLista(tableOrder(filterProposal(proposals, e.value)));
              }
            }
              options={options} 
              //isMulti
          >Status</Selectt>
  
            
          </div>
          <Table
            data={listaProposals}
            searchBox={searchBox}
            headers={{
              ...headers,
              tableActions: {
                text: '',
                invisible: false,
                sortable: false,
                filterable: false, 
                transform: (value, index, row) => {
                  if (user.user.role.name === 'Corresponding' || user.user.role.name === "Manager") {
                    return (
                      <div className="group-buttons">
                        {(row.status === 'Aguardando Formalização' || row.status === 'Aguardando Assinatura') && <Button className="small signature" action={() => generateToken({ contract: row.id, client: row.client_id })} >Recolher assinatura</Button>}
                      <Button action={()=>{
                        setProposal(row);
                        handleShowReupload();
                      }} className="circle small"><FontAwesomeIcon size={14} icon={faEye} /></Button>
                      </div>
                    )
                    //<Link to={`propostas/${row.id}`}><Button className="circle small"><FontAwesomeIcon size={14} icon={faEye} /></Button></Link>

                  }
                }
              }
            }}
          />
        </Box>
      </Container>

      <Modal size="xl"
        show={showReupload}
        //onHide={handleClose}
        onHide={() => {
          handleCloseReupload();
        }}>
          <Modal.Header closeButton>
          <Modal.Title>Dados da Proposta</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Reupload
            id = {proposal}
            closeModal={handleCloseReupload} />
          </Modal.Body>
      </Modal>
      
      <Modal
        size="xl"
        show={show}
        //onHide={handleClose}
        onHide={() => {
          getProposalRequest();
          handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Nova Proposta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreatedProposal
            closeModal={handleClose}
            clients={clients}
            handleShowClient={handleShowClient}
            searchClient={(text) => searchClient(text, getClientsRequest)}
            loadingClient={loadingClient}
            clientSuccess={clientSuccess}
            user={user}
            postProposalRequest={postProposalRequest}
            loading={loading}
          />
        </Modal.Body>
      </Modal>
      <Modal
        size="xl"
        show={showClient}
        //onHide={handleCloseClient}
        onHide={() => {
          handleCloseClient();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Novo Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreatedClient closeModal={handleCloseClient} />
        </Modal.Body>
      </Modal>
    </>
  );
}


const mapStateToProps = ({ users, clients, proposal }) => ({
  loading: proposal.loading,
  user: users.data.user,
  clients: clients.data,
  loadingClient: clients.loading,
  clientSuccess: clients.success,
  proposals: proposal.data,
  success: proposal.success
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  ...UsersActions,
  ...ClientsActions,
  ...ProposalActions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Proposal);
