import React, { useState, useRef, useCallback, useEffect } from 'react';
import Lottie from 'react-lottie'
import animations from '@/assets/animations'
import SignaturePad from 'signature_pad';
import 'react-html5-camera-photo/build/css/index.css';
import Loading from '@/assets/images/loading.svg';
import { ReactSVG } from 'react-svg';


import {
  Button,
  Grid
} from '@material-ui/core';

import {
  DivBlockText,
  DivBlockTextAssinatura,
  ContentFormInside, 
  Letra,
  Letra2,
  DivButtonAssinatura,
  ButtonAssinatura,
  ButtonLimparAssinatura,
} from '../styles';


export default ({
  showSignature,
  loading,
  history,
  setShowSignature,
  getSignatureRequest,
  formalizeSignatureRequest,
  proposal
}) => {
  const [signaturePad, setSignaturePad] = useState('');

  useEffect(() => {
    // if (showSignature) {
      let element = new SignaturePad(document.getElementById('signature-pad'), {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        penColor: 'rgb(0, 0, 0)'
      })
      element.canvas.width = document.querySelector('.parent-signature').offsetWidth;
      element.canvas.height = '160';

      setSignaturePad(element);
    // }
    
  }, []);

  // if (showSignature) {
    return (

      <ContentFormInside style={{ width: 720 }}>

        <DivBlockText>
          <Letra>Assine com o dedo no espaço abaixo</Letra>
        </DivBlockText>
        <div className="parent-signature" style={{border:"2px solid", maxHeight: "160px", borderRadius:"25px", maxWidth: "360px"}}>
          <canvas id="signature-pad" style={{maxHeight:"160px", borderRadius:"25px"}} className={`signature-pad ${loading ? 'hide' : ''}`}></canvas>
        </div>
        <DivButtonAssinatura>

          <ButtonLimparAssinatura onClick={() => signaturePad.clear()}> LIMPAR</ButtonLimparAssinatura>
          <ButtonAssinatura 
            disabled={loading}
            onClick={() => {
            let img = signaturePad.toDataURL('image/png');
            const token = history.location.search.replace('?token=', '');
            getSignatureRequest({
            signature: img,
            filename: `signature_${proposal.proposal_external}_${proposal.client.id}.jpg`,
            proposal: proposal.id
            }, token);
          }} >
          {!loading && 'ASSINAR'} 
          {loading && <ReactSVG className="loading" src={Loading} />}
          </ButtonAssinatura>
           
        </DivButtonAssinatura>
        
        <DivBlockTextAssinatura>
          <Letra2>Ao clicar em <strong>"Assinar"</strong>, você concorda com os Termos e Condições.</Letra2>
        </DivBlockTextAssinatura>
       
        </ContentFormInside>
    )
  
}

/*{loading && <Lottie
          width={200}
          options={{
            loop: true,
            autoplay: true,
            animationData: animations.loading
          }}
        />}*/