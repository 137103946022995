import styled from 'styled-components';

import colors from '@/styles/colors';

export const HeaderContainer = styled.div`
  height: 90px;
  width: 100%;
  background: #f5f7fa;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.04);
  border: 1px solid #eee;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Image = styled.img`
  width: 90px;
  height: 45px;
  max-height: 100%;
  display: block;
`;

export const LogoContainer = styled.a`
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 145px;
`;

export const Menu = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const Item = styled.span`
  height: 100%;
  padding: 35px 25px;
  color: ${colors.grayBold};
  text-decoration: none !important;
  transition: all 250ms;
  position: relative;
  border-left: 1px solid #ddd;

  svg {
    margin-right: 5px;
  }

  :after {
    width: 100%;
    height: 0;
    background: ${colors.default};
    bottom: 0;
    left: 0;
    content: '';
    position: absolute;
    transition: all 250ms;
  }

  :hover, &.active {
    color: ${colors.default};
    background: ${colors.white};

    :after {
      height: 5px;
    }
  }
`;

export const GroupInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px;
`;

export const Bold = styled.strong`
  color: ${colors.dark}
`;

export const NotificationBar = styled.div`
  width: 600px;
  height: 100vh;
  background: ${colors.white};
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  box-shadow: 0 2px 30px rgba(0,0,0,0.1);
  padding: 0 32px;
`;

export const Alert = styled.div`
  background: ${colors.error};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: ${colors.white};

  a {
    color: ${colors.white};
    display: block;
    padding: 10px 30px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;

    &: hover{
      text-decoration: none;
    }
  }
`;


export const ItemNotification = styled.div`
  padding: 15px 0;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  display: flex;
  align-items: center;

  h5 {
    font-size: 18px;
    font-weight: bold;
  }

  p {
    font-size: 14px;
  }

  .diviser {
    flex: 1;
    p {
      flex: 1;
    }
  }

  .timer {
    font-size: 12px;
    color: #bbb
  }
`