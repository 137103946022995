import moment from 'moment';
import status from '@/utils/status'

export default ({
  "id": {
    invisible: true
  },
  "_id": {
    invisible: true
  },
  "__v": {
    invisible: true
  },
  "usuario": {
    invisible: true
  },
  "nome":{
    sortable: false,
    filterable: false
  },
  "proposta":{
    sortable: false,
    filterable: false
  },
  "cpf": {
    text: "CPF",
    sortable: false
  },
  "email": {
    sortable: false
  },
  "nascimento": {
    invisible: true,
    sortable: false
  },
  "celular": {
    sortable: false
  },
  "status": {
    transform: (value) => status(value),
    sortable: false,
    filterable: true
  },
  "createdAt": {
    text: "Data de criação",
    transform: (value) => moment(value).format('DD/MM/YYYY'),
    filterable: false,
    invisible: true
  },
  "updatedAt": {
    text: "Atualização",
    transform: (value) => moment(value).format('DD/MM/YYYY'),
    invisible: true
  }
})