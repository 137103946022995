import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getSignatureRequest: ['values', 'token'],
  getSignatureSuccess: ['data'],
  getSignatureFailure: [],

  formalizeSignatureRequest: ['values', 'token'],
  formalizeSignatureSuccess: [],
  formalizeSignatureFailure: [],

  generatePdfRequest: ['values', 'token'],
  generatePdfSuccess: [],
  generatePdfFailure: [],
});

export const SignatureTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  loading: false,
  data: [],
  error: false,
  success: false,
  success_signature: false,
  success_pdf: false
});

/* Reducers */

const getSignatureRequest = (state) => state.merge({
  ...state,
  loading: true,
  success_signature: false,
  success: false,
  success_pdf: false
});

const getSignatureSuccess = (state, { data }) => state.merge({
  data,
  loading: false,
  error: false,
  success_signature: true
});

const getSignatureFailure = (state) => state.merge({
  ...state,
  error: true,
  loading: false,
});


const formalizeSignatureRequest = (state) => state.merge({
  ...state,
  loading: true,
  success: false,
  success_pdf: false
});

const formalizeSignatureSuccess = (state, { data }) => state.merge({
  data,
  loading: false,
  error: false,
  success: true
});

const formalizeSignatureFailure = (state) => state.merge({
  ...state,
  error: true,
  loading: false,
});


const generatePdfRequest = (state) => state.merge({
  ...state,
  loading: true,
  success_pdf: false
});

const generatePdfSuccess = (state, { data }) => state.merge({
  loading: false,
  error: false,
  success_pdf: true
});

const generatePdfFailure = (state) => state.merge({
  ...state,
  error: true,
  loading: false,
});

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_SIGNATURE_REQUEST]: getSignatureRequest,
  [Types.GET_SIGNATURE_SUCCESS]: getSignatureSuccess,
  [Types.GET_SIGNATURE_FAILURE]: getSignatureFailure,

  [Types.FORMALIZE_SIGNATURE_REQUEST]: formalizeSignatureRequest,
  [Types.FORMALIZE_SIGNATURE_SUCCESS]: formalizeSignatureSuccess,
  [Types.FORMALIZE_SIGNATURE_FAILURE]: formalizeSignatureFailure,

  [Types.GENERATE_PDF_REQUEST]: generatePdfRequest,
  [Types.GENERATE_PDF_SUCCESS]: generatePdfSuccess,
  [Types.GENERATE_PDF_FAILURE]: generatePdfFailure,
});
