import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  getAuthenticationRequest: ['values', 'history'],
  getAuthenticationSuccess: ['data'],
  getAuthenticationFailure: [],

  postForgotPasswordRequest: ['values'],
  postForgotPasswordSuccess: [],
  postForgotPasswordFailure: [],

  postResetPasswordRequest: ['values'],
  postResetPasswordSuccess: [],
  postResetPasswordFailure: []
});

export const AuthenticationTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  loading: false,
  data: [],
  error: false,
});

/* Reducers */

const getAuthenticationRequest = (state) =>
  state.merge({
    ...state,
    loading: true,
  });

const getAuthenticationSuccess = (state, { data }) =>
  state.merge({
    data,
    loading: false,
    error: false,
  });

const getAuthenticationFailure = (state) =>
  state.merge({
    ...state,
    error: true,
    loading: false,
  });




const postForgotPasswordRequest = (state) =>
  state.merge({
    ...state,
    error: false,
    loading: true,
    success: false
  })

const postForgotPasswordSuccess = (state) =>
  state.merge({
    error: false,
    loading: false,
    success: true
  })

const postForgotPasswordFailure = (state) =>
  state.merge({
    ...state,
    error: true,
    loading: false,
    success: false
  })

const postResetPasswordRequest = (state) =>
  state.merge({
    ...state,
    error: false,
    loading: true,
    success: false
  })

const postResetPasswordSuccess = (state) =>
  state.merge({
    error: false,
    loading: false,
    success: true
  })

const postResetPasswordFailure = (state) =>
  state.merge({
    ...state,
    error: true,
    loading: false,
    success: false
  })

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_AUTHENTICATION_REQUEST]: getAuthenticationRequest,
  [Types.GET_AUTHENTICATION_SUCCESS]: getAuthenticationSuccess,
  [Types.GET_AUTHENTICATION_FAILURE]: getAuthenticationFailure,

  [Types.POST_FORGOT_PASSWORD_REQUEST]: postForgotPasswordRequest,
  [Types.POST_FORGOT_PASSWORD_SUCCESS]: postForgotPasswordSuccess,
  [Types.POST_FORGOT_PASSWORD_FAILURE]: postForgotPasswordFailure,
  

  [Types.POST_RESET_PASSWORD_REQUEST]: postResetPasswordRequest,
  [Types.POST_RESET_PASSWORD_SUCCESS]: postResetPasswordSuccess,
  [Types.POST_RESET_PASSWORD_FAILURE]: postResetPasswordFailure,
});
