import React, { useState } from 'react'
import { Formik} from 'formik'
import * as Yup from 'yup';
import { ReactSVG } from 'react-svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import Logo from '@/assets/images/logo-branca.svg';

import { Link } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AuthenticationActions from '@/store/ducks/authentication';

import {
  Container,
} from 'react-bootstrap';

import {
  Background,
  Description,
  Title,
  Center,
  Formgroup,
  Input,
  Form,
  Button,
  EyeBlock
} from './styles';


const validationSchema = Yup.object().shape({
  identifier: Yup.string()
    .email('E-mail inválido')
    .required('Campo obrigatório'),

  password: Yup.string()
    .required('Campo obrigatório')

});

const Login = ({
  getAuthenticationRequest,
  loading,
  history,
  ...props
}) => {
    
  const [showPassword, setShowPassword] = useState(false);
  /*const enterLogin = useEffect(() => {
    const listener = (event, values) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        console.log("Enter key was pressed. Run your function.", values);
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);*/

  return (
    <Background>
      <Container>
        <Center>
          <ReactSVG src={Logo} style={{ width: 75, margin: '0 auto' }} />
          <Title>Login.</Title>
          <Description>Por favor, insira seus dados para entrar.</Description>

          <Formik
            validationSchema={validationSchema}
            onSubmit={(values) => getAuthenticationRequest(values, history)}
            initialValues={{
              identifier: '',
              password: ''
            }}
          >
            {({
              values, errors, handleChange, handleSubmit
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Formgroup>
                    <Input
                      type="email"
                      placeholder="Email"
                      name="identifier"
                      className={errors?.identifier ? 'error' : ''}
                      onChange={handleChange}
                    />
                  </Formgroup>
                  <Formgroup>
                    <EyeBlock type="button" onClick={() => setShowPassword(!showPassword)}><FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} size="lg" /></EyeBlock>
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder="Senha"
                      name="password"
                      className={errors?.password ? 'error' : ''}
                      onChange={handleChange}
                    />
                  </Formgroup>
                  <Button onClick={handleSubmit} disabled={loading} type="submit">Entrar</Button>
                  <Link to="esqueci-minha-senha">Esqueceu sua senha?</Link>
                </Form>
              )
            }}
          </Formik>
        </Center>
      </Container>
    </Background>
  )
};

const mapStateToProps = ({ authentication }) => ({
  loading: authentication.loading,
  error: authentication.error,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(AuthenticationActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);

