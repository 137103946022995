import { call, put } from 'redux-saga/effects';
import api from '@/services/api';
import cogoToast from 'cogo-toast';

import AuthenticationActions from '@/store/ducks/authentication';
import storage from '@/utils/storage';
import toast from '@/utils/toast';

export function* getAuthenticationRequest({ values, history }) {
  try {
    const endpoint = '/auth/local';
    const { data } = yield call(api.post, endpoint, {
      identifier: values.identifier,
      password: values.password,
    });

    storage.setToken(data.jwt);

    yield put(
      AuthenticationActions.getAuthenticationSuccess({ user: data.user }),
    );

    window.location.href = '/'

  } catch (error) {
    cogoToast.error('Usuário ou senha incorreto');
    storage.removeToken()
    yield put(AuthenticationActions.getAuthenticationFailure());
  }
}

export function* postForgotPasswordRequest({ values }) {
  try {
    const endpoint = '/auth/forgot-password';
    const { data } = yield call(api.post, endpoint, {
      email: values.email
    });


    toast.success('Verifique seu e-mail, enviamos um link para você.')
    yield put(
      AuthenticationActions.postForgotPasswordSuccess(),
    );

  } catch (error) {
    cogoToast.error('E-mail incorreto')
    yield put(AuthenticationActions.postForgotPasswordFailure());
  }
}

export function* postResetPasswordRequest({ values }) {
  try {
    const endpoint = '/auth/reset-password';
    const { data } = yield call(api.post, endpoint, values);


    toast.success('Sua senha foi resetada com sucesso.');
    setTimeout(() => {
      window.location.href = '/login'
    }, 1500)
    yield put(
      AuthenticationActions.postResetPasswordSuccess(),
    );

  } catch (error) {
    cogoToast.error('E-mail incorreto')
    yield put(AuthenticationActions.postResetPasswordFailure());
  }
}
