import storage from "./storage";

const now = new Date()

export async function getIp() {
    const ipCache = await storage.getStorage('ip');

    if (ipCache) return ipCache;

    const ip = await fetch('https://api.ipify.org?format=json');
    const repsonse = await ip.json();
    await storage.setStorage('ip', repsonse.ip);
    return repsonse.ip;
}


export const getBrowser = async (sUsrAg) => {
    let sBrowser;
    if (sUsrAg.indexOf("Firefox") > -1) {
        sBrowser = "Mozilla Firefox";
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
        sBrowser = "Samsung Internet";
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
        sBrowser = "Opera";
    } else if (sUsrAg.indexOf("Trident") > -1) {
        sBrowser = "Microsoft Internet Explorer";
    } else if (sUsrAg.indexOf("Edge") > -1) {
        sBrowser = "Microsoft Edge";
    } else if (sUsrAg.indexOf("Chrome") > -1) {
        sBrowser = "Google Chrome or Chromium";
    } else if (sUsrAg.indexOf("Safari") > -1) {
        sBrowser = "Apple Safari";
    } else {
        sBrowser = "unknown";
    }

    return sBrowser;
}